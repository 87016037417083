import {
  SEND_BUSINESS_INVITE,
  GET_BUSINESS_INVITE,
  FINALIZE_BUSINESS_INVITE
} from '../constants/UserManagementActionTypes';

const initialState = JSON.parse(localStorage.getItem('userManagement')) || {};

export default function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_BUSINESS_INVITE:
      const hashedId = action.res
      return {
        ...state,
        hashedId,
      };
    case GET_BUSINESS_INVITE:
      const inviteList = action.res
      return {
        ...state,
        inviteList,
      };
    case FINALIZE_BUSINESS_INVITE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
