import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Paper,
  Toggle,
  TextField,
  Card,
  CardHeader,
  CardText
} from 'material-ui';
import ROLE from '../constants/Global';

const styles = {
  block: {
    maxWidth: 1024,
    margin: '16px auto',
    padding: 8
  },
  toggle: {
    marginBottom: 16
  }
};

class FilterUsers extends Component {
  static propTypes = {
    children: PropTypes.node,
    role: PropTypes.number,
    onChange: PropTypes.func,
    ROLE: PropTypes.array
  };

  state = {
    role: this.props.role,
    searchText: null
  };

  static contextTypes = {
    router: PropTypes.object
  };

  filter() {
    const { onChange } = this.props;
    const { role, searchText } = this.state;
    onChange && onChange(role, searchText);
  }

  tryFilter() {
    if (this._timer) {
      clearTimeout(this._timer);
    }
    this._timer = setTimeout(() => {
      this.filter();
      this._timer = null;
    }, 500);
  }

  setFilter = (roleMask, event) => {
    let { role } = this.state;
    const { checked } = event.target;

    checked
      ? this.setState({ role: role | roleMask })
      : this.setState({ role: role ^ roleMask });

    this.tryFilter();
  };

  changeSearchText = event => {
    const { value } = event.target;
    const { router } = this.context;
    this.setState({ searchText: value }, () => {
      this.tryFilter();
    });
    router.push('/users');
  };

  render() {
    const { searchText, role } = this.state;
    return (
      <Paper style={styles.block}>
        <TextField
          value={searchText || ''}
          fullWidth
          onChange={this.changeSearchText}
          floatingLabelText={'Search by Email or User ID'}
        />

        <Card>
          <CardHeader
            title="Search by Roles"
            actAsExpander={true}
            showExpandableButton={true}
          />
          <CardText expandable={true}>
            <Toggle
              toggled={!!(role & ROLE.reader)}
              onToggle={this.setFilter.bind(this, ROLE.reader)}
              style={styles.toggle}
              ref="reader"
              label="Reader"
            />
            <Toggle
              toggled={!!(role & ROLE.teacher)}
              onToggle={this.setFilter.bind(this, ROLE.teacher)}
              style={styles.toggle}
              ref="teacher"
              label="Teacher"
            />
            <Toggle
              toggled={!!(role & ROLE.accountant)}
              onToggle={this.setFilter.bind(this, ROLE.accountant)}
              style={styles.toggle}
              ref="accountant"
              label="Accountant"
            />
            <Toggle
              toggled={!!(role & ROLE.editor)}
              onToggle={this.setFilter.bind(this, ROLE.editor)}
              style={styles.toggle}
              ref="editor"
              label="Editor"
            />
            <Toggle
              toggled={!!(role & ROLE.owner)}
              onToggle={this.setFilter.bind(this, ROLE.owner)}
              style={styles.toggle}
              ref="owner"
              label="Owner"
            />
            <Toggle
              toggled={!!(role & ROLE.tester)}
              onToggle={this.setFilter.bind(this, ROLE.tester)}
              style={styles.toggle}
              ref="tester"
              label="Tester"
            />
            <Toggle
              toggled={!!(role & ROLE.assistant)}
              onToggle={this.setFilter.bind(this, ROLE.assistant)}
              style={styles.toggle}
              ref="assistant"
              label="Assistant"
            />
          </CardText>
        </Card>
      </Paper>
    );
  }
}

export default FilterUsers;
