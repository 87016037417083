//This is the reducer for a course
import {
  DELETE_COURSE,
  DELETE_COURSE_REQUEST,
  FIND_COURSE_BY_ID,
  FIND_COURSE_BY_ID_REQUEST,
  DUPLICATE_COURSE,
  DUPLICATE_COURSE_REQUEST,
  UPDATE_COURSE,
  UPDATE_COURSE_REQUEST,
  CHANGE_OWNER_COURSE
} from '../constants/CurrCourseActionTypes';

const initialState = {
  loading: true,
  currCourse: {}
};

export default function currCourseReducer(state = initialState, action) {
  let currCourse;
  switch (action.type) {
    case FIND_COURSE_BY_ID:
      currCourse = action.res;
      return {
        loading: false,
        currCourse
      };
    case FIND_COURSE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_COURSE:
      currCourse = action.res;
      return {
        loading: false,
        currCourse
      };
    case DELETE_COURSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DUPLICATE_COURSE:
      currCourse = action.res;
      return {
        loading: false,
        currCourse
      };
    case DUPLICATE_COURSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_COURSE:
      currCourse = action.res;
      return {
        loading: false,
        currCourse
      };
    case UPDATE_COURSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CHANGE_OWNER_COURSE:
      return {
        ...state, currCourse: { ...state.currCourse, createdBy:action.userId },
        loading: true
      };
    default:
      return state;
  }
}
