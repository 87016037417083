import SteelCompass from '../services/SteelCompass';
import {
  FIND_INVITATION_BY_ID,
  DELETE_INVITATION,
  RESEND_INVITATION,
  GENERATE_GIFT
} from '../constants/CurrInvitationActionTypes';

//this function brings an invitation by ID
export function findInvitationById(id) {
  return {
    type: FIND_INVITATION_BY_ID,
    id,
    promise: SteelCompass.request('mr.wolf-invitation', 'findById', { id })
  };
}

//delete an invitation
export function deleteInvitation(id) {
  return {
    type: DELETE_INVITATION,
    id,
    promise: SteelCompass.request('invitation', 'delete', { id })
  };
}

//resend an invitation
export function resendInvitation(id) {
  return {
    type: RESEND_INVITATION,
    id,
    promise: SteelCompass.request('mr.wolf-invitation', 'resend', { id })
  };
}


//Generate gift codes for give course
export function generateGiftCodes( objectId, n, objectType = "course" ) {
  return {
    type: GENERATE_GIFT,
    objectId,
    n,
    promise: SteelCompass.request('mr.wolf-invitation','generateGiftCodes',
    {
      objectId,
      objectType,
      n
    })
  }
}