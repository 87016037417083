import PropTypes from 'prop-types';

import React from 'react';
import {
  Paper,
  List,
  ListItem,
} from 'material-ui';
import Delete from 'material-ui/svg-icons/action/delete';

const styles = {
  list: {
    margin: 8
  },
  listItem: {
    display: 'flex',
    alignItems: 'center'
  },
  listEmpty: {
    margin: 8,
    padding: 8
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',

  }

};

class ResellerCutsList extends React.Component {
  static propTypes = {
    resellerCuts: PropTypes.array,
    onSelectItem: PropTypes.func
  };

  state = {

  };


  selectItem = (cut) => {
    const { onSelectItem } = this.props;

    onSelectItem && onSelectItem(cut);
  };

  render() {
    const { resellerCuts } = this.props;

    return (
      <Paper style={styles.list}>
        <List>
          {resellerCuts.map((cut, index) => {
            return (
              <div key={index} style={styles.listItem}>
                <div style={{ width: '100%' }}>
                  <ListItem
                    rightIcon={<Delete />}
                    onClick={this.selectItem.bind(this, cut.id)}
                    primaryText={<div style={styles.flex}>
                      <div>{cut.name}</div>
                      {cut.courseId && <div>{`courseId: ${cut.courseId}`}</div>}
                      {cut.userId && <div>{`userId: ${cut.userId}`}</div>}
                    </div>}
                    secondaryText={`value: ${cut.cut} ${cut.cutType === '1' ? '%' : ''}`}
                  />
                </div>
              </div>
            );
          })}
        </List>
      </Paper>
    );
  }
}

export default ResellerCutsList;
