import {
  OPEN_DRAWER,
  CLOSE_DRAWER,
  TEST_MODE,
  LIVE_MODE,
  SHOW_BULK_OPTIONS,
  HIDE_BULK_OPTIONS
} from '../constants/UiActionTypes';

const initialState = {
  drawer: false,
  testMode:
    localStorage.getItem('userOptions') == null
      ? false
      : JSON.parse(localStorage.getItem('userOptions')).testMode == null
        ? false
        : JSON.parse(localStorage.getItem('userOptions')).testMode,
  bulkOptions: false
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        ...state,
        drawer: true
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        drawer: false
      };
    case TEST_MODE:
      return {
        ...state,
        testMode: true
      };
    case LIVE_MODE:
      return {
        ...state,
        testMode: false
      };
    case SHOW_BULK_OPTIONS:
      return {
        ...state,
        bulkOptions: true
      };
    case HIDE_BULK_OPTIONS:
      return {
        ...state,
        bulkOptions: false
      };
    default:
      return state;
  }
}
