import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  RaisedButton,
  IconButton,
  AppBar,
  Snackbar,
  TextField,
  Toggle
} from 'material-ui';
import { createUser } from '../../actions/User';
import ContentBackspace from 'material-ui/svg-icons/content/backspace';
import ROLE from '../../constants/Global';

const styles = {
  detail: {
    margin: 12
  },
  toggle: {
    marginBottom: 16
  }
};

class CreateUser extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    message: null
  };

  static propTypes = {
    createUser: PropTypes.func
  };

  createUser = ({ email, password, username, displayName, roles }) => {
    this.props
      .createUser({ email, password, username, displayName, roles })
      .then(res => {
        if (!res.error) {
          this.setState({ message: 'New user has been created!' });
          this.goToUser(res.data.id);
        } else {
          this.setState({ message: res.data });
        }
      });
  };

  goToUser = userId => {
    this.context.router.push(`/users/${userId}`);
  };

  //after the message goes away the message becomes null again
  clearMessage = () => {
    this.setState({ message: null });
  };

  handleSubmit = event => {
    event.preventDefault();

    const roles = this.state.roles | ROLE.reader;
    const email = this.refs.email.getValue();
    const password = this.refs.password.getValue();
    const username = this.refs.username.getValue();
    const displayName = this.refs.displayName.getValue();

    this.createUser({ email, password, username, displayName, roles });
  };

  setToggle = (roleMask, { target: { checked } }) => {
    const { roles } = this.state;
    const newRoles = checked ? roles | roleMask : roles ^ roleMask;
    this.setState({ roles: newRoles });
  };

  closeEdit = () => {
    this.context.router.push('/users');
  };

  render() {
    const { message } = this.state;

    return (
      <div>
        <div>
          <AppBar
            title={'Creating new user'}
            iconElementLeft={
              <IconButton
                tooltip="Cancel"
                tooltipPosition="top-right"
                onClick={this.closeEdit}
              >
                <ContentBackspace />
              </IconButton>
            }
          />
          <div style={styles.detail}>
            <TextField
              fullWidth
              id="email"
              hintText=""
              floatingLabelText="Email"
              ref="email"
              type="text"
            />

            <TextField
              fullWidth
              id="password"
              hintText=""
              floatingLabelText="Password"
              ref="password"
              type="text"
            />

            <TextField
              fullWidth
              id="username"
              hintText=""
              floatingLabelText="Username"
              ref="username"
              type="text"
            />

            <TextField
              fullWidth
              id="displayName"
              hintText=""
              floatingLabelText="Display Name"
              ref="displayName"
              type="text"
            />

            <h4>User Roles</h4>
            <Toggle
              id="1"
              toggled={Boolean(this.state.roles & ROLE.teacher)}
              onToggle={this.setToggle.bind(this, ROLE.teacher)}
              style={styles.toggle}
              ref="teacher"
              label="Teacher"
            />
            <Toggle
              id="2"
              toggled={Boolean(this.state.roles & ROLE.accountant)}
              onToggle={this.setToggle.bind(this, ROLE.accountant)}
              style={styles.toggle}
              ref="accountant"
              label="Accountant"
            />
            <Toggle
              id="3"
              toggled={Boolean(this.state.roles & ROLE.editor)}
              onToggle={this.setToggle.bind(this, ROLE.editor)}
              style={styles.toggle}
              ref="editor"
              label="Editor"
            />
            <Toggle
              id="4"
              toggled={Boolean(this.state.roles & ROLE.owner)}
              onToggle={this.setToggle.bind(this, ROLE.owner)}
              style={styles.toggle}
              ref="owner"
              label="Owner"
            />
            <Toggle
              id="5"
              toggled={Boolean(this.state.roles & ROLE.tester)}
              onToggle={this.setToggle.bind(this, ROLE.tester)}
              style={styles.toggle}
              ref="tester"
              label="Tester"
            />
            <Toggle
              id="6"
              toggled={Boolean(this.state.roles & ROLE.assistant)}
              onToggle={this.setToggle.bind(this, ROLE.assistant)}
              style={styles.toggle}
              ref="assistant"
              label="Assistant"
            />

            <RaisedButton
              fullWidth
              label="Create User"
              onClick={this.handleSubmit}
            />
          </div>
          <Snackbar
            message={message || ''}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    createUser: bindActionCreators(createUser, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
