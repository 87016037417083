import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, List, ListItem, Checkbox } from 'material-ui';

const paymentStatus = { 
  1:"NEW",
  2:"CART_OPENED",
  4:"REGISTERED",
  8:"PAID",
  16:"GOOD",
  32:"ERROR",
  64:"USER_CANCELED",
  128:"REFUND"
}

const styles = {
  list: {
    margin: 8
  },
  listEmpty: {
    margin: 8,
    padding: 8
  },
  checkbox: {
    maxWidth: 30,
    float: 'left',
    marginTop: '20px',
    left: '10px'
  }
};

class PaymentsList extends Component {
  static propTypes = {
    children: PropTypes.node,
    payments: PropTypes.object,
    onChoosePayment: PropTypes.func,
    onCheckPayments: PropTypes.func
  };

  state = {
    paymentsChosen: {}
  };

  getPayment = payment => {
    const { onChoosePayment } = this.props;
    onChoosePayment && onChoosePayment(payment);
  };

  checkPayments = (payment, checked) => {
    const { onCheckPayments } = this.props;
    const { paymentsChosen } = this.state;

    if (checked) {
      if (Object.keys(this.state.paymentsChosen).length === 0) {
        // map is empty, so add item
        paymentsChosen[payment.id] = payment;
      } else {
        if (payment.id in paymentsChosen) {
          // item id exists so remove item
          delete paymentsChosen[payment.id];
        } else {
          // item id does not exist, so add item
          paymentsChosen[payment.id] = payment;
        }
      }
    }

    onCheckPayments && onCheckPayments(paymentsChosen);
  };

  render() {
    const { payments } = this.props;
    
    let finPayments;
    if (window.location.pathname !== '/payments') {
      finPayments = <div> </div>;
    } else {
      finPayments = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(payments).map(ID => {
              const payment = payments[ID];

              let check = (
                <div>
                  <Checkbox
                    ref="check"
                    onCheck={this.checkPayments.bind(this, payment)}
                    checked={payment.id in this.state.paymentsChosen}
                    style={styles.checkbox}
                  />
                </div>
              );

              return (
                <div key={payment.id}>
                  {check}
                  <ListItem
                    onClick={this.getPayment.bind(this, payment)}
                    primaryText={
                      'Id: ' +
                      payment.id +
                      ', Cost: ' +
                      payment.amount +
                      ' ' +
                      payment.currency
                    }
                    secondaryText={
                      'Store Name: ' +
                      payment.storeName +
                      ' (ID:' +
                      payment.storeId +
                      ' ) , Email: ' +
                      payment.email +
                      ' , Date Created: ' +
                      payment.created.substring(0, 10) +
                      ', Transcation ID: ' +
                      payment.transactionId +
                      ', Status: ' +
                      paymentStatus[payment.status]  
                    }
                    style={{ marginLeft: '40px' }}
                  />
                </div>
              );
            })}
          </List>
        </Paper>
      );
    }
    return (
      <div>
        {Object.keys(payments).length !== 0 ? (
          finPayments
        ) : (
          <Paper style={styles.listEmpty}>No results found</Paper>
        )}
      </div>
    );
  }
}

export default PaymentsList;
