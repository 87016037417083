//Here is a list component of all coursio users
import PropTypes from 'prop-types';

import React from 'react';
import {
  RaisedButton,
  Paper,
  Checkbox,
  List,
  ListItem,
  Avatar
} from 'material-ui';

const styles = {
  list: {
    margin: 8
  },
  listEmpty: {
    margin: 8,
    padding: 8
  },
  checkbox: {
    maxWidth: 30,
    float: 'left',
    marginTop: '25px',
    left: '10px'
  }
};

class UsersList extends React.Component {
  static propTypes = {
    users: PropTypes.object,
    onChooseUser: PropTypes.func,
    onCheckUsers: PropTypes.func
  };

  state = {
    usersChosen: {}
  };

  static contextTypes = {
    router: PropTypes.object
  };

  getUser = user => {
    const { onChooseUser } = this.props;
    onChooseUser && onChooseUser(user);
  };

  createUser = () => {
    this.context.router.push('/users/new');
  };

  createBatchUser = () => {
    this.context.router.push('/users/batch');
  };

  checkUsers = (user, checked) => {
    const { onCheckUsers } = this.props;
    const { usersChosen } = this.state;

    if (checked) {
      if (Object.keys(this.state.usersChosen).length === 0) {
        // map is empty, so add item
        usersChosen[user.id] = user;
      } else {
        if (user.id in usersChosen) {
          // item id exists so remove item
          delete usersChosen[user.id];
        } else {
          // item id does not exist, so add item
          usersChosen[user.id] = user;
        }
      }
    }

    onCheckUsers && onCheckUsers(usersChosen);
  };

  render() {
    const { users } = this.props;

    let finUsers;
    if (window.location.pathname !== '/users') {
      finUsers = <div> </div>;
    } else {
      finUsers = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(users).map(ID => {
              const user = users[ID];

              let check = (
                <div>
                  <Checkbox
                    ref="check"
                    onCheck={this.checkUsers.bind(this, user)}
                    checked={user.id in this.state.usersChosen}
                    style={styles.checkbox}
                  />
                </div>
              );

              return (
                <div key={user.id}>
                  {check}
                  <ListItem
                    onClick={this.getUser.bind(this, user)}
                    primaryText={user.name}
                    secondaryText={user.email}
                    leftAvatar={<Avatar src={user.avatarUrl} />}
                    style={{ marginLeft: '40px' }}
                  />
                </div>
              );
            })}
          </List>
        </Paper>
      );
    }

    return (
      <div>
        {window.location.pathname !== '/users/new' && (
          <div style={{ marginBottom: 15, marginLeft: 8 }}>
            <RaisedButton
              secondary
              label="Create User"
              onClick={this.createUser}
            />

            <RaisedButton
              style={{ marginLeft: "10px" }}
              secondary
              label="Create Batch of Users"
              onClick={this.createBatchUser}
            />
          </div>
        )}
        {Object.keys(users).length !== 0 ? (
          finUsers
        ) : (
          <Paper style={styles.listEmpty}>No results found</Paper>
        )}
      </div>
    );
  }
}

export default UsersList;
