//Bar drawer actions
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

//Test or live mode switch actions
export const TEST_MODE = 'TEST_MODE';
export const LIVE_MODE = 'LIVE_MODE';

//Bulk options actions
export const SHOW_BULK_OPTIONS = 'SHOW_BULK_OPTIONS';
export const HIDE_BULK_OPTIONS = 'HIDE_BULK_OPTIONS';
