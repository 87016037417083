//This is the reducer for bringing all coursio users
import {
  GET_USERS,
  GET_USERS_REQUEST,
  POPULATE_USERS,
  POPULATE_USERS_REQUEST
} from '../constants/UsersActionTypes';

const initialState = {
  loading: true,
  users: {}
};

const mapUsersToId = (prevState, nextState) => {
  return nextState.reduce((previousResult, current) => {
    previousResult[current.id] = {
      ...prevState[current.id],
      ...current
    };
    return previousResult;
  }, {});
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      let users;
      if (action.pageStart === 0) {
        users = mapUsersToId({}, action.res);
      } else {
        users = mapUsersToId(state.users, action.res);
      }
      return {
        loading: false,
        users
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case POPULATE_USERS:
      if (action.pageStart === 0) {
        users = mapUsersToId({}, action.res);
      } else {
        users = mapUsersToId(state.users, action.res);
      }
      return {
        loading: false,
        users
      };
    case POPULATE_USERS_REQUEST:
      return {
        ...state
      };
    default:
      return state;
  }
}
