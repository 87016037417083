import React from 'react';
import {
  TextField,
  RaisedButton,
} from 'material-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { generateGiftCodes } from '../actions/Invitation';

const styles = {
  detail: {
    margin: 12
  },
  toggle: {
    marginBottom: 16
  }
};

class CreateGiftCardsForm extends React.Component {
  state = {
    numberOfCodes: null
  };

  handleSubmit = () => {
    this.props.generateGiftCodes(this.props.courseId, this.state.numberOfCodes);
  };

  render() {
    const { giftCodes } = this.props;
    return (
      <div style={styles.detail}>
        <TextField
          fullWidth
          hintText=""
          defaultValue={1}
          floatingLabelText="Number of gift cards"
          type="number"
          onChange={e => this.setState({ numberOfCodes: e.target.value })}
        />
        <RaisedButton
          fullWidth
          label="Generate"
          onClick={this.handleSubmit}
        />
        {giftCodes && (
          <TextField
            hintText="Gift Codes"
            defaultValue={giftCodes.join('\n')}
            multiLine={true}
            rows={2}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    generateGiftCodes: bindActionCreators(generateGiftCodes, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    giftCodes: state.currInvitation.giftCodes
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGiftCardsForm);
