//Here is the container for providing more details on each choosen course
import PropTypes from "prop-types";

import React, { Component } from "react";
import DeleteCourseForm from "../../components/DeleteCourseForm";
import { bindActionCreators } from "redux";
import {
  deleteCourse,
  findCourseById,
  duplicateCourse,
  updateCourse
} from "../../actions/Course";
import { connect } from "react-redux";
import {
  Snackbar,
  AppBar,
  IconButton,
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  IconMenu
} from "material-ui";
import NavigationCancel from "material-ui/svg-icons/navigation/cancel";
import ContentContentCopy from "material-ui/svg-icons/content/content-copy";
import ContentBackspace from "material-ui/svg-icons/content/backspace";
import ActionCardGiftcard from "material-ui/svg-icons/action/card-giftcard";
import ActionAccountBox from "material-ui/svg-icons/action/account-box";
import CourseDetailsForm from "../../components/CourseDetailsForm";
import DuplicateCourseForm from "../../components/DuplicateCourseForm";
import CreateGiftCardsForm from "../../components/CreateGiftCardsForm";
import ImageEdit from "material-ui/svg-icons/image/edit";
import UpdateCourseForm from "../../components/UpdateCourseForm";
import ChangeCourseOwnerForm from "../../components/ChangeCourseOwnerForm";

const styles = {
  smallIcon: {
    width: 23,
    height: 23
  },
  small: {
    width: 10,
    height: 10,
    padding: 16
  },
  button: {
    margin: 12
  }
};

class CourseDetails extends Component {
  static propTypes = {
    children: PropTypes.node,
    params: PropTypes.object,
    currCourse: PropTypes.object,
    courses: PropTypes.object,
    user: PropTypes.object,
    deleteCourse: PropTypes.func,
    duplicateCourse: PropTypes.func,
    updateCourse: PropTypes.func,
    findCourseById: PropTypes.func
  };

  state = {
    duplicating: false,
    editing: false,
    message: null,
    open: false,
    generateGiftCards: false,
    changeOwner: false
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    const { params, findCourseById } = this.props;

    findCourseById(params.courseId);
  }

  goBack = () => {
    const { router } = this.context;
    router.push("/allcourses");
  };

  //if there is no response error then the user gets a meesage that the course has been deleted
  deleteCourse = courseId => {
    const { deleteCourse, currCourse } = this.props;

    deleteCourse(courseId).then(res => {
      if (!res.error) {
        this.setState({
          message: `Course ${currCourse.name || ""} has been deleted!`
        });
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  //if there is no response error then the user gets a meesage that the course has been duplicated
  duplicateCourse = (courseId, userId) => {
    const { duplicateCourse, currCourse } = this.props;

    duplicateCourse(courseId, userId).then(res => {
      if (!res.error) {
        this.setState({
          message: `Course ${currCourse.name || ""} has been duplicated!`
        });
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  updateCourse = (courseId, name, keepSlug, price, ucpPeriod, verypublic) => {
    const { updateCourse } = this.props;
    // let finPublic = null;
    // (verypublic === 0 && (finPublic = false)) || (finPublic = true);
    updateCourse(courseId, name, keepSlug, price, ucpPeriod, verypublic).then(
      res => {
        if (!res.error) {
          this.setState({ message: `Course ${name} has been updated!` });
        } else {
          this.setState({ message: res.data });
        }
      }
    );
  };

  openEditing = () => {
    this.setState({ editing: true, duplicating: false });
  };

  openGenerateGiftCards = () => {
    this.setState({ generateGiftCards: true });
  };

  openChangeOwner = () => {
    this.setState({ changeOwner: true })
  }

  closeEditing = () => {
    this.setState({ editing: false });
  };

  openEdit = () => {
    this.setState({ duplicating: true, editing: false });
  };

  closeEdit = () => {
    this.setState({ duplicating: false });
  };

  closeGenerateGiftCards = () => {
    this.setState({ generateGiftCards: false });
  };

  //when the message is cleared up the message is set to null and the user is redirected to the courses router
  clearMessage = () => {
    this.setState({ message: null, editing: false, duplicateCourse: false });
  };

  render() {
    const { params, currCourse, user } = this.props;
    const { message } = this.state;

    let courseItem;
    if (currCourse.deletedBy === 0 && !this.state.editing) {
      courseItem = (
        <div>
          <Toolbar>
            <ToolbarGroup key={0}>
              <ToolbarTitle text={currCourse.name + " - " + params.courseId} />
            </ToolbarGroup>
            <ToolbarGroup key={1}>
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Generate Gift codes"
                    tooltipPosition="top-right"
                    onClick={this.openGenerateGiftCards}
                  >
                    <ActionCardGiftcard />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Change Owner"
                    tooltipPosition="top-right"
                    onClick={this.openChangeOwner}
                  >
                    <ActionAccountBox />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Edit"
                    tooltipPosition="top-right"
                    onClick={this.openEditing}
                  >
                    <ImageEdit />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Duplicate"
                    tooltipPosition="top-right"
                    onClick={this.openEdit}
                  >
                    <ContentContentCopy />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Close"
                    tooltipPosition="top-right"
                    onClick={this.goBack}
                  >
                    <NavigationCancel />
                  </IconButton>
                }
              />
            </ToolbarGroup>
          </Toolbar>

          <CourseDetailsForm currCourse={currCourse} />

          <DeleteCourseForm onDelete={this.deleteCourse} params={params} />

          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    } else if (currCourse.deletedBy !== 0 && !this.state.editing) {
      courseItem = (
        <div>
          <Toolbar>
            <ToolbarGroup key={0}>
              <ToolbarTitle text={currCourse.name + " - " + params.courseId} />
            </ToolbarGroup>
            <ToolbarGroup key={1}>
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Close"
                    onClick={this.goBack}
                  >
                    <NavigationCancel />
                  </IconButton>
                }
              />
            </ToolbarGroup>
          </Toolbar>

          <CourseDetailsForm currCourse={currCourse} />

          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    }

    if (this.state.duplicating === true && this.state.editing === false) {
      courseItem = (
        <div>
          <AppBar
            title={"Duplicate " + currCourse.name}
            iconElementLeft={
              <IconButton
                tooltip="Cancel"
                tooltipPosition="top-right"
                onClick={this.closeEdit}
              >
                <ContentBackspace />
              </IconButton>
            }
          />

          <DuplicateCourseForm
            params={params}
            duplicateCourse={this.duplicateCourse}
            user={user}
            currCourse={currCourse}
          />

          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={2500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    } else if (this.state.duplicating === false && this.state.editing === true) {
      courseItem = (
        <div>
          <AppBar
            title={"Editing " + currCourse.name}
            iconElementLeft={
              <IconButton
                tooltip="Cancel"
                tooltipPosition="top-right"
                onClick={this.closeEditing}
              >
                <ContentBackspace />
              </IconButton>
            }
          />

          <UpdateCourseForm
            params={params}
            updateCourse={this.updateCourse}
            user={user}
            currCourse={currCourse}
          />

          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    } else if (this.state.generateGiftCards) {
      courseItem = (
        <div>
          <AppBar
            title={"Generate gift cards for " + currCourse.name}
            iconElementLeft={
              <IconButton
                tooltip="Cancel"
                tooltipPosition="top-right"
                onClick={this.closeGenerateGiftCards}
              >
                <ContentBackspace />
              </IconButton>
            }
          />
          <CreateGiftCardsForm courseId={params.courseId} />
          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    } else if (this.state.changeOwner) {
      courseItem = (
        <div>
          <AppBar
            title={"Change owner for " + currCourse.name}
            iconElementLeft={
              <IconButton
                tooltip="Cancel"
                tooltipPosition="top-right"
                onClick={() => this.setState({ changeOwner: false })}
              >
                <ContentBackspace />
              </IconButton>
            }
          />
          <ChangeCourseOwnerForm courseId={params.courseId} />
          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    }

    return <div>{courseItem}</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    deleteCourse: bindActionCreators(deleteCourse, dispatch),
    findCourseById: bindActionCreators(findCourseById, dispatch),
    duplicateCourse: bindActionCreators(duplicateCourse, dispatch),
    updateCourse: bindActionCreators(updateCourse, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    currCourse: state.currCourse.currCourse,
    user: state.user
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDetails);
