//Here is where the list of payments comes together with filtering options
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showBulkOptions, hideBulkOptions } from '../../actions/Ui';
import { getPayments } from '../../actions/Payments';
import Header from '../Bar/Header';
import { CircularProgress, Paper } from 'material-ui';
import FilterPayments from '../../components/FilterPayments';
import PaymentsList from '../../components/PaymentsList';
import BulkOptions from '../../components/BulkOptions';

const styles = {
  loader: {
    position: 'fixed',
    bottom: 16,
    right: 16
  },
  list: {
    margin: 8,
    padding: 8
  }
};

class Payments extends Component {
  static propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    payments: PropTypes.object,
    paymentsPerPage: PropTypes.number,
    getPayments: PropTypes.func,
    showBulkOptions: PropTypes.func,
    hideBulkOptions: PropTypes.func,
    route: PropTypes.object,
    path: PropTypes.object,
    bulkOptions: PropTypes.bool
  };

  state = {
    filters: [],
    pageStart: 0,
    pageEnd: 30,
    startDate: null,
    endDate: null,
    chosenPayments: {},
    prevPayments: {}
  };

  static defaultProps = {
    paymentsPerPage: 20
  };

  static contextTypes = {
    router: PropTypes.object
  };

  onChoosePayment = payment => {
    const { router } = this.context;
    router.push(`/payments/${payment.id}`);
  };

  onCheckPayments = elements => {
    const { showBulkOptions, hideBulkOptions } = this.props;

    this.setState({ chosenPayments: elements }, () => {
      (Object.keys(elements).length > 0 &&
        window.location.pathname === '/payments' &&
        showBulkOptions()) ||
        hideBulkOptions();
    });
  };

  componentDidMount() {
    const { getPayments, paymentsPerPage, hideBulkOptions } = this.props;
    const { pageStart, filters, startDate, endDate } = this.state;

    this._node = document.body;

    getPayments(filters, startDate, endDate, pageStart, paymentsPerPage);
    hideBulkOptions();
    window.addEventListener('scroll', this.onPageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll);
  }

  onPageScroll = () => {
    const userScrollHeight = window.innerHeight + window.scrollY;
    const windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight) {
      this.loadNext();
    }
  };

  loadNext() {
    const { getPayments, paymentsPerPage, payments } = this.props;
    const { filters, startDate, endDate, pageStart, pageEnd, prevPayments } = this.state;

    this.setState(
      {
        pageStart: 0,
        pageEnd: pageEnd + paymentsPerPage,
        prevPayments: payments
      },
      () => {
        if(Object.keys(prevPayments).length !== Object.keys(payments).length)
        getPayments(filters, startDate, endDate, pageStart, this.state.pageEnd);
      }
    );
  }

  filter() {
    const { getPayments, paymentsPerPage } = this.props;
    const { filters, startDate, endDate } = this.state;
    getPayments(filters, startDate, endDate, 0, paymentsPerPage);
  }

  handleFilter = (searchTextEmail,searchTranscationId, searchTextCourseID, minDate, maxDate) => {
    if (searchTextEmail && searchTextEmail !== '') {
      this.setState(
        {
          filters: [{ mode: 'email', value: searchTextEmail }],
          startDate: minDate,
          endDate: maxDate,
          pageEnd: this.props.paymentsPerPage
        },
        () => {
          this.filter();
        }
      );
    } else if (searchTextCourseID && searchTextCourseID !== '') {
      this.setState(
        {
          filters: [{ mode: 'articleId', value: searchTextCourseID }],
          startDate: minDate,
          endDate: maxDate,
          pageEnd: this.props.paymentsPerPage
        },
        () => {
          this.filter();
        }
      );
    }
    else if (searchTranscationId && searchTranscationId !== '') {
      this.setState(
        {
          filters: [{ mode: 'transactionId', value: searchTranscationId }],
          startDate: minDate,
          endDate: maxDate,
          pageEnd: this.props.paymentsPerPage
        },
        () => {
          this.filter();
        }
      );
    } else {
      this.setState(
        {
          filters: [],
          startDate: minDate,
          endDate: maxDate
        },
        () => {
          this.filter();
        }
      );
    }
  };

  render() {
    const { chosenPayments, pageEnd } = this.state;
    const { children, loading, payments, bulkOptions } = this.props;

    let bulk;
    bulk = (
      <div>
        <Paper style={styles.list}>
          Total Items Chosen: {Object.keys(chosenPayments).length || 0}
          <BulkOptions chosenItems={chosenPayments} />
        </Paper>
      </div>
    );

    return (
      <div>
        <Header title="Payments" />

        {Object.keys(payments).length <= pageEnd
          ? loading && (
              <div style={styles.loader}>
                <CircularProgress />
              </div>
            )
          : null}

        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            marginTop: 60,
            marginLeft: 8,
            marginRight: 8
          }}
        >
          <div style={{ flex: 1 }}>
            <p style={{ textAlign: 'center' }}>
              Search payments by either email or course ID. Change the dates
              according to your needs.{' '}
            </p>
            <FilterPayments onChange={this.handleFilter} />
            {bulkOptions === true}{' '}
            {Object.keys(chosenPayments).length !== 0 ? bulk : <div> </div>}
            <PaymentsList
              onChoosePayment={this.onChoosePayment}
              payments={payments}
              onCheckPayments={this.onCheckPayments}
            />
          </div>

          {children && (
            <Paper style={{ flex: 1, margin: 18 }}>{children}</Paper>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getPayments: bindActionCreators(getPayments, dispatch),
    showBulkOptions: bindActionCreators(showBulkOptions, dispatch),
    hideBulkOptions: bindActionCreators(hideBulkOptions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    payments: state.payments.payments,
    loading: state.payments.loading,
    bulkOptions: state.ui.bulkOptions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
