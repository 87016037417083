import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppBar, Toggle } from 'material-ui';
import { openDrawer, testMode, liveMode } from '../../actions/Ui';

const styles = {
  appBarTest: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0
  },
  appBarLive: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: '#09cece'
  },
  toggle: {
    backgroundColor: 'white',
    position: 'fixed',
    right: 130,
    top: 12,
    width: 10,
    padding: 5
  }
};

class Header extends Component {
  static propTypes = {
    children: PropTypes.node,
    openDrawer: PropTypes.func,
    testMode: PropTypes.func,
    liveMode: PropTypes.func,
    user: PropTypes.object,
    title: PropTypes.string
  };

  state = {
    testMode:
      localStorage.getItem('userOptions') == null
        ? false
        : JSON.parse(localStorage.getItem('userOptions')).testMode == null
          ? false
          : JSON.parse(localStorage.getItem('userOptions')).testMode
  };

  setToggle = () => {
    const { liveMode, testMode } = this.props;

    if (this.state.testMode) {
      this.setState({ testMode: false });
      liveMode();
    } else {
      this.setState({ testMode: true });
      testMode();
    }

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  render() {
    const { openDrawer, title, user } = this.props;
    const { testMode } = this.state;

    var userOptions = { testMode };
    localStorage.setItem('userOptions', JSON.stringify(userOptions));

    let Toggling;
    if (user && user.id != null) {
      Toggling = (
        <Toggle
          id="mode"
          key={'mode'}
          toggled={!testMode}
          onToggle={this.setToggle}
          style={styles.toggle}
          ref="labeling"
          label={testMode ? 'TestMode' : 'LiveMode'}
        />
      );
    } else {
      Toggling = null;
    }

    return (
      <AppBar
        style={testMode ? styles.appBarTest : styles.appBarLive}
        onLeftIconButtonClick={openDrawer}
      >
        <div>
          <div
            style={{
              paddingTop: '20px',
              position: 'fixed',
              right: '50%',
              color: 'white'
            }}
          >
            {title}
          </div>

          {Toggling}
        </div>
      </AppBar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    openDrawer: bindActionCreators(openDrawer, dispatch),
    liveMode: bindActionCreators(liveMode, dispatch),
    testMode: bindActionCreators(testMode, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
