//Here is a list component of all coursio users
import PropTypes from 'prop-types';

import React from 'react';
import {
  Paper,
  Checkbox,
  List,
  ListItem,
} from 'material-ui';

const styles = {
  list: {
    margin: 8
  },
  listItem: {
    display: 'flex',
    alignItems: 'center'
  },
  listEmpty: {
    margin: 8,
    padding: 8
  },
  checkbox: {
    maxWidth: 30,
    float: 'left',
    left: '10px'
  }
};

class EmailsList extends React.Component {
  static propTypes = {
    contacts: PropTypes.array,
    onCheckItem: PropTypes.func
  };

  state = {
    contactsSelected: []
  };


  checkEmails = (email) => {
    const { onCheckItem } = this.props;
    const { contactsSelected } = this.state;

    let newContactsList;
    const isSelected = contactsSelected.some(e => e.email === email)
    if (isSelected) {
      // item exists so remove item
      newContactsList = contactsSelected.filter((contact) => contact.email !== email)
      this.setState({ contactsSelected: [...newContactsList] });
    } else {
      newContactsList = [...contactsSelected]
      // item does not exist, so add item
      newContactsList.push({ email: email });
      this.setState({ contactsSelected: [...newContactsList] });
    }

    onCheckItem && onCheckItem(newContactsList);
  };

  render() {
    const { contacts } = this.props;
    const { contactsSelected } = this.state;

    return (
      <Paper style={styles.list}>
        <List>
          {contacts.map((contact, index) => {
            const isChecked = contactsSelected.some(e => e.email === contact.email)
            const check = (
              <div>
                <Checkbox
                  ref="check"
                  onCheck={this.checkEmails.bind(this, contact.email)}
                  checked={isChecked}
                  style={styles.checkbox}
                />
              </div>
            );

            return (
              <div key={index} style={styles.listItem}>
                {check}
                <div style={{ width: '100%' }}>
                  <ListItem
                    onClick={this.checkEmails.bind(this, contact.email)}
                    primaryText={contact.email}
                    secondaryText={contact.endDate ? `End date: ${contact.endDate}` : ''}
                  />
                </div>
              </div>
            );
          })}
        </List>
      </Paper>
    );
  }
}

export default EmailsList;
