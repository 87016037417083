import SteelCompass from '../services/SteelCompass';
import {
  FIND_USER_BY_ID,
  UPDATE_USER,
  CREATE_USER,
  CREATE_DEMO_ACCOUNT,
  HIDE_USER,
  GET_FORTNOX,
  SET_FORTNOX
} from '../constants/CurrUserActionTypes';


export function pushToFortnox(userId, month) {
  return {
    type: 'PUSH_FORTNOX',
    userId,
    promise: SteelCompass.request(
      'mr.wolf-accounting',
      'pushToFortnox',
      { userId, month }
    )
  };
}

export function getFortnoxUser(userId) {
  return {
    type: GET_FORTNOX,
    userId,
    promise: SteelCompass.request(
      'mr.wolf-accounting',
      'fortnoxSettings',
      { userId }
    )
  };
}


export function setFortnoxUser(userId, { supplierId, customerId, methods }) {
  return {
    type: SET_FORTNOX,
    userId,
    promise: SteelCompass.request(
      'mr.wolf-accounting',
      'fortnoxSettings',
      { userId, supplierId, customerId, methods }
    )
  };
}

//find user by id
export function findUserById(id, pageStart = 0, pageLength = 15) {
  return {
    type: FIND_USER_BY_ID,
    id,
    promise: SteelCompass.request(
      'mr.wolf-user',
      'findById',
      { id },
      pageStart,
      pageLength
    )
  };
}

//create new user
export function createUser({ email, password, username, displayName, roles }) {
  return {
    type: CREATE_USER,
    email,
    password,
    username,
    displayName,
    roles,
    promise: SteelCompass.request('mr.wolf-user', 'create', {
      email,
      password,
      username,
      displayName,
      roles
    })
  };
}

export function batchCreateUsersAndConnect(batch, recipientEmail = null) {
  return {
    type: null,
    batch,
    recipientEmail,
    promise: SteelCompass.request('mr.wolf-user', 'batchCreateAndConnect', {
      batch,
      recipientEmail
    })
  };
}

// update user course permisison
export function updateUserCoursePermission({ id, mode }) {
  return {
    type: null,
    promise: SteelCompass.request('mr.wolf-ucp', 'update', {
      id,
      mode
    })
  };
}

// list user course permission
export function listUserCoursePermission({ courseId, userId, type = 1 }) {
  return {
    type: UPDATE_USER,
    courseId,
    userId,
    promise: SteelCompass.request('mr.wolf-ucp', 'list', {
      courseId,
      userId,
      type
    })
  };
}

export function inactivateUser(id, active) {
  return {
    type: UPDATE_USER,
    id,
    active,
    promise: SteelCompass.request('mr.wolf-user', 'update', {
      id,
      active
    })
  };
}
export function freezeUser(id) {
  return {
    type: UPDATE_USER,
    id,
    promise: SteelCompass.request('user', 'freeze', {
      id,
    })
  };
}
export function thawUser(id) {
  return {
    type: UPDATE_USER,
    id,
    promise: SteelCompass.request('user', 'thaw', {
      id,
    })
  };
}

//update user data
export function updateUser(
  id,
  email,
  username,
  password,
  displayName,
  address,
  city,
  country,
  vatNumber,
  zip,
  roles,
  textRoles,
  paymentProviders,
  noCourseExpire,
) {
  return {
    type: UPDATE_USER,
    id,
    email,
    username,
    password,
    displayName,
    address,
    city,
    country,
    vatNumber,
    zip,
    roles,
    textRoles,
    paymentProviders,
    noCourseExpire,
    promise: SteelCompass.request('mr.wolf-user', 'update', {
      id,
      email,
      username,
      password,
      displayName,
      address,
      city,
      country,
      vatNumber,
      zip,
      roles,
      textRoles,
      noCourseExpire,
      payment: { providers: paymentProviders }
    })
  };
}

//create a demo user account
export function createDemoAccount(username, password, courseIds) {
  return {
    type: CREATE_DEMO_ACCOUNT,
    username,
    password,
    courseIds,
    promise: SteelCompass.request('mr.wolf-user', 'createDemoAccount', {
      username,
      password,
      courseIds
    })
  };
}

//hide a user
export function hideUser(id) {
  return {
    type: HIDE_USER,
    id,
    promise: SteelCompass.request('mr.wolf-user', 'stash', { id })
  };
}
