import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from './lib/promiseMiddleware';
import reducers from '../reducers';

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware)(
  createStore
);

export default function store(initialState) {
  return createStoreWithMiddleware(
    reducers,
    initialState,
    window.devToolsExtension ? window.devToolsExtension() : f => f
  );
}
