//This is the filtering component for filtering the courses list
//which comes from all coursio courses, filtering is based on course name
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Paper, TextField } from 'material-ui';

const styles = {
  block: {
    maxWidth: 1024,
    margin: '16px auto',
    padding: 8
  }
};

class FilterCourses extends Component {
  static propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func,
    courses: PropTypes.object
  };

  state = {
    searchText: null
  };

  static contextTypes = {
    router: PropTypes.object
  };

  filter() {
    const { onChange } = this.props;
    const { searchText } = this.state;
    onChange && onChange(searchText);
  }

  tryFilter() {
    if (this._timer) {
      clearTimeout(this._timer);
    }
    this._timer = setTimeout(() => {
      this.filter();
      this._timer = null;
    }, 500);
  }

  //There is a course saved with en empty name
  //When the value became === '' then it brought that specific
  //course and this is why I do the following check
  changeSearchText = event => {
    const { value } = event.target;
    const { router } = this.context;
    if (value === '') {
      this.setState({ searchText: null }, () => {
        this.tryFilter();
      });
      router.push('/allcourses');
    } else {
      this.setState({ searchText: value }, () => {
        this.tryFilter();
      });
      router.push('/allcourses');
    }
  };

  render() {
    const { searchText } = this.state;
    return (
      <Paper style={styles.block}>
        <TextField
          value={searchText || ''}
          fullWidth
          onChange={this.changeSearchText}
          floatingLabelText={'Search Course'}
          autoFocus
        />
      </Paper>
    );
  }
}

export default FilterCourses;
