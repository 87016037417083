export const SCAN_RECEIPT = 'SCAN_RECEIPT';
export const SCAN_RECEIPT_REQUEST = 'SCAN_RECEIPT_REQUEST';

export const GENERATE_CS_STATS = 'GENERATE_CS_STATS';
export const GENERATE_CS_STATS_REQUEST = 'GENERATE_CS_STATS_REQUEST';

export const CUSTOMER_SALES_COEFFICIENTS = 'CUSTOMER_SALES_COEFFICIENTS';
export const CUSTOMER_SALES_COEFFICIENTS_REQUEST =
  'CUSTOMER_SALES_COEFFICIENTS_REQUEST';

export const GENERATE_INVITATIONS_LIST = 'GENERATE_INVITATIONS_LIST';
export const GENERATE_INVITATIONS_LIST_REQUEST =
  'GENERATE_INVITATIONS_LIST_REQUEST';

export const GENERATE_PAYMENTS_LIST = 'GENERATE_PAYMENTS_LIST';
export const GENERATE_PAYMENTS_LIST_REQUEST = 'GENERATE_PAYMENTS_LIST_REQUEST';

export const GENERATE_PERMISSIONS_LIST = 'GENERATE_PERMISSIONS_LIST';
export const GENERATE_PERMISSIONS_LIST_REQUEST =
  'GENERATE_PERMISSIONS_LIST_REQUEST';
