//this is a form component for creating a demo user account
import PropTypes from 'prop-types';

import React from 'react';
import { TextField, RaisedButton } from 'material-ui';

class CreateUserForm extends React.Component {
  static propTypes = {
    onCreateDemoAccount: PropTypes.func
  };

  handleSubmit = event => {
    event.preventDefault();

    const { onCreateDemoAccount } = this.props;

    const username = this.refs.username.getValue();
    const password = this.refs.password.getValue();
    const courseID = this.refs.courseId.getValue();

    if (onCreateDemoAccount) {
      onCreateDemoAccount(username, password, courseID);
    }
  };

  render() {
    return (
      <div>
        <h6>Fields with red * are mandatory</h6>
        <h3>
          <center>Demo Account Data</center>
        </h3>
        <TextField
          fullWidth
          hintText=""
          floatingLabelText="Username"
          errorText="*"
          ref="username"
          type="text"
          required={true}
        />
        <TextField
          fullWidth
          hintText=""
          floatingLabelText="Password"
          errorText="*"
          ref="password"
          type="password"
          required={true}
        />
        <TextField
          fullWidth
          hintText=""
          floatingLabelText="Course ID"
          errorText="*"
          ref="courseId"
          type="array"
          required={true}
        />

        <RaisedButton
          primary
          fullWidth
          label="Create Demo Account"
          onClick={this.handleSubmit}
        />
      </div>
    );
  }
}

export default CreateUserForm;
