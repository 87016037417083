import { FORTNOX_INIT, COMPLETE_AUTH } from "../constants/VendorActionTypes";

const initialState = {
  loading: true,
  fortnox: {}
};

export default function vendorReducer(state = initialState, action) {
  switch (action.type) {
    case FORTNOX_INIT:
      return {
        ...state,
        fortnoxUrl: action.res,
      };
    case COMPLETE_AUTH:
      console.log('action', action)
      return {
        ...state,
      };
    default:
      return state;
  }
}
