import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  salesCoefficients,
  generateActiveClientsList,
  generatePermissionsList,
} from '../../actions/Accounting';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  RaisedButton,
  Snackbar,
  TextField,
  Toggle,
} from 'material-ui';

const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    marginBottom: 24,
    marginTop: 32,
    width: 200
  },
  status: {
    marginBottom: 32
  },
  flex1: { flex: 1 },
  flex: {
    display: 'flex',
  }
};


class ConnectedUsers extends Component {
  static propTypes = {
    salesCoefficients: PropTypes.func,
    generatePermissionsList: PropTypes.func,
    generateActiveClientsList: PropTypes.func,
  };

  state = {
    message: null,
    open: false,
    value: 'a',
    error: 'This field is mandatory',
    submit: true,
    idState: '',
    costState: '',
    percentageState: '',
    uniqueUsers: false,
    fromCircles: false,
    ucpData: false,
    uniqueUsersPermissions: false,
    status: 0,
    paymentStatus: 0,
    hidePurchases: false,
    paymentFormat: 0,
    emailsSelected: [],
  };

  clearMessage = () => {
    this.setState({ message: null, open: false });
  };

  setUniqueUsers = () => {
    this.setState({ uniqueUsers: !this.state.uniqueUsers });
  };


  togglePurches = () => {
    this.setState({ hidePurchases: !this.state.hidePurchases });
  };

  setUniqueUsersPermissions = () => {
    this.setState({
      uniqueUsersPermissions: !this.state.uniqueUsersPermissions
    });
  };

  handlePermissionsList = () => {
    const ownerId =
      String(this.refs.ownerIdPermissions.getValue()) || undefined;
    const startDate =
      String(this.refs.startDatePermissions.getValue()) || undefined;
    const endDate =
      String(this.refs.endDatePermissions.getValue()) || undefined;
    const uniqueUsers = this.state.uniqueUsersPermissions;
    const hidePurchases = this.state.hidePurchases;

    const { generatePermissionsList } = this.props;
    generatePermissionsList({
      ownerId,
      startDate,
      endDate,
      uniqueUsers,
      hidePurchases
    }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'Data sent to your email'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  };

  render() {
    const { message, open } = this.state;

    return (
      <div>
        <h2 style={styles.headline}>Connected users report</h2>
        <p>Below you can get a connected users report</p>

        <TextField
          hintText="Owner's user ID"
          floatingLabelText="Owner's user ID"
          ref='ownerIdPermissions'
          type='text'
        />
        <br />
        <TextField
          floatingLabelText='Start date'
          ref='startDatePermissions'
          type='date'
        />
        <br />
        <TextField
          floatingLabelText='End date'
          ref='endDatePermissions'
          type='date'
        />
        <br />
        <Toggle
          style={styles.toggle}
          toggled={this.state.uniqueUsersPermissions}
          onToggle={this.setUniqueUsersPermissions}
          id='0'
          ref='uniqueUsersPermissions'
          label='Only unique users'
        />
        <Toggle
          label='Hide purchases'
          toggled={this.state.hidePurchases}
          onToggle={this.togglePurches}
          style={styles.toggle}
        />
        <br />
        <RaisedButton
          label='Submit'
          onClick={this.handlePermissionsList}>
          <Snackbar
            message={message || ''}
            open={!!open}
            autoHideDuration={3000}
            onRequestClose={this.clearMessage}
          />
        </RaisedButton>
      </div>

    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    generateActiveClientsList: bindActionCreators(generateActiveClientsList, dispatch),
    generatePermissionsList: bindActionCreators(generatePermissionsList, dispatch),
    salesCoefficients: bindActionCreators(salesCoefficients, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUsers);
