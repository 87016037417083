import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, List, ListItem, Checkbox } from 'material-ui';
import ActionDone from 'material-ui/svg-icons/action/done';

const styles = {
  list: {
    margin: 8
  },
  listEmpty: {
    margin: 8,
    padding: 8
  },
  checkbox: {
    maxWidth: 30,
    float: 'left',
    marginTop: '20px',
    left: '10px'
  }
};

class InvitationsList extends Component {
  static propTypes = {
    children: PropTypes.node,
    invitations: PropTypes.object,
    onChooseInvitation: PropTypes.func,
    onCheckInvitations: PropTypes.func
  };

  state = {
    invitationsChosen: {}
  };

  getInvitation = invitation => {
    const { onChooseInvitation } = this.props;
    onChooseInvitation && onChooseInvitation(invitation);
  };

  checkInvitations = (invitation, checked) => {
    const { onCheckInvitations } = this.props;
    const { invitationsChosen } = this.state;

    if (checked) {
      if (Object.keys(this.state.invitationsChosen).length === 0) {
        // map is empty, so add item
        invitationsChosen[invitation.id] = invitation;
      } else {
        if (invitation.id in invitationsChosen) {
          // item id exists so remove item
          delete invitationsChosen[invitation.id];
        } else {
          // item id does not exist, so add item
          invitationsChosen[invitation.id] = invitation;
        }
      }
    }

    onCheckInvitations && onCheckInvitations(invitationsChosen);
  };

  render() {
    const { invitations } = this.props;

    let finInvitations;
    if (window.location.pathname !== '/invitations') {
      finInvitations = <div> </div>;
    } else {
      finInvitations = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(invitations).map(ID => {
              const invitation = invitations[ID];

              let check = (
                <div>
                  <Checkbox
                    ref="check"
                    onCheck={this.checkInvitations.bind(this, invitation)}
                    checked={invitation.id in this.state.invitationsChosen}
                    style={styles.checkbox}
                  />
                </div>
              );

              if (invitation.accepted) {
                return (
                  <div key={invitation.id}>
                    {check}
                    <ListItem
                      style={{ marginLeft: '40px' }}
                      rightIcon={<ActionDone />}
                      onClick={this.getInvitation.bind(this, invitation)}
                      primaryText={invitation.course.name}
                      secondaryText={
                        invitation.id + ' (Invitation has been accepted)'
                      }
                    />
                  </div>
                );
              } else {
                return (
                  <div key={invitation.id}>
                    {check}
                    <ListItem
                      style={{ marginLeft: '40px' }}
                      onClick={this.getInvitation.bind(this, invitation)}
                      primaryText={invitation.course.name}
                      secondaryText={invitation.id}
                    />
                  </div>
                );
              }
            })}
          </List>
        </Paper>
      );
    }
    return (
      <div>
        {Object.keys(invitations).length !== 0 ? (
          finInvitations
        ) : (
          <Paper style={styles.listEmpty}>No results found</Paper>
        )}
      </div>
    );
  }
}

export default InvitationsList;
