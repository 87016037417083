import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, TextField, RaisedButton } from 'material-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { scanReceipt } from '../../actions/Accounting';
import { Snackbar, Checkbox, DropDownMenu, MenuItem } from 'material-ui';
import Header from '../Bar/Header';

const styles = {
  checkbox: {
    marginBottom: 16
  }
};

class Receipts extends Component {
  static propTypes = {
    scanReceipt: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    message: null,
    forward: false,
    videoSources: [],
    data: ''
  };

  startVideo = (event, index, videoSource) => {
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    //we dont need the audio, only video for this function
    let constraints = {
      audio: false,
      video: { deviceId: videoSource ? { exact: videoSource } : undefined }
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        window.stream = stream; // make stream available to console
        this.refs.video.srcObject = stream;
        // Refresh button list in case labels have become available
        return navigator.mediaDevices.enumerateDevices();
      })
      .then(this.gotDevices);
  };

  gotDevices = deviceInfos => {
    let videoSources = [];
    videoSources.push(
      <MenuItem key={0} value={0} primaryText="Pick a camera if you dare" />
    );

    for (var i = 0; i < deviceInfos.length; ++i) {
      var deviceInfo = deviceInfos[i];

      if (deviceInfo.kind === 'videoinput') {
        let text = deviceInfo.label || 'camera ' + (i + 1);
        videoSources.push(
          <MenuItem
            key={i + 1}
            value={deviceInfo.deviceId}
            primaryText={text}
          />
        );
        this.setState({ videoSources });
      }
    }
  };

  componentDidMount() {
    this.startVideo();
  }

  clearMessage = () => {
    this.setState({ message: null });
  };

  handleScan = () => {
    let video = this.refs.video,
      data = '',
      canvas = this.refs.canvas,
      context = canvas.getContext('2d'),
      width = video.offsetWidth,
      height = video.offsetHeight;

    if (width && height) {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(video, 0, 0, width, height);
      data = canvas.toDataURL('image/jpeg');
    } else {
      context.fillRect(0, 0, canvas.width, canvas.height);
      data = canvas.toDataURL('image/jpeg');
    }

    this.setState({ data: data });
  };

  handleSend = () => {
    const { scanReceipt } = this.props;
    const { data, forward } = this.state;
    let comments = this.refs.comments.getValue();

    if (!comments.length) {
      alert('Wrong, no comments have been added');
      return;
    }

    scanReceipt(data, comments, forward).then(res => {
      if (res.error) {
        this.setState({ message: 'There is an error' + res.error });
      } else {
        this.setState({ message: 'Receipt successfully scanned!' });
      }
    });
  };

  setChecked = () => {
    const { forward } = this.state;
    this.setState({ forward: !forward });
  };

  render() {
    const { message, forward, videoSources } = this.state;
    return (
      <div>
        <Header title="Accounting" />
        <Paper>
          <div className="camera">
            <video autoplay className="video" ref="video" width="100%">
              Video stream not available
            </video>
            <DropDownMenu
              ref="videoSelect"
              value={0}
              onChange={this.startVideo}
            >
              {videoSources}
            </DropDownMenu>

            <RaisedButton fullWidth label="Scan" onClick={this.handleScan} />
            <Snackbar
              message={message || ''}
              open={!!message}
              autoHideDuration={2500}
              onRequestClose={this.clearMessage}
            />
          </div>
          <canvas className="canvas" ref="canvas" />
          <TextField
            autoFocus
            fullWidth
            hintText=""
            floatingLabelText="Description"
            ref="comments"
            type="text"
          />
          <Checkbox
            label="CC: ProaktivEkonomi"
            style={styles.checkbox}
            checked={forward}
            onCheck={this.setChecked}
          />
          <RaisedButton
            fullWidth
            label="Send Now"
            onClick={this.handleSend}
          />
        </Paper>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    scanReceipt: bindActionCreators(scanReceipt, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    state
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipts);
