import React from 'react';
import {
  TextField,
  RaisedButton,
} from 'material-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeCourseOwner } from '../actions/Course';

const styles = {
  detail: {
    margin: 12
  },
  toggle: {
    marginBottom: 16
  },
  success: {
    color:"green"
  }
};

class ChangeCourseOwnerForm extends React.Component {
  state = {
    id: 0,
    ownerChanged:false
  };

  handleSubmit = () => {
    this.props.changeCourseOwner(this.props.courseId, this.state.id).then(
      () => this.setState({ownerChanged:true})
    );
  };

  render() {
    return (
      <div style={styles.detail}>
        <TextField
          fullWidth
          hintText=""
          floatingLabelText="New Owner ID"
          type="number"
          onChange={e => this.setState({ id: e.target.value })}
        />
        <RaisedButton
          fullWidth
          label="Change"
          onClick={this.handleSubmit}
        />
        {this.state.ownerChanged && (<h2 style={styles.success}>Owner changed</h2>)}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    changeCourseOwner: bindActionCreators(changeCourseOwner, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ChangeCourseOwnerForm);