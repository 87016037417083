import {
  FIND_USER_BY_ID,
  FIND_USER_BY_ID_REQUEST,
  CREATE_USER,
  CREATE_USER_REQUEST,
  UPDATE_USER,
  UPDATE_USER_REQUEST,
  CREATE_DEMO_ACCOUNT,
  CREATE_DEMO_ACCOUNT_REQUEST,
  HIDE_USER,
  HIDE_USER_REQUEST,
  GET_FORTNOX,
  SET_FORTNOX
} from '../constants/CurrUserActionTypes';

const initialState = {
  fortnox: {},
  loading: true,
  currUser: {},
  data: {},
  permissions: []
};

export default function currUserReducer(state = initialState, action) {
  let currUser;
  switch (action.type) {
    case SET_FORTNOX:
    case GET_FORTNOX:
      const fortnox = action.res;
      return {
        ...state,
        fortnox
      };
    case FIND_USER_BY_ID:
      currUser = action.res;
      return {
        ...state,
        loading: false,
        currUser
      };
    case FIND_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_USER:
      currUser = action.res;
      return {
        ...state,
        loading: false,
        currUser
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER:
      currUser = action.res;
      if(action.res.length) {
        return {
          ...state,
          loading: false,
          permissions: action.res
        }
      }
      return {
        ...state,
        loading: false,
        currUser
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_DEMO_ACCOUNT:
      currUser = action.res;
      return {
        ...state,
        loading: true,
        currUser
      };
    case CREATE_DEMO_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: false
      };
    case HIDE_USER:
      currUser = action.res;
      return {
        ...state,
        loading: false,
        currUser
      };
    case HIDE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
