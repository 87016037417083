import {
  CUSTOMER_SALES_COEFFICIENTS,
  CUSTOMER_SALES_COEFFICIENTS_REQUEST
} from '../constants/AccountingActionTypes';

const initialState = {
  data: {}
};

export default function currUserReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_SALES_COEFFICIENTS:
      return {
        data: action.res
      };
    case CUSTOMER_SALES_COEFFICIENTS_REQUEST:
      return {
        ...state
      };
    default:
      return state;
  }
}
