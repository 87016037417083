import { GET_EMAILS_AS_TEST_ACCOUNTS, GET_EMAILS_AS_TEST_ACCOUNTS_REQUEST } from '../constants/TestUsersActionTypes';

const initialState = {
  loading: true,
  emails: []
};



export default function testUsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMAILS_AS_TEST_ACCOUNTS:
      return {
        loading: false,
        emails: action.res
      };
    case GET_EMAILS_AS_TEST_ACCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

