import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Bar/Header';
import {
  Paper,
  Tabs,
  Tab,
} from 'material-ui';
import SalesCoefficients from './SalesCoefficients';
import UcaExport from './UcaExport';
import CsStats from './CsStats';
import InvitationsListReport from './InvitationsListReport';
import PaymentsList from './PaymentsList';
import ConnectedUsers from './ConnectedUsers';
import ActiveClients from './ActiveClients';
import ResellerCuts from './ResellerCuts';

const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    marginBottom: 24,
    marginTop: 32,
    width: 200
  },
  status: {
    marginBottom: 32
  },
  flex: {
    display: 'flex',
  }
};

class StatsAndCoefficients extends Component {
  static propTypes = {};

  state = {
    value: 'a',
    status: 0,
  };

  handleChange = value => {
    this.setState({
      value: value
    });
  };

  render() {
    return (
      <div>
        <Header title='Stats and Coefficients' />
        <Paper style={styles.stats}>
          <Tabs value={this.state.value} onChange={this.handleChange}>
            <Tab label='Generate CS Stats' value='a'><CsStats /></Tab>
            <Tab label='UCA' value='b'><UcaExport /></Tab>
            <Tab label='Sales Coefficients' value='c'>
              <div style={styles.flex}>
                <SalesCoefficients />
                <ResellerCuts />
              </div>
            </Tab>
            <Tab label='Invitations list' value='d'><InvitationsListReport /></Tab>
            <Tab label='Payments list' value='e'><PaymentsList /></Tab>
            <Tab label='Connected users report' value='f'><ConnectedUsers /></Tab>

            <Tab label='Active clients' value='g'>
              <ActiveClients />
            </Tab>
          </Tabs>
        </Paper>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatsAndCoefficients);
