//This is the reducer for bringing all KPI
import {
  GET_CS_SALES,
  GET_CS_SALES_REQUEST,
  GET_CS_INVITES,
  GET_CS_INVITES_REQUEST
} from '../constants/KpiActionTypes';

const initialState = {
  loading: true,
  topSales: [],
  topInvites: []
};

export default function kpiReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CS_SALES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_CS_SALES:
      return {
        ...state,
        topSales: action.res,
        loading: false
      };
    case GET_CS_INVITES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_CS_INVITES:
      return {
        ...state,
        topInvites: action.res,
        loading: false
      };
    default:
      return state;
  }
}
