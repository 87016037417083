import SteelCompass from '../services/SteelCompass';
import {
  DELETE_COURSE,
  FIND_COURSE_BY_ID,
  DUPLICATE_COURSE,
  UPDATE_COURSE,
  CHANGE_OWNER_COURSE
} from '../constants/CurrCourseActionTypes';

//delete course function for the logged in user
export function deleteCourse(id) {
  return {
    type: DELETE_COURSE,
    id,
    promise: SteelCompass.request('mr.wolf-course', 'delete', { id })
  };
}

//this function brings a course by ID
export function findCourseById(id) {
  return {
    type: FIND_COURSE_BY_ID,
    id,
    promise: SteelCompass.request('mr.wolf-course', 'findById', { id })
  };
}

//this duplicates a course , to your account or possibly to another user account
export function duplicateCourse(id, userId) {
  return {
    type: DUPLICATE_COURSE,
    id,
    userId,
    promise: SteelCompass.request('mr.wolf-course', 'duplicate', { id, userId })
  };
}

//this change thw owern of a course to a new owner based on ID
export function changeCourseOwner(id, userId) {
  return {
    type: CHANGE_OWNER_COURSE,
    id,
    userId,
    promise: SteelCompass.request('mr.wolf-course', 'setOwnerId', { id, userId })
  };
}

//this updates a course
export function updateCourse(id, name, keepSlug, price, ucpPeriod, verypublic) {
  return {
    type: UPDATE_COURSE,
    id,
    name,
    keepSlug: true,
    price,
    ucpPeriod,
    public: verypublic,
    promise: SteelCompass.request('mr.wolf-course', 'update', {
      id,
      name,
      keepSlug,
      price,
      ucpPeriod,
      public: verypublic
    })
  };
}
