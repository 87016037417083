import {
  FIND_INVITATION_BY_ID,
  FIND_INVITATION_BY_ID_REQUEST,
  DELETE_INVITATION,
  DELETE_INVITATION_REQUEST,
  RESEND_INVITATION,
  RESEND_INVITATION_REQUEST,
  GENERATE_GIFT
} from '../constants/CurrInvitationActionTypes';

const initialState = {
  loading: true,
  currInvitation: {},
  giftCodes:null
};

export default function currInvitationReducer(state = initialState, action) {
  let currInvitation;
  let giftCodes;
  
  switch (action.type) {
    case FIND_INVITATION_BY_ID:
      currInvitation = action.res;
      return {
        loading: false,
        currInvitation
      };
    case FIND_INVITATION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_INVITATION:
      return {
        loading: false,
        currInvitation
      };
    case DELETE_INVITATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RESEND_INVITATION:
      currInvitation = action.res;
      return {
        loading: false,
        currInvitation
      };
    case RESEND_INVITATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GENERATE_GIFT:
      giftCodes = action.res.codes;
      return {
        giftCodes,
        loading: true
      };
    default:
      return state;
  }
}
