//here is the list of the courses related to the current user
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { List, ListItem, Paper } from 'material-ui';

const styles = {
  list: {
    margin: 0
  }
};

class CurrUserStudentsList extends Component {
  static propTypes = {
    readers: PropTypes.array,
    params: PropTypes.object
  };

  render() {
    const { readers, params } = this.props;

    let finStudents;
    if (window.location.pathname !== `/users/${params.userId}`) {
      finStudents = <div> </div>;
    } else {
      finStudents = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(readers).map(id => {
              const reader = readers[id];

              return (
                <ListItem
                  key={id}
                  primaryText={reader.email + ' (id: ' + reader.id + ' )'}
                  secondaryText={
                    'Last Active ' + reader.last_active.substring(0, 10)
                  }
                />
              );
            })}
          </List>
        </Paper>
      );
    }

    return <div>{finStudents}</div>;
  }
}

export default CurrUserStudentsList;
