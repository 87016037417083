import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RaisedButton, TextField } from 'material-ui';

const styles = {
  but: {
    maring: 12
  },
  errorStyle: {
    color: '#00BCD4',
    fontSize: '15px'
  },
  block1: {
    display: 'inline-block',
    marginRight: '10px',
    width: '50%',
    position: 'relative',
    left: '20px'
  },
  block2: {
    display: 'inline-block',
    width: '40%',
    position: 'relative',
    top: '-20px',
    left: '40px'
  },
  block3: {
    position: 'relative',
    left: '40%',
    padding: '10px'
  }
};

class DuplicateCourseForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    duplicateCourse: PropTypes.func,
    params: PropTypes.object,
    user: PropTypes.object,
    currCourse: PropTypes.object
  };

  handleSubmit = event => {
    const { duplicateCourse, params } = this.props;
    event.preventDefault();
    const userID = this.refs.userID.getValue();

    duplicateCourse && duplicateCourse(params.courseId, userID);
  };

  render() {
    return (
      <div>
        <div style={styles.block1}>
          <h3>Be careful: </h3>
          {this.props.currCourse.createdBy === this.props.user.id
            ? 'The course belongs to you and pressing Duplicate button will duplicate it to your account'
            : "The course belongs to someone else and pressing the Duplicate button without entering a user ID will duplicate it to that user's account"}
        </div>

        <p style={styles.block1}>
          If you add user ID, and then press Duplicate, the course will be
          duplicated to the user account you chose.
        </p>
        <TextField
          hintText="Type User Id Here and then press Duplicate"
          floatingLabelText=""
          ref="userID"
          type="text"
          style={styles.block2}
          hintStyle={styles.errorStyle}
        />

        <div style={styles.block3}>
          <RaisedButton label="Duplicate" onClick={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

export default DuplicateCourseForm;
