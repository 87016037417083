import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  List,
  ListItem,
  Paper,
  IconMenu,
  IconButton,
  Divider
} from 'material-ui';
import ActionRestore from 'material-ui/svg-icons/action/restore';

const styles = {
  list: {
    margin: 8
  }
};

class CurrUserPaymentsList extends Component {
  static propTypes = {
    children: PropTypes.node,
    payments: PropTypes.object,
    onRefundPaymentNow: PropTypes.func,
    params: PropTypes.object
  };

  refundPaymentNow = payment => {
    const { onRefundPaymentNow } = this.props;
    onRefundPaymentNow && onRefundPaymentNow(payment);
  };

  render() {
    const { payments, params } = this.props;

    if (payments.loading) {
      return <p>Search Payments</p>;
    }

    let finPayments;
    if (window.location.pathname !== `/users/${params.userId}`) {
      finPayments = <div />;
    } else {
      finPayments = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(payments).map(id => {
              const payment = payments[id];

              const rightIconMenu = (
                <div>
                  <IconMenu
                    iconButtonElement={
                      <IconButton
                        tooltip="Refund payment"
                        tooltipPosition="bottom-center"
                        onClick={this.refundPaymentNow.bind(this, payment)}
                      >
                        <ActionRestore />
                      </IconButton>
                    }
                  />
                </div>
              );

              return (
                <List key={payment.id}>
                  <ListItem
                    primaryText={payment.id}
                    secondaryText={
                      'Amount: ' +
                      payment.amount +
                      ' ' +
                      payment.currency +
                      ', Created on ' +
                      payment.created.substring(0, 10)
                    }
                    rightIconButton={rightIconMenu}
                  />
                  <Divider />
                </List>
              );
            })}
          </List>
        </Paper>
      );
    }

    return (
      <div>
        {Object.keys(payments).length === 0 ? (
          <p
            style={{
              textAlign: 'center',
              verticalAlign: 'middle',
              marginTop: '30px'
            }}
          >
            None Found
          </p>
        ) : (
          finPayments
        )}
      </div>
    );
  }
}

export default CurrUserPaymentsList;
