import PropTypes from "prop-types";
import React, { Component } from "react";
import { TextField, RaisedButton, Toggle, Divider, MenuItem, SelectField } from "material-ui";
import ROLE from "../constants/Global";

const styles = {
  detail: {
    margin: 12
  },
  toggle: {
    marginBottom: 16
  }
};

class UpdateUserForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    onUpdate: PropTypes.func,
    params: PropTypes.object,
    ROLE: PropTypes.array,
    currUser: PropTypes.object
  };

  state = {
    value: 0,
    rolesValue: this.props.currUser.roles.value,
    noCourseExpire: this.props.currUser.noCourseExpire,
    paymentProviders: {
      stripe: this.props.currUser.payment.providers.stripe,
      klarna: this.props.currUser.payment.providers.klarna
    }
  };

  handleChange = (event, index, value) => this.setState({value});

  handleSubmit = event => {
    const { onUpdate, params } = this.props;
    const { rolesValue, paymentProviders, noCourseExpire } = this.state;
    event.preventDefault();

    const email = this.refs.email.getValue();
    const username = this.refs.username.getValue();
    const displayName = this.refs.displayName.getValue();
    const password = this.refs.password.getValue();
    const address = this.refs.address && this.refs.address.getValue();
    const city = this.refs.city && this.refs.city.getValue();
    const country = this.refs.country && this.refs.country.getValue();
    const vatNumber = this.refs.vatNumber && this.refs.vatNumber.getValue();
    const zip = this.refs.zip && this.refs.zip.getValue();

    onUpdate &&
      onUpdate(
        params.userId,
        email,
        username,
        password,
        displayName,
        address,
        city,
        country,
        vatNumber,
        zip,
        rolesValue,
        "",
        paymentProviders,
        noCourseExpire
      );
  };

  setToggle = (roleMask, event) => {
    let { rolesValue } = this.state;
    const { checked } = event.target;

    (checked && this.setState({ rolesValue: rolesValue | roleMask })) ||
      this.setState({ rolesValue: rolesValue ^ roleMask });
  };

  setProviders = (roleMask, event) => {
    const { checked } = event.target;
    let { paymentProviders } = this.state;

    paymentProviders[roleMask] = checked;
    this.setState(paymentProviders);
  };

  handleUpdateFortnox = () => {
    const supplier = this.refs.supplier.getValue();
    const customer = this.refs.customer.getValue();
    const methods = this.refs.methods.getValue();

    this.props.updateFortnox(this.props.params.userId, {
      supplierId: supplier && supplier,
      customerId: customer && customer,
      methods: methods && methods.split(/[\s,]+/)
    });
  };

  handleExpireToggle = (e) => {
    this.setState({noCourseExpire: e.target.checked});
  }

  handlePush = () => {
    this.props.pushFortnox(this.props.params.userId, this.state.value !== 0 && this.state.value);
  };

  render() {
    const { currUser, fortnox } = this.props;
    const { address, city, vatNumber, country, zip } = currUser.hasOwnProperty('company') && currUser.company;
    const isOwner = currUser.hasOwnProperty('roles') && currUser.roles.text.includes('owner')

    return (
      <div style={styles.detail}>
        <TextField
          fullWidth
          id="email"
          defaultValue={currUser.email}
          hintText=""
          floatingLabelText="User Email"
          ref="email"
          type="text"
        />

        <TextField
          fullWidth
          id="username"
          defaultValue={currUser.username}
          hintText=""
          floatingLabelText="User Username"
          ref="username"
          type="text"
        />

        <TextField
          fullWidth
          id="displayName"
          defaultValue={currUser.displayName}
          hintText=""
          floatingLabelText="User Display Name"
          ref="displayName"
          type="text"
        />

        <TextField
          fullWidth
          id="password"
          hintText=""
          floatingLabelText="User Password"
          ref="password"
          type="text"
        />

        <h4>Course expiration</h4>

        <Toggle
          id="expiration"
          toggled={this.state.noCourseExpire}
          onToggle={this.handleExpireToggle}
          style={styles.toggle}
          ref="expiration"
          label="Possibility to have courses that never expire"
        />

        {
            isOwner &&
            <>
                <TextField
                    fullWidth
                    id="address"
                    defaultValue={address}
                    floatingLabelText="Address"
                    ref="address"
                    type="text"
                />

                <TextField
                    fullWidth
                    id="city"
                    defaultValue={city}
                    floatingLabelText="City"
                    ref="city"
                    type="text"
                />
                <TextField
                    fullWidth
                    id="country"
                    defaultValue={country}
                    floatingLabelText="Country"
                    ref="country"
                    type="text"
                />

                <TextField
                    fullWidth
                    id="vat"
                    defaultValue={vatNumber}
                    floatingLabelText="VAT number"
                    ref="vatNumber"
                    type="text"
                />

                <TextField
                    fullWidth
                    id="zip"
                    defaultValue={zip}
                    floatingLabelText="Zip"
                    ref="zip"
                    type="text"
                />
            </>
        }

        <h4>User Roles</h4>
        <Toggle
          id="0"
          toggled={!!(this.state.rolesValue & ROLE.reader)}
          onToggle={this.setToggle.bind(this, ROLE.reader)}
          style={styles.toggle}
          ref="reader"
          label="Reader"
        />
        <Toggle
          id="1"
          toggled={!!(this.state.rolesValue & ROLE.teacher)}
          onToggle={this.setToggle.bind(this, ROLE.teacher)}
          style={styles.toggle}
          ref="teacher"
          label="Teacher"
        />
        <Toggle
          id="2"
          toggled={!!(this.state.rolesValue & ROLE.accountant)}
          onToggle={this.setToggle.bind(this, ROLE.accountant)}
          style={styles.toggle}
          ref="accountant"
          label="Accountant"
        />
        <Toggle
          id="3"
          toggled={!!(this.state.rolesValue & ROLE.editor)}
          onToggle={this.setToggle.bind(this, ROLE.editor)}
          style={styles.toggle}
          ref="editor"
          label="Editor"
        />
        <Toggle
          id="4"
          toggled={!!(this.state.rolesValue & ROLE.owner)}
          onToggle={this.setToggle.bind(this, ROLE.owner)}
          style={styles.toggle}
          ref="owner"
          label="Owner"
        />
        <Toggle
          id="5"
          toggled={!!(this.state.rolesValue & ROLE.tester)}
          onToggle={this.setToggle.bind(this, ROLE.tester)}
          style={styles.toggle}
          ref="tester"
          label="Tester"
        />
        <Toggle
          id="6"
          toggled={!!(this.state.rolesValue & ROLE.assistant)}
          onToggle={this.setToggle.bind(this, ROLE.assistant)}
          style={styles.toggle}
          ref="assistant"
          label="Assistant"
        />

        <h4>Payment Providers</h4>
        <Divider />
        <Toggle
          id="7"
          toggled={!!this.state.paymentProviders["klarna"]}
          onToggle={this.setProviders.bind(this, "klarna")}
          style={styles.toggle}
          ref="klarna"
          label="Klarna"
        />
        <Toggle
          id="8"
          toggled={!!this.state.paymentProviders["stripe"]}
          onToggle={this.setProviders.bind(this, "stripe")}
          style={styles.toggle}
          ref="stripe"
          label="Stripe"
        />

        <RaisedButton
          fullWidth
          label="Update User"
          onClick={this.handleSubmit}
        />

        <h4>Fortnox</h4>

        <TextField
          fullWidth
          id="supplier"
          defaultValue={fortnox && fortnox.supplierId}
          hintText=""
          floatingLabelText="Supplier"
          ref="supplier"
          type="text"
        />

        <TextField
          fullWidth
          id="customer"
          defaultValue={fortnox && fortnox.customerId}
          hintText=""
          floatingLabelText="Customer"
          ref="customer"
          type="text"
        />

        <TextField
          fullWidth
          id="methods"
          defaultValue={fortnox && fortnox.methods.join(", ")}
          hintText=""
          floatingLabelText="Methods"
          errorText="Methods can only be invites or webshop or both. Separate by comma or space"
          errorStyle={{ color: "gray" }}
          ref="methods"
          type="text"
        />

        <RaisedButton
          primary
          fullWidth
          label="Update Fortnox"
          onClick={this.handleUpdateFortnox}
        />

<SelectField
          floatingLabelText="A month to overwrite, if necessary"
          value={this.state.value}
          onChange={this.handleChange}
        >
          <MenuItem value={0} primaryText="None" />
          <MenuItem value={1} primaryText="January" />
          <MenuItem value={2} primaryText="February" />
          <MenuItem value={3} primaryText="Mars" />
          <MenuItem value={4} primaryText="April" />
          <MenuItem value={5} primaryText="May" />
          <MenuItem value={6} primaryText="June" />
          <MenuItem value={7} primaryText="July" />
          <MenuItem value={8} primaryText="August" />
          <MenuItem value={9} primaryText="September" />
          <MenuItem value={10} primaryText="October" />
          <MenuItem value={11} primaryText="November" />
          <MenuItem value={12} primaryText="December" />
        </SelectField>

        <RaisedButton
          fullWidth
          label="Push data to fortnox"
          onClick={this.handlePush}
        />
      </div>
    );
  }
}

export default UpdateUserForm;
