//here is where the links to components are first added
//and then depending on routers the redirect the user
import PropTypes from 'prop-types';

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getStatus } from '../actions/Auth';
import { Drawer, AppBar, MenuItem, Divider } from 'material-ui';
import ActionHome from 'material-ui/svg-icons/action/home';
import SocialPeople from 'material-ui/svg-icons/social/people';
import SocialSchool from 'material-ui/svg-icons/social/school';
import ImageCameraAlt from 'material-ui/svg-icons/image/camera-alt';
import EditorInsertInvitation from 'material-ui/svg-icons/editor/insert-invitation';
import ActionPayment from 'material-ui/svg-icons/action/payment';
import SocialPoll from 'material-ui/svg-icons/social/poll';
import ActionAccountBalanceWallet from 'material-ui/svg-icons/action/account-balance-wallet';
import HardwareKeyboardArrowRight from 'material-ui/svg-icons/hardware/keyboard-arrow-right';

//To customize the muiTheme I must use getMuiTheme() to compute
//a valid muiTheme. Then I can use <MuiThemeProvider /> to
//provide it down the tree to components
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { openDrawer, closeDrawer } from '../actions/Ui';
import { EditorAttachMoney } from "material-ui/svg-icons/index.es";

// This replaces the textColor value on the palette
// and then update the keys for each component that depends on it.
// More on Colors: http://www.material-ui.com/#/customization/colors
const muiTheme = getMuiTheme({
  // palette: {
  //   textColor: cyan500,
  // },
  appBar: {
    height: 54
  }
});

class Main extends React.Component {
  static propTypes = {
    getStatus: PropTypes.func,
    openDrawer: PropTypes.func,
    closeDrawer: PropTypes.func,
    children: PropTypes.node,
    user: PropTypes.object,
    drawer: PropTypes.bool
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    const { getStatus } = this.props;
    getStatus();
  }

  handleBarChange = open => {
    const { openDrawer, closeDrawer } = this.props;
    (open && openDrawer()) || closeDrawer();
  };

  goto = link => {
    const { router } = this.context;
    const { closeDrawer } = this.props;
    router.push(link);
    closeDrawer();
  };

  render() {
    const { drawer } = this.props;
    return (
      /*MuiThemeProvider takes the theme as a property and passes it
      down the hierarchy using React's context feature */
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>
          <Drawer
            docked={false}
            open={drawer}
            onRequestChange={this.handleBarChange}
          >
            <AppBar title="Menu" />
            <MenuItem
              leftIcon={<ActionHome />}
              onClick={this.goto.bind(this, '/')}
            >
              Home
            </MenuItem>
            <Divider />
            <MenuItem
              leftIcon={<SocialPeople />}
              onClick={this.goto.bind(this, '/users')}
            >
              Users
            </MenuItem>
            <MenuItem
              leftIcon={<SocialPeople />}
              onClick={this.goto.bind(this, '/business-invites')}
            >
              Business Invites
            </MenuItem>
            <MenuItem
              leftIcon={<SocialSchool />}
              onClick={this.goto.bind(this, '/allcourses')}
            >
              Courses
            </MenuItem>

            <MenuItem
              leftIcon={<EditorInsertInvitation />}
              onClick={this.goto.bind(this, '/invitations')}
            >
              Invitations
            </MenuItem>
            <MenuItem
              leftIcon={<ActionPayment />}
              onClick={this.goto.bind(this, '/payments')}
            >
              Payments
            </MenuItem>
            <MenuItem
              leftIcon={<EditorAttachMoney />}
              onClick={this.goto.bind(this, '/fortnox')}
            >
              Fortnox
            </MenuItem>
            <Divider />
            <MenuItem
              primaryText="Accounting"
              leftIcon={<ActionAccountBalanceWallet />}
              rightIcon={<HardwareKeyboardArrowRight />}
              menuItems={[
                <MenuItem
                  leftIcon={<ImageCameraAlt />}
                  primaryText="Process Receipts"
                  onClick={this.goto.bind(this, '/receipts')}
                />,
                <MenuItem
                  leftIcon={<SocialPoll />}
                  primaryText="CS stats & Sales coefficients"
                  onClick={this.goto.bind(this, '/statsandcoefficients')}
                />
              ]}
            />
            <Divider />
            <Divider />
          </Drawer>

          {/* Render the children of Main inside Main */}
          {/* See if we have any children, and if not, render home */}
          <div style={{ height: '100%', paddingTop: 64 }}>
            {this.props.children || (
              <p>
                You are {!localStorage.getItem('user') && 'not'} logged in.{' '}
              </p>
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    openDrawer: bindActionCreators(openDrawer, dispatch),
    closeDrawer: bindActionCreators(closeDrawer, dispatch),
    getStatus: bindActionCreators(getStatus, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    drawer: state.ui.drawer
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
