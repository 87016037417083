//current user action types

export const FIND_USER_BY_ID = 'FIND_BY_ID';
export const FIND_USER_BY_ID_REQUEST = 'FIND_BY_ID_REQUEST';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';

export const CREATE_DEMO_ACCOUNT = 'CREATE_DEMO_ACCOUNT';
export const CREATE_DEMO_ACCOUNT_REQUEST = 'CREATE_DEMO_ACCOUNT_REQUEST';

export const HIDE_USER = 'HIDE_USER';
export const HIDE_USER_REQUEST = 'HIDE_USER_REQUEST';

export const GET_FORTNOX = 'GET_FORTNOX';
export const SET_FORTNOX = 'SET_FORTNOX';
