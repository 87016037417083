import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, TextField, DatePicker, RaisedButton } from 'material-ui';

const styles = {
  block: {
    maxWidth: 1024,
    minWidth: 1024 / 3,
    margin: '8px auto',
    padding: 8
  }
};

class FilterPayments extends Component {
  static propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    searchTextEmail: null,
    searchTranscationId: null,
    searchTextCourseID: null,
    autoOk: false,
    controlledMinDate: null,
    controlledMaxDate: null,
    active: true
  };

  filter() {
    const {
      searchTextEmail,
      searchTextCourseID,
      searchTranscationId,
      controlledMinDate,
      controlledMaxDate
    } = this.state;
    const { onChange } = this.props;

    let min, max;

    if (controlledMinDate != null) {
      min = new Date(
        new Date(controlledMinDate).getTime() + 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10);
    } else {
      min = controlledMinDate;
    }

    if (controlledMaxDate != null) {
      max = new Date(
        new Date(controlledMaxDate).getTime() + 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10);
    } else {
      max = controlledMaxDate;
    }
    
    onChange && onChange(searchTextEmail,searchTranscationId, searchTextCourseID, min, max);
  }

  tryFilter() {
    if (this._timer) {
      clearTimeout(this._timer);
    }

    this._timer = setTimeout(() => {
      this.filter();
      this._timer = null;
    }, 500);
  }

  changeSearchText = event => {
    event.preventDefault();
    const { router } = this.context;
    const email = this.refs.email.getValue();
    const transactionId = this.refs.transcationid.getValue();
    const courseID = this.refs.course.getValue();

    this.setState(
      { searchTextEmail: email, searchTextCourseID: courseID, searchTranscationId: transactionId  },
      () => {
        this.tryFilter();
      }
    );
    router.push('/payments');
  };

  handleChangeMinDate = (event, date) => {
    this.setState(
      { controlledMinDate: date, active: date == null ? true : false },
      () => {
        this.tryFilter();
      }
    );
  };

  handleChangeMaxDate = (event, date) => {
    this.setState(
      { controlledMaxDate: date, active: date == null ? true : false },
      () => {
        this.tryFilter();
      }
    );
  };

  clearDates = () => {
    this.setState({ controlledMinDate: null, controlledMaxDate: null });

    this.handleChangeMinDate();
    this.handleChangeMaxDate();
  };

  render() {
   
    
    const {
      searchTextEmail,
      searchTextCourseID,
      searchTranscationId,
      controlledMinDate,
      controlledMaxDate
    } = this.state;

    return (
      <Paper style={styles.block}>
        <TextField
          value={searchTextEmail || ''}
          hintText="Search with at least three characters"
          onChange={this.changeSearchText}
          fullWidth
          floatingLabelText={'Search payments by email'}
          ref="email"
        />
        <TextField
          value={searchTranscationId || ''}
          hintText="Search with at least three characters"
          onChange={this.changeSearchText}
          fullWidth
          floatingLabelText={'Search payments by transcation id'}
          ref="transcationid"
        />
        <TextField
          value={searchTextCourseID || ''}
          onChange={this.changeSearchText}
          fullWidth
          floatingLabelText={'Search payments by course ID'}
          ref="course"
        />

        <div style={{ display: 'inline-block' }}>
          <DatePicker
            onChange={this.handleChangeMinDate}
            autoOk={this.state.autoOk}
            floatingLabelText="Show only after this date"
            value={controlledMinDate}
            style={{ float: 'left' }}
          />
          <DatePicker
            onChange={this.handleChangeMaxDate}
            autoOk={this.state.autoOk}
            floatingLabelText="Show only before this date"
            value={controlledMaxDate}
            style={{ float: 'left', paddingLeft: '10px' }}
          />
        </div>
        <div>
          <RaisedButton
            label="Clear Dates"
            onClick={this.clearDates.bind(this)}
            disabled={this.state.active}
          />
        </div>
      </Paper>
    );
  }
}

export default FilterPayments;
