import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateUCAs } from '../../actions/Accounting';
import Update from 'material-ui/svg-icons/action/update';

import { setEmailAsTestingAccount, getEmailAsTestingAccount } from '../../actions/TestUsers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  RaisedButton,
  Snackbar,
  TextField,
} from 'material-ui';

import EmailsList from '../../components/EmailsList';

const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    marginBottom: 24,
    marginTop: 32,
    width: 200
  },
  status: {
    marginBottom: 32
  },
  flex1: { flex: 1 },
  flex: {
    display: 'flex',
  }
};


class UcaExport extends Component {
  static propTypes = {
    generateUCAs: PropTypes.func,
    setEmailAsTestingAccount: PropTypes.func,
    getEmailAsTestingAccount: PropTypes.func,
    emailsToExclude: PropTypes.array,
  };

  state = {
    message: null,
    open: false,
    value: 'a',
    error: 'This field is mandatory',
    submit: true,
    idState: '',
    costState: '',
    percentageState: '',
    uniqueUsers: false,
    fromCircles: false,
    ucpData: false,
    uniqueUsersPermissions: false,
    status: 0,
    paymentStatus: 0,
    hidePurchases: false,
    paymentFormat: 0,
    emailsSelected: [],
  };



  handleGenerateUCAs = () => {
    const { generateUCAs } = this.props;

    const startDate = String(this.refs.UcaStartDate.getValue()) || undefined;
    const endDate = String(this.refs.UcaEndDate.getValue()) || undefined;
    const ucaUserID = String(this.refs.UcaUserID.getValue()) || undefined;

    generateUCAs({ startDate, endDate, ucaUserID }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'Data sent to your email'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  };

  onEmailSelect = (emailsSelected) => {
    this.setState({ emailsSelected: emailsSelected })
  };

  handleExcludeEmailUCAs = () => {
    const { setEmailAsTestingAccount, emailsToExclude } = this.props;
    const { emailsSelected } = this.state;
    const ucaClientID = String(this.refs.UcaClientID.getValue()) || undefined;
    const excludedEndDate = String(this.refs.excludedEndDate.getValue()) || undefined;

    let filteredEmailsList;

    // Remove emails after selection a duplicates
    filteredEmailsList = emailsToExclude && emailsToExclude.filter((value) => {
      const emailToRemove = !emailsSelected.find(element => element.email === value.email);
      const duplicateEmail = !emailsToExclude.find((element) => element.email === this.refs.UcaEmail.getValue());
      return emailToRemove && duplicateEmail
    })

    if (this.refs.UcaEmail.getValue().length) {
      const ucaEmailsToExclude = String(this.refs.UcaEmail.getValue()).replace(/\s/g, "").split(',') || undefined;
      const EmailsAsObject = ucaEmailsToExclude.map((email) => {
        return { email: email, endDate: excludedEndDate }
      })

      filteredEmailsList = [...filteredEmailsList, ...EmailsAsObject]
    }

    setEmailAsTestingAccount({ id: ucaClientID, emails: filteredEmailsList }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'Email added to exclude list'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  }

  handleGetFullEmailListUCA = () => {
    const { getEmailAsTestingAccount } = this.props;
    const ucaClientID = String(this.refs.UcaClientID.getValue()) || undefined;

    getEmailAsTestingAccount({ id: ucaClientID })
  }

  clearMessage = () => {
    this.setState({ message: null, open: false });
  };

  setUniqueUsers = () => {
    this.setState({ uniqueUsers: !this.state.uniqueUsers });
  };

  render() {
    const { message, open } = this.state;

    const { emailsToExclude } = this.props

    return (
      <>
        <h2 style={styles.headline}>Unique Connection Attempts</h2>
        <div style={styles.flex}>
          <div style={styles.flex1}>
            <h3>Request report</h3>
            <TextField
              floatingLabelText='User Id'
              ref='UcaUserID'
              type='text'
            />
            <br />
            <TextField
              floatingLabelFixed={true}
              floatingLabelText='Start date'
              ref='UcaStartDate'
              type='date'
            />
            <br />
            <TextField
              floatingLabelFixed={true}
              floatingLabelText='End date'
              ref='UcaEndDate'
              type='date'
            />
            <br />
            <p> Pressing the following button, generates UCAs </p>
            <RaisedButton
              label={'Generate UCAs for the dates'}
              onClick={this.handleGenerateUCAs}
              style={{ marginLeft: 8 }}
              backgroundColor='#FFFAAE'>
              <Snackbar
                message={message || ''}
                open={!!open}
                autoHideDuration={3000}
                onRequestClose={this.clearMessage}
              />
            </RaisedButton>
          </div>
          <div style={styles.flex1}>
            <h3>Excluded emails</h3>
            <TextField
              floatingLabelText='client Id'
              ref='UcaClientID'
              type='text'
              onBlur={this.handleGetFullEmailListUCA}
            />
            <RaisedButton
              onClick={this.handleGetFullEmailListUCA}
              style={{ marginLeft: '10px' }}
              backgroundColor='#FFFAAE'>
              <Update style={{ paddingTop: '5px' }} />
              <Snackbar
                message={message || ''}
                open={!!open}
                autoHideDuration={3000}
                onRequestClose={this.clearMessage}
              />
            </RaisedButton>
            <br />
            <div>
              <TextField
                floatingLabelText='add email(s) to list'
                ref='UcaEmail'
                type='text'
              />
              <TextField
                floatingLabelFixed={true}
                floatingLabelText='Add an end date'
                type="date"
                ref='excludedEndDate' />
            </div>
            <br />
            <p style={{ fontSize: '12px' }}>
              add email(s) to list to exclude from UCAs.
                  </p>

            <p style={{ fontSize: '12px' }}>
              to insert more than one email just separate them with ","
                  </p>

            {!!emailsToExclude.length && <>
              <p style={{ marginTop: '50px' }} >
                Emails already being excluded from UCAs
                    </p>
              <EmailsList
                onCheckItem={this.onEmailSelect}
                contacts={emailsToExclude}
              />
              <p style={{ fontSize: '12px' }}>
                Select email(s) to be removed from list
                    </p>
            </>}

            <RaisedButton
              label={'SAVE CHANGES'}
              onClick={this.handleExcludeEmailUCAs}
              style={{ marginTop: '45px' }}
              backgroundColor='#FFFAAE'>
              <Snackbar
                message={message || ''}
                open={!!open}
                autoHideDuration={3000}
                onRequestClose={this.clearMessage}
              />
            </RaisedButton>
            <p style={{ fontSize: '12px' }} >
              to see updates refresh page after saving
                  </p>
          </div>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    generateUCAs: bindActionCreators(generateUCAs, dispatch),
    setEmailAsTestingAccount: bindActionCreators(setEmailAsTestingAccount, dispatch),
    getEmailAsTestingAccount: bindActionCreators(getEmailAsTestingAccount, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    emailsToExclude: state.testUsers.emails
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UcaExport);
