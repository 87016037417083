//Here is where the list of invitations comes together with filtering options
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../Bar/Header';
import { Paper, CircularProgress } from 'material-ui';
import { getInvitations } from '../../actions/Invitations';
import FilterInvitations from '../../components/FilterInvitations';
import InvitationsList from '../../components/InvitationsList';
import { showBulkOptions, hideBulkOptions } from '../../actions/Ui';
import BulkOptions from '../../components/BulkOptions';

const styles = {
  loader: {
    position: 'fixed',
    bottom: 16,
    right: 16
  },
  list: {
    margin: 8,
    padding: 8
  }
};

class Invitations extends Component {
  static propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    invitationsPerPage: PropTypes.number,
    getInvitations: PropTypes.func,
    invitations: PropTypes.object,
    route: PropTypes.object,
    path: PropTypes.object,
    showBulkOptions: PropTypes.func,
    hideBulkOptions: PropTypes.func,
    bulkOptions: PropTypes.bool
  };

  state = {
    filters: [],
    pageStart: 0,
    pageEnd: 30,
    chosenInvitations: {},
    prevInvitations: {}
  };

  static defaultProps = {
    invitationsPerPage: 30
  };

  static contextTypes = {
    router: PropTypes.object
  };

  onChooseInvitation = invitation => {
    const { router } = this.context;
    router.push(`/invitations/${invitation.id}`);
  };

  onCheckInvitations = elements => {
    const { showBulkOptions, hideBulkOptions } = this.props;

    this.setState({ chosenInvitations: elements }, () => {
      (Object.keys(elements).length > 0 &&
        window.location.pathname === '/invitations' &&
        showBulkOptions()) ||
        hideBulkOptions();
    });
  };

  componentDidMount() {
    const { getInvitations, invitationsPerPage, hideBulkOptions } = this.props;
    const { pageStart, filters } = this.state;

    this._node = document.body;
    getInvitations(filters, pageStart, invitationsPerPage);

    hideBulkOptions();
    window.addEventListener('scroll', this.onPageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll);
  }

  onPageScroll = () => {
    const userScrollHeight = window.innerHeight + window.scrollY;
    const windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight) {
      this.loadNext();
    }
  };

  loadNext() {
    const { getInvitations, invitationsPerPage, invitations } = this.props;
    const { filters, pageStart, pageEnd, prevInvitations } = this.state;

    this.setState(
      {
        pageStart: 0,
        pageEnd: pageEnd + invitationsPerPage,
        prevInvitations: invitations,
      },
      () => {
        if(Object.keys(prevInvitations).length !== Object.keys(invitations).length)
        getInvitations(filters, pageStart, this.state.pageEnd);
      }
    );
  }

  filter() {
    const { getInvitations, invitationsPerPage } = this.props;
    const { filters } = this.state;

    getInvitations(filters, 0, invitationsPerPage);
  }

  handleFilter = searchText => {
    if (searchText !== null) {
      this.setState(
        {
          filters: [{ mode: 'email', value: searchText }],
          pageEnd: this.props.invitationsPerPage
        },
        () => {
          this.filter();
        }
      );
    } else {
      this.setState(
        {
          filters: []
        },
        () => {
          this.filter();
        }
      );
    }
  };

  render() {
    const { chosenInvitations, pageEnd } = this.state;
    const { children, loading, invitations, bulkOptions } = this.props;

    let bulk;
    bulk = (
      <div>
        <Paper style={styles.list}>
          Total Items Chosen: {Object.keys(chosenInvitations).length || 0}
          <BulkOptions chosenItems={chosenInvitations} />
        </Paper>
      </div>
    );

    return (
      <div>
        <Header title="Invitations" />

        {Object.keys(invitations).length <= pageEnd
          ? loading && (
              <div style={styles.loader}>
                <CircularProgress />
              </div>
            )
          : null}

        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            marginTop: 60,
            marginLeft: 8,
            marginRight: 8
          }}
        >
          <div style={{ flex: 1 }}>
            <FilterInvitations onChange={this.handleFilter} />
            {bulkOptions === true}{' '}
            {Object.keys(chosenInvitations).length !== 0 ? bulk : <div> </div>}
            <InvitationsList
              onChooseInvitation={this.onChooseInvitation}
              invitations={invitations}
              onCheckInvitations={this.onCheckInvitations}
            />
          </div>

          {children && (
            <Paper style={{ flex: 1, margin: 18 }}>{children}</Paper>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getInvitations: bindActionCreators(getInvitations, dispatch),
    showBulkOptions: bindActionCreators(showBulkOptions, dispatch),
    hideBulkOptions: bindActionCreators(hideBulkOptions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    invitations: state.invitations.invitations,
    loading: state.invitations.loading,
    bulkOptions: state.ui.bulkOptions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitations);
