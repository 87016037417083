import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateUser,
  inactivateUser,
  freezeUser,
  thawUser,
  findUserById,
  getFortnoxUser,
  setFortnoxUser,
  pushToFortnox,
  hideUser,
  updateUserCoursePermission,
  listUserCoursePermission
} from "../../actions/User";
import { getInvitations } from "../../actions/Invitations";
import { getPayments } from "../../actions/Payments";
import { refundPayment } from "../../actions/Payment";
import { deleteInvitation, resendInvitation } from "../../actions/Invitation";
import {
  findCoursesByUserId,
  findInactiveCoursesByUserId
} from "../../actions/Courses";
import { bindActionCreators } from "redux";
import {
  IconButton,
  AppBar,
  Snackbar,
  Toolbar,
  ToolbarTitle,
  ToolbarGroup,
  IconMenu,
  Dialog,
  FlatButton,
  Paper,
  List,
  ListItem
} from "material-ui";
import ContentBackspace from "material-ui/svg-icons/content/backspace";
import NavigationCancel from "material-ui/svg-icons/navigation/cancel";
import SocialSentimentVeryDissatisfied from "material-ui/svg-icons/social/sentiment-very-dissatisfied";
import ImageEdit from "material-ui/svg-icons/image/edit";
import UpdateUserForm from "../../components/UpdateUserForm";
import UserDetailsForm from "../../components/UserDetailsForm";
import CurrUserCoursesList from "../../components/CurrUserCoursesList";
import CurrUserPaymentsList from "../../components/CurrUserPaymentsList";
import CurrUserInvitationsList from "../../components/CurrUserInvitationsList";
import CurrUserStudentsList from "../../components/CurrUserStudentsList";
import ActionFingerprint from "material-ui/svg-icons/action/fingerprint";
import Freeze from "material-ui/svg-icons/action/visibility-off"
import UnFreeze from "material-ui/svg-icons/action/visibility"
import ActionChromeReaderMode from "material-ui/svg-icons/action/chrome-reader-mode";
import ROLE from "../../constants/Global";
import ActionRestore from "material-ui/svg-icons/action/restore";
import ActionPayment from "material-ui/svg-icons/action/payment";
import ActionFace from "material-ui/svg-icons/action/face";

const styles = {
  smallIcon: {
    width: 20,
    position: "absolute",
    top: "6px"
  },
  small: {
    width: 10,
    height: 10,
    padding: 16
  },
  inactiveList: {
    color: "gray"
  }
};

class UserDetails extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    editing: false,
    exporting: false,
    payments: false,
    message: null,
    open: false,
    openDialog: false,
    userAvatar: {
      url: null
    },
    filters: [],
    notAcceptedInvitations: [],
    student: [],
    showStudents: false,
    pageStart: 0,
    pageEnd: 30,
    coursesPerPage: 30,
    prevCourses: {},
    prevInactiveCourses: []
  };

  static propTypes = {
    children: PropTypes.node,
    currUser: PropTypes.object,
    params: PropTypes.object,
    courses: PropTypes.object,
    students: PropTypes.object,
    loading: PropTypes.bool,
    updateUser: PropTypes.func,
    inactivateUser: PropTypes.func,
    freezeUser: PropTypes.func,
    thawUser: PropTypes.func,
    hideUser: PropTypes.func,
    findUserById: PropTypes.func,
    getFortnoxUser: PropTypes.func,
    setFortnoxUser: PropTypes.func,
    getInvitations: PropTypes.func,
    getPayments: PropTypes.func,
    deleteInvitation: PropTypes.func,
    resendInvitation: PropTypes.func,
    findCoursesByUserId: PropTypes.func,
    ROLE: PropTypes.array,
    invitations: PropTypes.object,
    invitation: PropTypes.object,
    payments: PropTypes.object,
    payment: PropTypes.object,
    refundPayment: PropTypes.func
  };

  componentDidMount() {
    const {
      findUserById,
      getFortnoxUser,
      findCoursesByUserId,
      findInactiveCoursesByUserId,
      params
    } = this.props;

    findUserById(params.userId, 0, 1000);
    getFortnoxUser(params.userId)
    findInactiveCoursesByUserId(params.userId, 0, 30);
    findCoursesByUserId(params.userId, 0, 30);
    this.setState({ notAcceptedInvitations: [] });

    window.addEventListener('scroll', this.onPageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll);
  }

  onPageScroll = () => {
    if (Math.ceil(window.innerHeight + document.documentElement.scrollTop) <= (document.documentElement.offsetHeight - 1000))
      return;
    this.loadNext();
  };

  loadNext() {
    const { findCoursesByUserId, findInactiveCoursesByUserId, courses, inactiveCourses, params } = this.props;
    const { pageStart, pageEnd, coursesPerPage, prevCourses, prevInactiveCourses } = this.state;

    this.setState(
      {
        pageStart: 0,
        pageEnd: pageEnd + coursesPerPage,
        prevCourses: courses,
        prevInactiveCourses: inactiveCourses,
      },
      () => {
        if (Object.keys(prevCourses).length !== Object.keys(courses).length) {
          findCoursesByUserId(params.userId, pageStart, this.state.pageEnd);
        }
        if (prevInactiveCourses.length !== inactiveCourses.length) {
          findInactiveCoursesByUserId(params.userId, pageStart, this.state.pageEnd);
        }
      }
    );
  }

  goBack = () => {
    const { router } = this.context;
    router.push("/users");
  };

  onChooseCourse = course => {
    console.log(course.name + " tapped");
  };

  pushFortnox = (id, month) => {
    const { currUser } = this.props;
    this.props.pushToFortnox(id, month)
      .then(() => {
        this.setState({
          message: `Successfully push data to fortnox to user ${currUser.displayName}`
        });
      })
      .catch(err => {
        this.setState({
          message: err.message
        });
      })
  }

  updateFortnox = (id, { methods, ...rest }) => {
    const strings = ['webshop', 'invites'];
    const { currUser } = this.props;
    let error = null;
    (methods && !!methods.length) && methods.forEach(m => {
      if (!strings.includes(m)) {
        error = 'Methods cam only include invites or webshop or both'
      }
    });
    if (error) {
      this.setState({
        message: error
      });
      return
    }
    this.props.setFortnoxUser(id, { methods, ...rest })
      .then(() => {
        this.setState({
          message: `User ${currUser.displayName} has been updated!`
        });
      })
      .catch(err => {
        this.setState({
          message: err.message
        });
      })
  }

  updateUser = (
    userId,
    email,
    username,
    password,
    displayName,
    address,
    city,
    country,
    vatNumber,
    zip,
    roles,
    rolesText,
    paymentProviders,
    noCourseExpire
  ) => {
    const { updateUser, currUser } = this.props;

    updateUser(
      userId,
      email,
      username,
      password,
      displayName,
      address,
      city,
      country,
      vatNumber,
      zip,
      roles,
      rolesText,
      paymentProviders,
      noCourseExpire
    ).then(res => {
      if (!res.error) {
        this.setState({
          message: `User ${currUser.displayName} has been updated!`
        });
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  invitations = () => {
    const { filters } = this.state;
    const { getInvitations } = this.props;

    getInvitations(filters, 0, 100000);
  };

  showInvitations = () => {
    const { params } = this.props;

    this.setState(
      { exporting: true, filters: [{ mode: "ownerId", value: params.userId }] },
      () => {
        this.invitations();
      }
    );
  };

  deleteInvitationNow = invitation => {
    const { deleteInvitation } = this.props;

    deleteInvitation(invitation.id).then(res => {
      if (!res.error) {
        this.setState({
          message: `Invitation ${invitation.id} has been deleted!`
        });
        this.invitations();
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  resendInvitationNow = invitation => {
    const { resendInvitation } = this.props;

    resendInvitation(invitation.id).then(res => {
      if (!res.error) {
        this.setState({
          message: `Invitation ${invitation.id} has been resent!`
        });
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  resendInvitationAll = () => {
    const { invitations } = this.props;

    Object.keys(invitations).map(ID => {
      const invitation = invitations[ID];

      if (invitation.accepted === false) {
        this.setState({ notAcceptedInvitations: [...this.state.notAcceptedInvitations, invitation.id] })
      }
      return null;
    });

    resendInvitation(this.state.notAcceptedInvitations);
    this.setState({
      message: "Invitations have been sent!",
      notAcceptedInvitations: []
    });
  };

  payments = () => {
    const { filters } = this.state;
    const { getPayments } = this.props;

    getPayments(filters, null, null, 0, 1000);
  };

  showPayments = () => {
    const { params } = this.props;

    this.setState(
      {
        payments: true,
        editing: false,
        filters: [{ mode: "ownerId", value: params.userId }]
      },
      () => {
        this.payments();
      }
    );
  };

  softDeleteUser = () => {
    const { inactivateUser, params: { userId }, currUser } = this.props;

    inactivateUser(userId, !currUser.active).then(res => {
      if (!res.error) {
        this.setState({
          message: `User ${currUser.displayName} has been ${currUser.active ? "inactivated" : "activated"}!`
        });
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  temporarilyDeactivateUser = () => {
    const { freezeUser, params: { userId }, currUser } = this.props;

    freezeUser(userId).then(res => {
      if (!res.error) {
        this.setState({
          message: `User ${currUser.displayName} has been "inactivated" }!`
        });
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  reactivateUser = () => {
    const { thawUser, params: { userId }, currUser } = this.props;

    thawUser(userId).then(res => {
      if (!res.error) {
        this.setState({
          message: `User ${currUser.displayName} has been "rectivated" }!`
        });
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  refundPaymentNow = payment => {
    const { refundPayment } = this.props;

    refundPayment(payment.id).then(res => {
      if (!res.error) {
        this.setState({
          message: `Payment with ${payment.id} has been refunded!`
        });
        this.payments();
      } else {
        this.setState({ message: res.data });
      }
    });
  };

  showUserStudents = () => {
    this.setState({ showStudents: true });
  };

  closeStudents = () => {
    this.setState({ showStudents: false });
  };

  closePayments = () => {
    this.setState({ payments: false });
  };

  openEdit = () => {
    this.setState({ editing: true });
  };

  closeEdit = () => {
    this.setState({ editing: false });
  };

  closeExport = () => {
    this.setState({ exporting: false });
  };

  //this is for the Dialog box to open
  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  //this is for the dialog box to close
  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleSubmit = () => {
    this.setState({ openDialog: true });
    const { hideUser, params, currUser } = this.props;
    const { router } = this.context;

    hideUser(params.userId).then(res => {
      if (!res.error) {
        this.setState({
          message: `User ${currUser.displayName || ""} has been hidden!`
        });
        this.handleClose();
        setTimeout(() => router.push("/users"), 1000);
      }
    });
  };

  //after the message goes away the message becomes null again
  clearMessage = () => {
    this.setState({ message: null, editing: false });
  };

  render() {
    const {
      params,
      courses,
      inactiveCourses,
      currUser,
      fortnox,
      userPermissions,
      listPermission,
      updatePermission,
      invitations,
      payments
    } = this.props;

    const { message } = this.state;
    const actions = [
      <FlatButton
        label="Cancel"
        secondary={true}
        onClick={this.handleClose}
      />,
      <FlatButton
        label="Submit"
        primary={true}
        onClick={this.handleSubmit}
      />
    ];

    let userUsers =
      this.props.currUser &&
      this.props.currUser.readersCount &&
      this.props.currUser.readersCount.length;
    let readers = this.props.currUser && this.props.currUser.readersCount;

    let userItem;
    let link = `https://beta.coursio.com/incarnate/${params.userId}`;
    if (
      !this.state.editing &&
      !this.state.exporting &&
      !this.state.payments &&
      !this.state.showStudents
    ) {
      let displayName = `${currUser.displayName} - ${params.userId}`;
      userItem = (
        <div>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle
                text={currUser.active ? displayName : `${displayName} INACTIVATED`}
              />
            </ToolbarGroup>
            <ToolbarGroup>
              {/* <IconButton
                style={styles.small}
                iconStyle={styles.smallIcon}
                tooltip="Inactivate"
                tooltipPosition="top-right"
                onClick={this.softDeleteUser}
              >
                {!currUser.active ? <ActionDeleteForever /> : <ActionDelete />}
              </IconButton> */}
              {currUser.active ? <IconButton
                style={styles.small}
                iconStyle={styles.smallIcon}
                tooltip="Temporarily freeze user"
                tooltipPosition="top-right"
                onClick={this.temporarilyDeactivateUser}
              >
                <Freeze />
              </IconButton> : <IconButton
                style={styles.small}
                iconStyle={styles.smallIcon}
                tooltip="Unfreeze user"
                tooltipPosition="top-right"
                onClick={this.reactivateUser}
              >
                <UnFreeze />
              </IconButton>}
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Incarnate"
                    tooltipPosition="top-right"
                    href={link}
                    target="_tab"
                  >
                    <ActionFingerprint />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Show User Invitations"
                    tooltipPosition="top-right"
                    onClick={this.showInvitations}
                  >
                    <ActionChromeReaderMode />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Show User Payments"
                    tooltipPosition="top-right"
                    onClick={this.showPayments}
                  >
                    <ActionPayment />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Hide"
                    tooltipPosition="top-right"
                    onClick={this.handleOpen}
                  >
                    <SocialSentimentVeryDissatisfied />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Edit"
                    tooltipPosition="top-right"
                    onClick={this.openEdit}
                  >
                    <ImageEdit />
                  </IconButton>
                }
              />
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Close"
                    tooltipPosition="top-right"
                    onClick={this.goBack}
                  >
                    <NavigationCancel />
                  </IconButton>
                }
              />
            </ToolbarGroup>

            <Dialog
              title="Hide User"
              actions={actions}
              modal={false}
              open={this.state.openDialog}
              onRequestClose={this.handleClose}
            >
              Are you sure you want to hide the user?
            </Dialog>

            <Snackbar
              message={message || ""}
              open={!!message}
              autoHideDuration={1500}
              onRequestClose={this.clearMessage}
            />
          </Toolbar>

          <UserDetailsForm currUser={currUser} fortnox={fortnox} params={params} />
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle
                text={
                  "Courses (" +
                  Object.keys(courses).length +
                  ") - Students in total: " +
                  userUsers
                }
              />
            </ToolbarGroup>
            <ToolbarGroup>
              <IconMenu
                iconButtonElement={
                  <IconButton
                    style={styles.small}
                    iconStyle={styles.smallIcon}
                    tooltip="Show User Students"
                    tooltipPosition="top-left"
                    onClick={this.showUserStudents}
                  >
                    <ActionFace />
                  </IconButton>
                }
              />
            </ToolbarGroup>
          </Toolbar>
          <CurrUserCoursesList
            userPermissions={userPermissions}
            listPermission={listPermission}
            updatePermission={updatePermission}
            courses={courses}
            userId={currUser.id}
            onChooseCourse={this.onChooseCourse}
            params={params}
          />
          {inactiveCourses && (
            <span>
              <Toolbar>
                <ToolbarGroup>
                  <ToolbarTitle
                    text={
                      "Inactive Courses (" +
                      Object.keys(inactiveCourses).length +
                      ")"
                    }
                  />
                </ToolbarGroup>
              </Toolbar>
              <Paper>
                <List>
                  {inactiveCourses.map((item, index) => {
                    return (
                      <ListItem style={styles.inactiveList} key={index}>
                        {item.name} removed: {item.ucp.modified} {item.ucp.deleted && '(soft deleted)'}
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </span>
          )}
        </div>
      );
    } else if (this.state.showStudents) {
      userItem = (
        <div>
          <AppBar
            title={"Total Students: " + userUsers}
            iconElementRight={
              <IconButton
                tooltip="Close"
                tooltipPosition="top-right"
                onClick={this.closeStudents}
              >
                <ContentBackspace />
              </IconButton>
            }
          />
          <CurrUserStudentsList readers={readers} params={params} />
        </div>
      );
    } else if (this.state.exporting) {
      userItem = (
        <div>
          <AppBar
            title={"Total Invitations: " + Object.keys(invitations).length}
            iconElementRight={
              <IconButton
                tooltip="Close"
                tooltipPosition="top-right"
                onClick={this.closeExport}
              >
                <ContentBackspace />
              </IconButton>
            }
            iconElementLeft={
              Object.keys(invitations).length === 0 ? null : (
                <IconButton
                  tooltip="Resend Invitations to All Those not Accepted Yet"
                  tooltipPosition="top-left"
                  onClick={this.resendInvitationAll}
                >
                  <ActionRestore />
                </IconButton>
              )
            }
          />

          <CurrUserInvitationsList
            invitations={invitations}
            onResendInvitationNow={this.resendInvitationNow}
            onDeleteInvitationNow={this.deleteInvitationNow}
            params={params}
          />

          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    } else if (this.state.payments) {
      userItem = (
        <div>
          <AppBar
            title={"Total payments: " + Object.keys(payments).length}
            iconElementRight={
              <IconButton
                tooltip="Close"
                tooltipPosition="top-right"
                onClick={this.closePayments}
              >
                <ContentBackspace />
              </IconButton>
            }
          />

          <CurrUserPaymentsList
            payments={payments}
            onRefundPaymentNow={this.refundPaymentNow}
            params={params}
          />

          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    } else {
      userItem = (
        <div>
          <AppBar
            title={"Editing " + currUser.displayName}
            iconElementLeft={
              <IconButton
                tooltip="Cancel"
                tooltipPosition="top-right"
                onClick={this.closeEdit}
              >
                <ContentBackspace />
              </IconButton>
            }
          />
          <UpdateUserForm
            onUpdate={this.updateUser}
            params={params}
            role={ROLE}
            currUser={currUser}
            fortnox={fortnox}
            updateFortnox={this.updateFortnox}
            pushFortnox={this.pushFortnox}
          />
          <Snackbar
            message={message || ""}
            open={!!message}
            autoHideDuration={1500}
            onRequestClose={this.clearMessage}
          />
        </div>
      );
    }

    return <div>{userItem}</div>;
  }
}

function mapStateToProps(state) {
  return {
    currUser: state.currUser.currUser,
    fortnox: state.currUser.fortnox,
    userPermissions: state.currUser.permissions,
    currInvitation: state.currInvitation.currInvitation,
    courses: state.courses.courses,
    inactiveCourses: state.courses.inactiveCourses,
    invitations: state.invitations.invitations,
    payments: state.payments.payments,
    payment: state.currPayment.currPayment,
    loading: state.courses.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    listPermission: bindActionCreators(listUserCoursePermission, dispatch),
    updatePermission: bindActionCreators(updateUserCoursePermission, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch),
    inactivateUser: bindActionCreators(inactivateUser, dispatch),
    freezeUser: bindActionCreators(freezeUser, dispatch),
    thawUser: bindActionCreators(thawUser, dispatch),
    hideUser: bindActionCreators(hideUser, dispatch),
    findUserById: bindActionCreators(findUserById, dispatch),
    getFortnoxUser: bindActionCreators(getFortnoxUser, dispatch),
    setFortnoxUser: bindActionCreators(setFortnoxUser, dispatch),
    pushToFortnox: bindActionCreators(pushToFortnox, dispatch),
    getInvitations: bindActionCreators(getInvitations, dispatch),
    getPayments: bindActionCreators(getPayments, dispatch),
    refundPayment: bindActionCreators(refundPayment, dispatch),
    deleteInvitation: bindActionCreators(deleteInvitation, dispatch),
    resendInvitation: bindActionCreators(resendInvitation, dispatch),
    findCoursesByUserId: bindActionCreators(findCoursesByUserId, dispatch),
    findInactiveCoursesByUserId: bindActionCreators(
      findInactiveCoursesByUserId,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);
