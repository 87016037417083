//Actions for a current course
export const FIND_COURSE_BY_ID = 'FIND_COURSE_BY_ID';
export const FIND_COURSE_BY_ID_REQUEST = 'FIND_COURSE_BY_ID_REQUEST';

export const DUPLICATE_COURSE = 'DUPLICATE_COURSE';
export const DUPLICATE_COURSE_REQUEST = 'DUPLICATE_COURSE_REQUEST';

export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';

export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST';

export const CHANGE_OWNER_COURSE = 'CHANGE_OWNER_COURSE';

export const FIND_INACTIVE_COURSES_BY_USER_ID = 'FIND_INACTIVE_COURSES_BY_USER_ID';