//all users' courses from mr.wolf-course
export const FETCH_COURSES = 'FETCH_COURSES';
export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';

export const FIND_COURSES_BY_USER_ID = 'FIND_COURSES_BY_USER_ID';
export const FIND_COURSES_BY_USER_ID_REQUEST =
  'FIND_COURSES_BY_USER_ID_REQUEST';

export const FIND_INACTIVE_COURSES_BY_USER_ID = 'FIND_INACTIVE_COURSES_BY_USER_ID';

