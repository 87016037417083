import SteelCompass from '../services/SteelCompass';
import {
  FIND_PAYMENT_BY_ID,
  REFUND_PAYMENT
} from '../constants/CurrPaymentActionTypes';

//this function brings a payment by ID
export function findPaymentById(id) {
  return {
    type: FIND_PAYMENT_BY_ID,
    id,
    promise: SteelCompass.request('mr.wolf-payment', 'findById', { id })
  };
}

//This function refunds a payment based on payment ID
export function refundPayment(id) {
  return {
    type: REFUND_PAYMENT,
    id,
    promise: SteelCompass.request('mr.wolf-payment', 'refund', { id })
  };
}
