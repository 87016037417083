import PropTypes from 'prop-types';

import React from 'react';
import { fortnoxCompleteAuth } from '../../actions/Vendor';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from "../Bar/Header";

class FortnoxFinalize extends React.Component {
  static propTypes = {
    fortnoxCompleteAuth: PropTypes.func,
  };

  state = {
  };

  componentDidMount() {
    const { fortnoxCompleteAuth, location } = this.props;
    fortnoxCompleteAuth(location.query.code, location.query.state)
  }

  render() {
    return <div style={{marginBottom: 10}}>
      <Header title="Fortnox Finalize" />
      <div style={{ marginBottom: 10 }}>
        <p style={{ fontSize: 23 }}>You are now connected to Fortnox</p>
      </div>
    </div>
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fortnoxCompleteAuth: bindActionCreators(fortnoxCompleteAuth, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    fortnoxUrl: state.url
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FortnoxFinalize);
