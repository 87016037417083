//this is a delete component for deleting a course
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { RaisedButton, FlatButton, Dialog } from 'material-ui';
import ActionDelete from 'material-ui/svg-icons/action/delete';

const style = {
  margin: 12
};

class DeleteCourseForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    onDelete: PropTypes.func,
    params: PropTypes.object,
    course: PropTypes.object
  };

  //state for the dialog box
  state = {
    open: false
  };

  //this is for the Dialog box to open
  handleOpen = () => {
    this.setState({ open: true });
  };

  //this is for the dialog box to close
  handleClose = () => {
    this.setState({ open: false });
  };

  //handleSubmit for the Delete button, set the dialog box to open when delete button is clicked
  handleSubmit = event => {
    this.setState({ open: true });
    const { onDelete, params } = this.props;
    event.preventDefault();
    if (onDelete) {
      this.setState({ open: false });
      onDelete(params.courseId);
    }
  };

  render() {
    const { params } = this.props;
    const actions = [
      <FlatButton
        label="Cancel"
        secondary={true}
        onClick={this.handleClose}
      />,
      <FlatButton
        label="Submit"
        primary={true}
        keyboardFocused={true}
        onClick={this.handleSubmit}
      />
    ];

    return (
      <div>
        <RaisedButton
          label="Delete Course"
          labelPosition="after"
          icon={<ActionDelete />}
          onClick={this.handleOpen}
          secondary={true}
          style={style}
        />
        <Dialog
          title="Delete Course"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          Are you sure you want to delete the course with course id{' '}
          {params.courseId}?
        </Dialog>
      </div>
    );
  }
}

export default DeleteCourseForm;
