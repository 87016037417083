import SteelCompass from '../services/SteelCompass';
import {
  SCAN_RECEIPT,
  GENERATE_CS_STATS,
  CUSTOMER_SALES_COEFFICIENTS,
  GENERATE_INVITATIONS_LIST,
  GENERATE_PAYMENTS_LIST,
  GENERATE_PERMISSIONS_LIST
} from '../constants/AccountingActionTypes';

export function scanReceipt(image, description, peForward) {
  return {
    type: SCAN_RECEIPT,
    peForward,
    promise: SteelCompass.request('mr.wolf-accounting', 'processReceiptScan', {
      image,
      description,
      peForward
    })
  };
}

//generate cs stats for current month
export function generateCSStats({ startDate, endDate }) {
  return {
    type: GENERATE_CS_STATS,
    promise: SteelCompass.request(
      'mr.wolf-accounting',
      'customerSuccessMonth',
      { startDate, endDate }
    )
  };
}

//generate UCAs
export function generateUCAs({ startDate, endDate, ucaUserID }) {
  return {
    type: GENERATE_CS_STATS,
    promise: SteelCompass.request(
      'statistics',
      'requestExport',
      { exportId: 8, userId: ucaUserID, params: { startDate, endDate } }
    )
  };
}

//get or set customer sales coefficients
export function salesCoefficients({ userId, invitationCost, salesPercentage }) {
  return {
    type: CUSTOMER_SALES_COEFFICIENTS,
    promise: SteelCompass.request('mr.wolf-accounting', 'salesCoefficients', {
      userId,
      invitationCost,
      salesPercentage
    })
  };
}

//generate invitation list
export function generateInvitationsList({
  ownerId,
  startDate,
  endDate,
  status,
  uniqueUsers,
  addUcpData,
  fromCircles
}) {
  return {
    type: GENERATE_INVITATIONS_LIST,
    promise: SteelCompass.request('mr.wolf-accounting', 'getInvitationsList', {
      ownerId,
      startDate,
      endDate,
      status,
      uniqueUsers,
      addUcpData,
      fromCircles
    })
  };
}

//generate payments list
export function generatePaymentsList({ storeId, startDate, endDate, status, format }) {
  return {
    type: GENERATE_PAYMENTS_LIST,
    promise: SteelCompass.request('mr.wolf-accounting', 'getPaymentsList', {
      storeId,
      startDate,
      endDate,
      status,
      format
    })
  };
}

//generate active clients list
export function generateActiveClientsList() {
  return {
    type: GENERATE_PAYMENTS_LIST,
    promise: SteelCompass.request('mr.wolf-accounting', 'getActiveClients', {})
  };
}

//generate permissions list
export function generatePermissionsList({
  ownerId,
  startDate,
  endDate,
  uniqueUsers,
  hidePurchases
}) {
  return {
    type: GENERATE_PERMISSIONS_LIST,
    promise: SteelCompass.request('mr.wolf-accounting', 'getPermissionsList', {
      ownerId,
      startDate,
      endDate,
      uniqueUsers,
      hidePurchases
    })
  };
}
