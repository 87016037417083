export const FIND_INVITATION_BY_ID = 'FIND_INVITATION_BY_ID';
export const FIND_INVITATION_BY_ID_REQUEST = 'FIND_INVITATION_BY_ID_REQUEST';

export const DELETE_INVITATION = 'DELETE_INVITATION';
export const DELETE_INVITATION_REQUEST = 'DELETE_INVITATION_REQUEST';

export const RESEND_INVITATION = 'RESEND_INVITATION';
export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST';

export const GENERATE_GIFT = 'GENERATE_GIFT';
