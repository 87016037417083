import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateActiveClientsList } from '../../actions/Accounting';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RaisedButton, Snackbar } from 'material-ui';

const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    marginBottom: 24,
    marginTop: 32,
    width: 200
  },
  status: {
    marginBottom: 32
  },
  flex1: { flex: 1 },
  flex: {
    display: 'flex',
  }
};

class ActiveClients extends Component {
  static propTypes = {
    generateActiveClientsList: PropTypes.func,
  };

  state = {
    message: null,
    open: false,
    value: 'a',
    error: 'This field is mandatory',
    submit: true,
    idState: '',
    costState: '',
    percentageState: '',
    uniqueUsers: false,
    fromCircles: false,
    ucpData: false,
    uniqueUsersPermissions: false,
    status: 0,
    paymentStatus: 0,
    hidePurchases: false,
    paymentFormat: 0,
    emailsSelected: [],
  };

  clearMessage = () => {
    this.setState({ message: null, open: false });
  };

  handleChange = value => {
    this.setState({
      value: value
    });
  };

  handleGenerateActiveClientslist = () => {
    const { generateActiveClientsList } = this.props;
    generateActiveClientsList().then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'Data sent to your email'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  };

  render() {
    const { message, open } = this.state;

    return (
      <div>
        <h2 style={styles.headline}>Active clients</h2>
        <p> Below you can get a list of active clients. The report will be emailed to you</p>
        <RaisedButton
          label='Generate'
          onClick={this.handleGenerateActiveClientslist}>
          <Snackbar
            message={message || ''}
            open={!!open}
            autoHideDuration={3000}
            onRequestClose={this.clearMessage}
          />
        </RaisedButton>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    generateActiveClientsList: bindActionCreators(generateActiveClientsList, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveClients);
