//this is the reducer for authenticating the user
import {
  LOGIN_USER,
  LOGOUT_USER,
  CHECK_USER_STATUS
} from '../constants/AuthActionTypes';

const initialState = JSON.parse(localStorage.getItem('user')) || {};

export default function userReducer(state = initialState, action) {
  let user;
  switch (action.type) {
    case LOGIN_USER:
      user = action.res.user;
      localStorage.setItem('user', JSON.stringify(user));
      return {
        ...state,
        ...user
      };
    case LOGOUT_USER:
      localStorage.removeItem('user');
      return {};
    case CHECK_USER_STATUS:
      user = action.res.user;
      if (!user || !user.id) {
        localStorage.removeItem('user');
      }
      return {
        ...state,
        ...user
      };
    default:
      return state;
  }
}
