import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ActionFace from 'material-ui/svg-icons/action/face';
import ActionSchedule from 'material-ui/svg-icons/action/schedule';
import ActionPayment from 'material-ui/svg-icons/action/payment';
import ActionAccountCircle from 'material-ui/svg-icons/action/account-circle';
import ImagePalette from 'material-ui/svg-icons/image/palette';
import SocialPerson from 'material-ui/svg-icons/social/person';
import CommunicationEmail from 'material-ui/svg-icons/communication/email';
import { Avatar, List, ListItem, Divider } from 'material-ui';

class UserDetailsForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    currUser: PropTypes.object,
    params: PropTypes.object,
    findUserById: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const { currUser, params, fortnox } = this.props;
    const { address, city, vatNumber, country, zip } = currUser.hasOwnProperty('company') && currUser.company;
    const isOwner = currUser.hasOwnProperty('roles') && currUser.roles.text.includes('owner')

    let rolesText = '';
    if (typeof currUser.roles != 'undefined') {
      rolesText = currUser.roles.text.join(', ');
    }

    let userAvatar = '';
    if (typeof currUser.avatar != 'undefined') {
      userAvatar = currUser.avatar.url;
    }

    let paymentInfo = '';
    if (typeof currUser.payment != 'undefined') {
      if (currUser.payment.providers.klarna) {
        paymentInfo = 'Klarna';
      } else if (currUser.payment.providers.stripe) {
        paymentInfo = 'Stripe';
      } else if (
        currUser.payment.providers.klarna &&
        currUser.payment.providers.stripe
      ) {
        paymentInfo = 'Klarna, Stripe';
      } else {
        paymentInfo = '-';
      }
    }

    return (
      <div>
        <List>
          <ListItem
            disabled={true}
            leftAvatar={<Avatar src={currUser.avatarUrl || userAvatar} />}
            primaryText={currUser.displayName}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<SocialPerson />}
            primaryText={'Username'}
            secondaryText={currUser.username}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText={'Id'}
            secondaryText={params.userId}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<CommunicationEmail />}
            primaryText={'Email'}
            secondaryText={currUser.email}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionFace />}
            primaryText={'Roles'}
            secondaryText={rolesText}
          />
          {
            isOwner &&
                <>
                  <Divider inset={true} />
                  <ListItem
                      disabled={true}
                      leftIcon={<div />}
                      primaryText={'Address'}
                      secondaryText={address}
                  />
                  <Divider inset={true} />
                  <ListItem
                      disabled={true}
                      leftIcon={<div />}
                      primaryText={'City'}
                      secondaryText={city}
                  />
                  <Divider inset={true} />
                  <ListItem
                      disabled={true}
                      leftIcon={<div />}
                      primaryText={'Country'}
                      secondaryText={country}
                  />
                  <Divider inset={true} />
                  <ListItem
                      disabled={true}
                      leftIcon={<div />}
                      primaryText={'VAT Number'}
                      secondaryText={vatNumber}
                  />
                  <Divider inset={true} />
                  <ListItem
                      disabled={true}
                      leftIcon={<div />}
                      primaryText={'Zip'}
                      secondaryText={zip}
                  />
                </>
          }
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionPayment />}
            primaryText={'Providers'}
            secondaryText={paymentInfo}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionSchedule />}
            primaryText={'Last Login'}
            secondaryText={currUser.lastLogin}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ImagePalette />}
            primaryText={'Created'}
            secondaryText={currUser.created}
          />
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionPayment />}
            primaryText={'Fortnox'}
            secondaryText={fortnox &&
              <p>
                <span>Supplier: {fortnox.supplierId}</span>
                <span>, Costumer: {fortnox.customerId}</span>
                <span>, Methods: {fortnox.methods && fortnox.methods.join(', ')}</span>
              </p>
            }
          />
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionSchedule />}
            primaryText={'Course Expiration'}
            secondaryText={currUser.noCourseExpire ? 'Permitted' : 'Forbidden'}
          />
          <Divider />
          <br />
        </List>
      </div>
    );
  }
}

export default UserDetailsForm;
