import SteelCompass from '../services/SteelCompass';
import { GET_EMAILS_AS_TEST_ACCOUNTS } from '../constants/TestUsersActionTypes';

export function setEmailAsTestingAccount({ id, emails }) {
  return {
    type: null,
    id,
    emails,
    promise: SteelCompass.request(
      'mr.wolf-user',
      'setTestEmails',
      { id, emails }
    )
  };
}

export function getEmailAsTestingAccount({ id, pageStart = 0, pageLength = 20 }) {
  return {
    type: GET_EMAILS_AS_TEST_ACCOUNTS,
    id,
    promise: SteelCompass.request(
      'mr.wolf-user',
      'getTestEmails',
      { id },
      pageStart,
      pageLength
    )
  };
}
