import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findPaymentById, refundPayment } from '../../actions/Payment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  IconButton,
  IconMenu,
  List,
  ListItem,
  Divider,
  Snackbar
} from 'material-ui';
import NavigationCancel from 'material-ui/svg-icons/navigation/cancel';
import ActionLabel from 'material-ui/svg-icons/action/label';
import ActionLabelOutline from 'material-ui/svg-icons/action/label-outline';
import ActionTrendingDown from 'material-ui/svg-icons/action/trending-down';
import ActionRestore from 'material-ui/svg-icons/action/restore';
import ActionCode from 'material-ui/svg-icons/action/code';
import SocialPeople from 'material-ui/svg-icons/social/people';
import SocialPoll from 'material-ui/svg-icons/social/poll';
import ActionPermIdentity from 'material-ui/svg-icons/action/perm-identity';
import ActionAccountBox from 'material-ui/svg-icons/action/account-box';
import ActionAccountBalanceWallet from 'material-ui/svg-icons/action/account-balance-wallet';
import ActionAccountCircle from 'material-ui/svg-icons/action/account-circle';
import ActionSchedule from 'material-ui/svg-icons/action/schedule';
import CommunicationEmail from 'material-ui/svg-icons/communication/email';

const styles = {
  smallIcon: {
    width: 23,
    height: 23
  },
  small: {
    width: 10,
    height: 10,
    padding: 16
  }
};

const paymentStatus = {
  1: "NEW",
  2: "CART_OPENED",
  4: "REGISTERED",
  8: "PAID",
  16: "GOOD",
  32: "ERROR",
  64: "USER_CANCELED",
  128: "REFUND"
}

class PaymentsDetails extends Component {
  static propTypes = {
    children: PropTypes.node,
    params: PropTypes.object,
    findPaymentById: PropTypes.func,
    refundPayment: PropTypes.func,
    currPayment: PropTypes.object
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    message: null
  };

  componentDidMount() {
    const { params, findPaymentById } = this.props;

    findPaymentById(params.paymentId);
  }

  goBack = () => {
    const { router } = this.context;
    router.push('/payments');
  };

  goRefund = () => {
    const { params, refundPayment } = this.props;

    refundPayment(params.paymentId).then(res => {
      if (!res.error) {
        this.setState({
          message: `Payment with id: ${params.paymentId} has been refunded!`
        });
      } else {
        this.setState({ message: 'Error ' + res.data });
      }
    });
  };

  //after the message goes away the message becomes null again
  clearMessage = () => {
    this.setState({ message: null });
  };

  render() {
    const { params, currPayment } = this.props;
    const { message } = this.state;

    let paymentItem;

    paymentItem = (
      <div>
        <Toolbar>
          <ToolbarGroup key={0}>
            <ToolbarTitle text={'Payment id: ' + params.paymentId} />
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <IconMenu
              iconButtonElement={
                <IconButton
                  style={styles.small}
                  iconStyle={styles.smallIcon}
                  tooltip="Refund"
                  tooltipPosition="top-right"
                  onClick={this.goRefund}
                >
                  <ActionRestore />
                </IconButton>
              }
            />
            <IconMenu
              iconButtonElement={
                <IconButton
                  style={styles.small}
                  iconStyle={styles.smallIcon}
                  tooltip="Close"
                  tooltipPosition="top-right"
                  onClick={this.goBack}
                >
                  <NavigationCancel />
                </IconButton>
              }
            />
          </ToolbarGroup>
        </Toolbar>

        <List>
          <ListItem
            disabled={true}
            leftIcon={<ActionPermIdentity />}
            primaryText="Store Id"
            secondaryText={currPayment.storeId}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Transaction Id"
            secondaryText={currPayment.transactionId}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Status"
            secondaryText={paymentStatus[currPayment.status]}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountBox />}
            primaryText="Store Name"
            secondaryText={currPayment.storeName}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<CommunicationEmail />}
            primaryText="Email"
            secondaryText={currPayment.email}
          />
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Provider"
            secondaryText={currPayment.provider}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountBalanceWallet />}
            primaryText="Amount/Cost"
            secondaryText={currPayment.amount + ' ' + currPayment.currency}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionSchedule />}
            primaryText="Created"
            secondaryText={currPayment.created}
          />
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionCode />}
            primaryText="Gift Code"
            secondaryText={
              (currPayment.gift && currPayment.gift.code) || 'None'
            }
          />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabel />}
            primaryText="Gift Count"
            secondaryText={(currPayment.gift && currPayment.gift.count) || '0'}
          />
          <ListItem
            disabled={true}
            leftIcon={<SocialPeople />}
            primaryText="Acceptors"
            secondaryText={
              (currPayment.gift && currPayment.gift.acceptors && currPayment.gift.acceptors[0] && currPayment.gift.acceptors[0].email) ||
              'None'
            }
          />
          <Divider inset={true} />
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabelOutline />}
            primaryText="Course Name"
            secondaryText={
              currPayment.receipt &&
              currPayment.receipt.cart[0] &&
              currPayment.receipt.cart[0].name
            }
          />
          <ListItem
            disabled={true}
            leftIcon={<SocialPoll />}
            primaryText="Amount"
            secondaryText={
              currPayment.receipt &&
              currPayment.receipt.cart[0] &&
              currPayment.receipt.cart[0].amount
            }
          />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabel />}
            primaryText="Article"
            secondaryText={
              currPayment.receipt &&
              currPayment.receipt.cart[0] &&
              currPayment.receipt.cart[0].article
            }
          />
          <ListItem
            disabled={true}
            leftIcon={<ActionTrendingDown />}
            primaryText="Discount"
            secondaryText={
              (currPayment.receipt &&
                currPayment.receipt.cart[0] &&
                currPayment.receipt.cart[0].discount) ||
              '0'
            }
          />
          <Divider />
        </List>

        <Snackbar
          message={message || ''}
          open={!!message}
          autoHideDuration={1500}
          onRequestClose={this.clearMessage}
        />
      </div>
    );
    return <div>{paymentItem}</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    findPaymentById: bindActionCreators(findPaymentById, dispatch),
    refundPayment: bindActionCreators(refundPayment, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    currPayment: state.currPayment.currPayment
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsDetails);
