//Here is where the list of coursio users comes together with filtering options
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUsers } from '../../actions/Users';
import { showBulkOptions, hideBulkOptions } from '../../actions/Ui';
import {
  Paper,
  CircularProgress,
  IconButton,
  Drawer,
  AppBar
} from 'material-ui';
import UsersList from '../../components/UsersList';
import FilterUsers from '../../components/FilterUsers';
import BulkOptions from '../../components/BulkOptions';
import ROLE from '../../constants/Global';
import ContentClear from 'material-ui/svg-icons/content/clear';
import Header from '../Bar/Header';

const styles = {
  loader: {
    position: 'fixed',
    bottom: 16,
    right: 16
  },
  helper: {
    position: 'absolute',
    top: 60,
    right: 0
  },
  list: {
    margin: 8,
    padding: 8
  }
};

class Users extends Component {
  static propTypes = {
    children: PropTypes.node,
    route: PropTypes.object,
    path: PropTypes.object,
    users: PropTypes.object,
    getUsers: PropTypes.func,
    showBulkOptions: PropTypes.func,
    hideBulkOptions: PropTypes.func,
    findUserById: PropTypes.func,
    onPageScroll: PropTypes.func,
    usersPerPage: PropTypes.number,
    bulkOptions: PropTypes.bool,
    loading: PropTypes.bool,
    ROLE: PropTypes.array
  };

  static defaultProps = {
    usersPerPage: 30
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    pageStart: 0,
    pageEnd: 30,
    filters: [],
    open: false,
    chosenUsers: {}
  };

  componentDidMount() {
    const { getUsers, usersPerPage, hideBulkOptions } = this.props;
    const { pageStart, filters } = this.state;
    this._node = document.body;
    getUsers(filters, pageStart, usersPerPage);
    hideBulkOptions();
    window.addEventListener('scroll', this.onPageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll);
  }

  onChooseUser = user => {
    const { router } = this.context;
    router.push(`/users/${user.id}`);
  };

  onCheckUsers = elements => {
    const { showBulkOptions, hideBulkOptions } = this.props;

    this.setState({ chosenUsers: elements }, () => {
      (Object.keys(elements).length > 0 &&
        window.location.pathname === '/users' &&
        showBulkOptions()) ||
        hideBulkOptions();
    });
  };

  onPageScroll = () => {
    const userScrollHeight = window.innerHeight + window.scrollY;
    const windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight) {
      this.loadNext();
    }
  };

  loadNext() {
    const { getUsers, usersPerPage } = this.props;
    const { filters, pageStart, pageEnd } = this.state;

    this.setState(
      {
        pageStart: 0,
        pageEnd: pageEnd + usersPerPage,
      },
      () => {
        getUsers(filters, pageStart, this.state.pageEnd);
      }
    );
  }

  filter() {
    const { getUsers, usersPerPage } = this.props;
    const { filters } = this.state;

    getUsers(filters, 0, usersPerPage);
  }

  handleFilter = (roleMask, searchText) => {

    let searchMode = { mode: (Number.isInteger(Number(searchText)) && 'userId') || 'email', value: searchText }

    this.setState(
      {
        filters: [
          { mode: 'role', value: roleMask },
          searchMode
        ],
        pageEnd: this.props.usersPerPage
      },
      () => {
        this.filter();
      }
    );
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { chosenUsers, pageEnd } = this.state;
    const { users, loading, children, bulkOptions } = this.props;

    let bulk;
    bulk = (
      <div>
        <Paper style={styles.list}>
          Total Items Chosen: {Object.keys(chosenUsers).length || 0}
          <BulkOptions chosenItems={chosenUsers} />
        </Paper>
      </div>
    );

    return (
      <div>
        <Header title="Users" />

        {Object.keys(users).length <= pageEnd
          ? loading && (
              <div style={styles.loader}>
                <CircularProgress />
              </div>
            )
          : null}

        <Drawer openSecondary={true} open={this.state.open}>
          <AppBar
            title="Close"
            onClick={this.handleToggle}
            iconElementLeft={
              <IconButton>
                <ContentClear />
              </IconButton>
            }
          />
        </Drawer>

        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            marginTop: 60,
            marginLeft: 8,
            marginRight: 8
          }}
        >
          <div style={{ flex: 1 }}>
            <FilterUsers role={ROLE.reader} onChange={this.handleFilter} />
            {bulkOptions === true}{' '}
            {Object.keys(chosenUsers).length !== 0 ? bulk : <div> </div>}
            <UsersList
              onChooseUser={this.onChooseUser}
              users={users}
              onCheckUsers={this.onCheckUsers}
            />
          </div>

          {children && (
            <Paper style={{ flex: 1, margin: 18 }}>{children}</Paper>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getUsers: bindActionCreators(getUsers, dispatch),
    showBulkOptions: bindActionCreators(showBulkOptions, dispatch),
    hideBulkOptions: bindActionCreators(hideBulkOptions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    loading: state.users.loading,
    bulkOptions: state.ui.bulkOptions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
