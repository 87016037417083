import SteelCompass from '../services/SteelCompass';
import {
  SEND_BUSINESS_INVITE,
  GET_BUSINESS_INVITE,
  FINALIZE_BUSINESS_INVITE,
  CREATE_BUSINESS_USER,
  DELETE_INVITE_ITEM
} from '../constants/UserManagementActionTypes';

export function sendInvite({ name, email, locale, months, fee_pc, fee_inv }) {
  return {
    type: SEND_BUSINESS_INVITE,
    promise: SteelCompass.request(
      'business-invite',
      'send',
      { name, email, locale, params: { months, fee_pc, fee_inv } },
    )
  };
}

export function createUser({ code, password }) {
  return {
    type: CREATE_BUSINESS_USER,
    promise: SteelCompass.request(
      'business-invite',
      'createUser',
      { code, password, },
    )
  };
}

export function getInviteList(pageStart = 0, pageLength = 20) {
  return {
    type: GET_BUSINESS_INVITE,
    promise: SteelCompass.request(
      'business-invite',
      'list',
      {
        sortBy: 'modified',
        sortMode: 'desc'
      },
      pageStart,
      pageLength
    )
  };
}

export function finalize({ id }) {
  return {
    type: FINALIZE_BUSINESS_INVITE,
    promise: SteelCompass.request(
      'business-invite',
      'finalize',
      { id },
    )
  };
}

export function deleteInviteItem({ id }) {
  return {
    type: DELETE_INVITE_ITEM,
    promise: SteelCompass.request(
      'business-invite',
      'delete',
      { id },
    )
  };
}
