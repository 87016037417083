import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  RaisedButton,
  Snackbar,
  Toggle,
  TextField,
} from 'material-ui';
import ResellerCutsList from '../../components/ResellerCutsList';
import { setResellerCut, getAllCutsForCourse, getCutOfUserForCourse, getAllCutsOfUser, deleteCut } from '../../actions/CourseBudget';

const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    width: 140
  },
  status: {
    marginBottom: 32
  },
  flex1: { flex: 1 },
  flex: {
    display: 'flex',
  }
};


class ResellerCuts extends Component {
  static propTypes = {
    listedCuts: PropTypes.array,
    setResellerCut: PropTypes.func,
    getAllCutsForCourse: PropTypes.func,
    getAllCutsOfUser: PropTypes.func,
    getCutOfUserForCourse: PropTypes.func,
    deleteCut: PropTypes.func,

  };

  state = {
    message: null,
    open: false,
    error: 'This field is mandatory',
    submit: true,
    idState: '',
    status: 0,
    cutsToDelete: [],
    cutType: false
  };

  handleSetResellerCut = ({ courseId, userId, cutValue, cutType, notes, cutName }) => {

    const { setResellerCut } = this.props;


    setResellerCut({ courseId, userId, cutValue, cutType, notes, cutName }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'Cut added to course'
        });
        this.handleGetCourseCuts(courseId)
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  }

  handleGetCourseCuts = (courseId) => {
    const { getAllCutsForCourse } = this.props;
    getAllCutsForCourse({ courseId }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'showing all cuts of a course'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    })
  }

  handleAddCourseCut = () => {

    const { getAllCutsOfUser, getCutOfUserForCourse } = this.props;
    const courseId = String(this.refs.courseId.getValue()) || undefined;
    const userId = String(this.refs.clientId.getValue()) || undefined;
    const cutValue = String(this.refs.cutAmount.getValue()) || undefined;
    const notes = String(this.refs.cutInfo.getValue()) || undefined;
    const cutName = String(this.refs.cutName.getValue()) || undefined;

    const cutType = !!this.state.cutType ? 2 : 1

    !!cutValue && !!userId && !!courseId && this.handleSetResellerCut({ courseId, userId, cutValue, cutType, notes, cutName })
    !cutValue && !userId && !!courseId && this.handleGetCourseCuts(courseId)
    !cutValue && !!userId && !courseId && getAllCutsOfUser({ userId }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'showing all cuts for a user'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    })
    !cutValue && !!userId && !!courseId && getCutOfUserForCourse({ courseId, userId }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'showing cut for a user related with a course'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    })
  }

  onDeleteCut = (selectedCutId) => {
    const { deleteCut } = this.props;

    deleteCut({ cutId: selectedCutId })
      .then(res => {
        if (!res.error) {
          this.setState({
            open: true,
            message: 'Cut delete'
          });
          this.handleGetCourseCuts(selectedCutId)
        } else {
          this.setState({
            open: true,
            message: 'Error: ' + res.data
          });
        }
      })
  }

  setCutType = () => {
    this.setState({ cutType: !this.state.cutType });
  }


  render() {
    const { message, open } = this.state;
    const { listedCuts } = this.props


    return (
      <div style={styles.flex1}>
        <h2 style={styles.headline}>Reseller special cuts</h2>
        <p>Below you can see, set or delete course reseller cuts</p>

        <div>
          <TextField
            hintText='Course where cut will be applied'
            floatingLabelText='Course ID'
            onChange={this.checkId}
            ref='courseId'
            type='number'
          />
          <br />
          <TextField
            floatingLabelText='Client Id'
            hintText='Client that cut refers to'
            ref='clientId'
            type='number'
            onBlur={this.handleGetFullEmailListUCA}
          />
          <br />
          <TextField
            floatingLabelText='Cut value'
            hintText='cut value to be applied to course'
            ref='cutAmount'
            type='number'
            onBlur={this.handleGetFullEmailListUCA}
          />
          <br />
          <p>Cut value is in:</p>
          <div style={styles.flex}>

            <Toggle
              style={styles.toggle}
              toggled={this.state.cutType}
              onToggle={this.setCutType}
              id='0'
              ref='cutType'
              label='percentage'
            /><span style={{ marginLeft: 30 }}>absolute amount</span>
          </div>
          <br />
          <TextField
            floatingLabelText='Name (optional)'
            hintText='give a name to the cut'
            ref='cutName'
            type='text'
          />
          <br />
          <TextField
            floatingLabelText='Notes (optional)'
            hintText='info related with this cut'
            ref='cutInfo'
            type='text'
          />
          <br />

          <RaisedButton
            label='Submit'
            onClick={this.handleAddCourseCut}>
            <Snackbar
              message={message || ''}
              open={!!open}
              autoHideDuration={3000}
              onRequestClose={this.clearMessage}
            />
          </RaisedButton>
        </div>
        {!!listedCuts.length && <div>
          <ResellerCutsList
            onSelectItem={this.onDeleteCut}
            resellerCuts={listedCuts}
          />
        </div>}
      </div >
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setResellerCut: bindActionCreators(setResellerCut, dispatch),
    getAllCutsForCourse: bindActionCreators(getAllCutsForCourse, dispatch),
    getCutOfUserForCourse: bindActionCreators(getCutOfUserForCourse, dispatch),
    getAllCutsOfUser: bindActionCreators(getAllCutsOfUser, dispatch),
    deleteCut: bindActionCreators(deleteCut, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    listedCuts: state.courseBudget.listedCuts
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResellerCuts);
