import SteelCompass from '../services/SteelCompass';
import { FORTNOX_INIT, COMPLETE_AUTH } from "../constants/VendorActionTypes";

//fortnox init
export function fortnoxInit() {
  return {
    type: FORTNOX_INIT,
    promise: SteelCompass.request('vendor/fortnox', 'initialize')
  };
}

export function fortnoxCompleteAuth(code, state) {
  return {
    type: COMPLETE_AUTH,
    promise: SteelCompass.request('vendor/fortnox', 'completeAuth', {code, state})
  };
}
