//update course component to change the course's name
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Toggle, TextField, RaisedButton } from 'material-ui';
import Ajv from 'ajv';

import SteelCompass from '../services/SteelCompass';

const styles = {
  detail: {
    margin: 12
  },
  toggle: {
    marginBottom: 16
  }
};

class UpdateCourseForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    updateCourse: PropTypes.func,
    params: PropTypes.object,
    currCourse: PropTypes.object
  };

  state = {
    message: null,
    veryPublic: this.props.currCourse.public
  };

  //handleSubmit for the Update button
  handleSubmit = event => {
    const { updateCourse } = this.props;
    const { veryPublic } = this.state;
    event.preventDefault();

    const ajv = new Ajv({ allErrors: true });
    const schema = {
      additionalProperties: false,
      properties: {
        emails: {
          type: 'array',
          items: { type: 'string', format: 'email' }
        }
      }
    };

    const coursename = this.refs.name.getValue();
    const courseId = this.refs.id.getValue();
    // const slug = this.refs.slug.getValue();
    const price = this.refs.price.getValue();
    const ucpPeriod = this.refs.ucpPeriod.getValue();
    const emails = this.refs.emails.getValue();

    const data = { emails: emails.split(/[\s,]+/) };
    const valid = ajv.validate(schema, data);
    if (!valid && emails) {
      if (ajv.errors.length === data.emails.length) {
        return;
      } else {
        const filtered = data.emails.filter(
          (e, i) => !ajv.errors.find(x => x.dataPath.endsWith(`[${i}]`))
        );
        SteelCompass.request('mr.wolf-user', 'addToCourseByEmails', {
          courseId: this.props.params.courseId,
          emails: filtered
        });
      }
    } else {
      SteelCompass.request('mr.wolf-user', 'addToCourseByEmails', {
        courseId: this.props.params.courseId,
        emails: data.emails
      });
    }
    updateCourse &&
      updateCourse(courseId, coursename, true, price, ucpPeriod, veryPublic);
  };

  setToggle = (event, toggled) => {
    // (toggled && (this.state.veryPublic === 1 || this.state.veryPublic === true) &&
    //   this.setState({ veryPublic: 0 })) ||
    //   this.setState({ veryPublic: 1 });
    this.setState({ veryPublic: !!this.state.veryPublic ? 0 : 1 })
  };

  render() {
    const { currCourse, params } = this.props;

    var days = Math.floor(currCourse.ucpPeriod / (3600 * 24));
    return (
      <div style={styles.detail}>
        <TextField
          fullWidth
          id="name"
          defaultValue={currCourse.name}
          hintText=""
          floatingLabelText="Course Name"
          ref="name"
          type="text"
        />
        <TextField
          fullWidth
          id="courseId"
          defaultValue={params.courseId}
          hintText=""
          floatingLabelText="Course ID"
          ref="id"
          type="int"
          disabled
        />
        <TextField
          fullWidth
          id="slug"
          defaultValue={currCourse.slug}
          hintText=""
          floatingLabelText="Course slug"
          ref="slug"
          type="text"
          disabled
        />
        <TextField
          fullWidth
          id="price"
          defaultValue={currCourse.price}
          hintText=""
          floatingLabelText="Course Price"
          ref="price"
          type="int"
        />
        <TextField
          fullWidth
          id="ucpPeriod"
          defaultValue={currCourse.ucpPeriod}
          hintText=""
          floatingLabelText={
            'Default user course permission duration in seconds (or in days: ' +
            days +
            ' days)'
          }
          ref="ucpPeriod"
          type="int"
        />
        <TextField
          fullWidth
          id="emails"
          hintText="Add users by email (seperate emails by a space or comma)"
          floatingLabelText="Add users"
          defaultValue=""
          ref="emails"
          rows={2}
          multiLine
        />
        <Toggle
          id="0"
          key={'0'}
          defaultToggled={this.state.veryPublic === 1 || this.state.veryPublic === true}
          onToggle={this.setToggle.bind(this, '0')}
          style={styles.toggle}
          ref="public"
          label="Public"
        />
        <RaisedButton
          fullWidth
          label="Update Course"
          onClick={this.handleSubmit}
        />
      </div>
    );
  }
}

export default UpdateCourseForm;
