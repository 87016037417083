//Globals defining the different users
const ROLE = {
  guest: 0, // not logged in
  reader: 0b0000000000000001, // good old reader
  teacher: 0b0000000000000010, // can work with tasks and invite readers
  accountant: 0b0000000000000100, // financial guy
  editor: 0b0000000000001000, // can build courses
  owner: 0b0001000000000000, // owns Teh Bznss
  tester: 0b0010000000000000, // has access to test features
  assistant: 0b0100000000000000, // usual admin
  darkAdmin: 0b1000000000000000 // the great lord
};

export default ROLE;
