import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EditorAttachMoney from 'material-ui/svg-icons/editor/attach-money';
import ActionLabel from 'material-ui/svg-icons/action/label';
import ActionDateRange from 'material-ui/svg-icons/action/date-range';
import ActionLabelOutline from 'material-ui/svg-icons/action/label-outline';
import ActionAccountCircle from 'material-ui/svg-icons/action/account-circle';
import SocialPublic from 'material-ui/svg-icons/social/public';
import { List, ListItem, Divider, Card, CardMedia } from 'material-ui';

class CourseDetailsForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    currCourse: PropTypes.object
  };

  render() {
    const { currCourse } = this.props;
    let courseDetailItem, courseImage;

    if (typeof currCourse.file !== 'undefined') {
      if (currCourse.file.url != null) {
        courseImage = (
          <Card>
            <CardMedia>
              <img alt={'fileImg'} src={currCourse.file.url} />
            </CardMedia>
          </Card>
        );
      }
    }

    if (currCourse.deleted !== '0000-00-00 00:00:00') {
      courseDetailItem = (
        <List>
          <ListItem
            disabled={true}
            leftIcon={<ActionLabel />}
            primaryText="Name"
            secondaryText={currCourse.name}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabelOutline />}
            primaryText="Slug"
            secondaryText={currCourse.slug}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Created By"
            secondaryText={currCourse.createdBy}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Deleted By"
            secondaryText={currCourse.deletedBy}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionDateRange />}
            primaryText="Date Created"
            secondaryText={currCourse.created}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionDateRange />}
            primaryText="Date Deleted"
            secondaryText={currCourse.deleted}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<EditorAttachMoney />}
            primaryText="Price"
            secondaryText={currCourse.price}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<SocialPublic />}
            primaryText="Public"
            secondaryText={
              currCourse.public === 1 || currCourse.public === true ? 'Yes' : 'No'
            }
          />
          <Divider inset={true} />
        </List>
      );
    } else {
      courseDetailItem = (
        <List>
          <ListItem
            disabled={true}
            leftIcon={<ActionLabel />}
            primaryText="Name"
            secondaryText={currCourse.name}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabelOutline />}
            primaryText="Slug"
            secondaryText={currCourse.slug}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Created By"
            secondaryText={currCourse.createdBy}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionDateRange />}
            primaryText="Date Created"
            secondaryText={currCourse.created}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<EditorAttachMoney />}
            primaryText="Price"
            secondaryText={currCourse.price}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<SocialPublic />}
            primaryText="Public"
            secondaryText={
              currCourse.public === 1 || currCourse.public === true ? 'Yes' : 'No'
            }
          />
          <Divider inset={true} />
        </List>
      );
    }

    return (
      <div>
        {courseImage}
        {courseDetailItem}
      </div>
    );
  }
}

export default CourseDetailsForm;
