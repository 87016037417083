//This is the reducer for all coursio courses
import {
  FETCH_COURSES,
  FETCH_COURSES_REQUEST,
  FIND_COURSES_BY_USER_ID,
  FIND_COURSES_BY_USER_ID_REQUEST,
  FIND_INACTIVE_COURSES_BY_USER_ID
} from '../constants/CoursesActionTypes';

const initialState = {
  loading: true,
  courses: {}
};

const mapCoursesToId = (prevState, nextState) => {
  return nextState.reduce((previousResult, current) => {
    previousResult[current.id] = {
      ...prevState[current.id],
      ...current
    };
    return previousResult;
  }, {});
};

export default function allCoursesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COURSES:
      let courses;
      if (action.pageStart === 0) {
        courses = mapCoursesToId({}, action.res);
      } else {
        courses = mapCoursesToId(state.courses, action.res);
      }
      return {
        loading: false,
        courses
      };
    case FETCH_COURSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FIND_COURSES_BY_USER_ID:
      courses = mapCoursesToId({}, action.res);
      return {
        ...state,
        loading: false,
        courses
      };
    case FIND_INACTIVE_COURSES_BY_USER_ID:
    let inactiveCourses;  
    inactiveCourses =  action.res;
      return {
        ...state,
        loading: false,
        inactiveCourses
      };
    case FIND_COURSES_BY_USER_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
