import SteelCompass from '../services/SteelCompass';
import {
  LOGIN_USER,
  LOGOUT_USER,
  CHECK_USER_STATUS
} from '../constants/AuthActionTypes';

//user login function
export function login(identity, credential) {
  return {
    type: LOGIN_USER,
    promise: SteelCompass.request('auth', 'login', { identity, credential })
  };
}

//user logout function
export function logout() {
  return {
    type: LOGOUT_USER,
    promise: SteelCompass.request('auth', 'logout')
  };
}

//get user status if logged in or not
export function getStatus() {
  return {
    type: CHECK_USER_STATUS,
    promise: SteelCompass.request('auth', 'status')
  };
}
