//user login component
import PropTypes from 'prop-types';

import React from 'react';
import { TextField, RaisedButton } from 'material-ui';

class Login extends React.Component {
  static propTypes = {
    onLogin: PropTypes.func
  };

  handleSubmit = event => {
    const { onLogin } = this.props;
    event.preventDefault();
    const email = this.refs.email.getValue();
    const password = this.refs.password.getValue();
    if (onLogin) {
      onLogin(email, password);
    }
  };

  render() {
    return (
      <div>
        <TextField
          fullWidth
          hintText=""
          floatingLabelText="Email"
          ref="email"
          type="email"
        />
        <br />
        <TextField
          fullWidth
          hintText=""
          floatingLabelText="Password"
          ref="password"
          type="password"
        />
        <RaisedButton fullWidth label="Login" onClick={this.handleSubmit} />
      </div>
    );
  }
}

export default Login;
