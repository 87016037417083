import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  RaisedButton,
  IconButton,
  AppBar,
  Snackbar,
  TextField,
} from 'material-ui';
import { batchCreateUsersAndConnect } from '../../actions/User';
import ContentBackspace from 'material-ui/svg-icons/content/backspace';
import readXlsxFile from 'read-excel-file';

const styles = {
    detail: {
        margin: 12
    },
    toggle: {
        marginBottom: 16
    },
    buttons: {
        margin: 10
    }
};

const schema = {
    'Email': {
        prop: 'email',
        type: 'Email',
    },
    'Name': {
        prop: 'name',
        type: String
    },
    'Password': {
        prop: 'password',
        type: String
    },
    'Course IDs': {
        prop: 'courseIds',
        type: String
    }
};

class CreateBatchUser extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    message: null
  };

  static propTypes = {
    createUser: PropTypes.func
  };

  parseBatchFile = (file) => {
    readXlsxFile(file, {schema}).then(({rows, errors}) => {
      const newRows = rows;
      let shouldTryOneMore = false
      if (errors.length) {
        errors.map(error => {
          if (error.type === 'Email') {
            newRows[error.row - 1].email = error.value.replace(/\s/g, '');
            shouldTryOneMore = true;
          }
        })
      }
      if (shouldTryOneMore || errors.length === 0) {
       this.props
         .batchCreateUsersAndConnect(shouldTryOneMore ? newRows : rows, this.refs.recipientEmil.getValue())
         .then(res => {
           if (!res.error) {
             this.setState({ message: 'New users has been created!' });
           } else {
             this.setState({ message: res.data });
           }
         });
      } else {
        this.setState({ message: 'File contains none valid e-mail address'})
      }
    })
  };

  //after the message goes away the message becomes null again
  clearMessage = () => {
    this.setState({ message: null });
  };

  handleSubmit = event => {
    event.preventDefault();
    if( this.refs.batchFile.files[0] && this.refs.batchFile.files[0].name.includes('.xlsx') ) {
        this.parseBatchFile(this.refs.batchFile.files[0]);
    } else {
        this.setState( {message: 'The files must be of type .xslx'})
    }

  };

  closeEdit = () => {
    this.context.router.push('/users');
  };

  render() {
    const { message } = this.state;

    return (
      <div>
        <div>
          <AppBar
            title={'Creating new users'}
            iconElementLeft={
              <IconButton
                tooltip="Cancel"
                tooltipPosition="top-right"
                onClick={this.closeEdit}
              >
                <ContentBackspace />
              </IconButton>
            }
          />
          <div style={styles.detail}>

            <p>Excel must contain following headers:
                <br/>
                <i>Email,Name,Password,Course IDs</i>
            </p>
            <p>
                If you like to supply Course IDs then you must separate them with comma
            </p>

            <TextField
                fullWidth
                id="email"
                hintText=""
                floatingLabelText="User recipient (Optinal)"
                ref="recipientEmil"
                type="text"
            />

            <RaisedButton
                style={styles.buttons}
                containerElement='label'
                label='Upload file (.xlsx) with users'>
                <input ref="batchFile" type="file" style={{ display: "none" }} />
            </RaisedButton>

            <RaisedButton
                style={styles.buttons}
                fullWidth
                label="Create User"
                onClick={this.handleSubmit}
            />
          </div>
          <Snackbar
            message={message || ''}
            open={!!message}
            autoHideDuration={3500}
            onRequestClose={this.clearMessage}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    batchCreateUsersAndConnect: bindActionCreators(batchCreateUsersAndConnect, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBatchUser);
