import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  List,
  ListItem,
  Paper,
  IconMenu,
  IconButton,
  Divider
} from 'material-ui';
import ContentClear from 'material-ui/svg-icons/content/clear';
import NavigationRefresh from 'material-ui/svg-icons/navigation/refresh';

const styles = {
  list: {
    margin: 8
  },
  notAccepted: {
    backgroundColor: '#f4d7dc'
  },
  accepted: {
    backgroundColor: '#def7e6'
  }
};

class CurrUserInvitationsList extends Component {
  static propTypes = {
    children: PropTypes.node,
    invitations: PropTypes.object,
    onResendInvitationNow: PropTypes.func,
    onDeleteInvitationNow: PropTypes.func,
    params: PropTypes.object
  };

  resendInvitationNow = invitation => {
    const { onResendInvitationNow } = this.props;
    onResendInvitationNow && onResendInvitationNow(invitation);
  };

  deleteInvitationNow = invitation => {
    const { onDeleteInvitationNow } = this.props;
    onDeleteInvitationNow && onDeleteInvitationNow(invitation);
  };

  render() {
    const { invitations, params } = this.props;

    if (invitations.loading) {
      return <p>Search Invitations</p>;
    }

    let finInvitations;
    if (window.location.pathname !== `/users/${params.userId}`) {
      finInvitations = <div> </div>;
    } else {
      finInvitations = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(invitations).map(id => {
              const invitation = invitations[id];

              let accepted = null;

              invitation.accepted
                ? (accepted = 'Accepted by ')
                : (accepted = 'Not Accepted by ');

              const rightIconMenu = (
                <div>
                  {!invitation.accepted ? (
                    <IconMenu
                      style={{ marginTop: '20px' }}
                      iconButtonElement={
                        <IconButton
                          tooltip="Resend invitation"
                          tooltipPosition="bottom-center"
                          onClick={this.resendInvitationNow.bind(
                            this,
                            invitation
                          )}
                        >
                          <NavigationRefresh />
                        </IconButton>
                      }
                    />
                  ) : null}
                  <IconMenu
                    style={{ marginTop: '20px' }}
                    iconButtonElement={
                      <IconButton
                        tooltip="Delete invitation"
                        tooltipPosition="bottom-center"
                        onClick={this.deleteInvitationNow.bind(
                          this,
                          invitation
                        )}
                      >
                        <ContentClear />
                      </IconButton>
                    }
                  />
                </div>
              );

              return (
                <List key={invitation.id}>
                  <ListItem
                    primaryText={invitation.id}
                    secondaryText={
                      <p>
                        <span style={{ color: 'black' }}>
                          Gift Code: {invitation.giftCode}{' '}
                          {invitation.accepted
                            ? null
                            : invitation.expires.slice(0, 10) <
                              new Date().toISOString().substring(0, 10)
                              ? ' - Invitation Expired (on ' +
                                invitation.expires +
                                ')'
                              : ' - Invitation Expires (on ' +
                                invitation.expires +
                                ')'}{' '}
                        </span>
                        <br />
                        <span style={{ color: 'grey' }}>
                          {accepted}
                          {invitation.email}
                        </span>
                      </p>
                    }
                    secondaryTextLines={2}
                    rightIconButton={rightIconMenu}
                    style={
                      !invitation.accepted
                        ? styles.notAccepted
                        : styles.accepted
                    }
                  />
                  <Divider />
                </List>
              );
            })}
          </List>
        </Paper>
      );
    }

    return (
      <div>
        {Object.keys(invitations).length === 0 ? (
          <p
            style={{
              textAlign: 'center',
              verticalAlign: 'middle',
              marginTop: '30px'
            }}
          >
            None Found
          </p>
        ) : (
          finInvitations
        )}
      </div>
    );
  }
}

export default CurrUserInvitationsList;
