import SteelCompass from '../services/SteelCompass';
import {
  FETCH_COURSES,
  FIND_COURSES_BY_USER_ID,
  FIND_INACTIVE_COURSES_BY_USER_ID
} from '../constants/CoursesActionTypes';

//this function brings all users courses and specific filters can be applied
export function fetchCourses(filters = [], pageStart = 0, pageLength = 15) {
  return {
    type: FETCH_COURSES,
    pageStart,
    promise: SteelCompass.request(
      'mr.wolf-course',
      'findAll',
      { filters },
      pageStart,
      pageLength
    )
  };
}

//Finds courses connected to the user by user's id
export function findCoursesByUserId(userId, pageStart = 0, pageLength = 15) {
  return {
    type: FIND_COURSES_BY_USER_ID,
    userId,
    pageStart,
    promise: SteelCompass.request(
      'mr.wolf-course',
      'findByUserId',
      { userId },
      pageStart,
      pageLength
    )
  };
}

//Finds inactive courses connected to the user by user's id
export function findInactiveCoursesByUserId(userId, pageStart = 0, pageLength = 15) {
  return {
    type: FIND_INACTIVE_COURSES_BY_USER_ID,
    userId,
    pageStart,
    promise: SteelCompass.request(
      'mr.wolf-course',
      'findByUserId',
      { userId, activeCourses:false },
      pageStart,
      pageLength
    )
  };
}
