import {
  GET_INVITATIONS,
  GET_INVITATIONS_REQUEST
} from '../constants/InvitationsActionTypes';

const initialState = {
  loading: true,
  invitations: {}
};

const mapInvitationsToId = (prevState, nextState) => {
  return nextState.reduce((previousResult, current) => {
    previousResult[current.id] = {
      ...prevState[current.id],
      ...current
    };
    return previousResult;
  }, {});
};

export default function invitationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVITATIONS:
      let invitations;
      if (action.pageStart === 0) {
        invitations = mapInvitationsToId({}, action.res);
      } else {
        invitations = mapInvitationsToId(state.invitations, action.res);
      }
      return {
        loading: false,
        invitations
      };
    case GET_INVITATIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
