import React from 'react';
import PropTypes from 'prop-types';
import { fortnoxInit } from '../../actions/Vendor';
import {
  Dialog, FlatButton,
  RaisedButton,
} from 'material-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from "../Bar/Header";

class Fortnox extends React.Component {
  static propTypes = {
    fortnoxInit: PropTypes.func,
    fortnoxUrl: PropTypes.string
  };

  state = {
    fortnoxModal: false,
  };

  handleOpenModal = () => {
    this.setState({ fortnoxModal: true });
  };

  handleCloseModal = () => {
    this.setState({ fortnoxModal: false });
  };

  fortnoxInitFn() {
    this.props.fortnoxInit();
  }

  render() {
    if (this.props.fortnoxUrl) {
      return window.location.replace(this.props.fortnoxUrl)
    }
    return <div style={{marginBottom: 10}}>
      <Header title="Fortnox" />


        <div style={{ marginBottom: 10 }}>
          <RaisedButton
            secondary
            label="FORTNOX INIT"
            onClick={this.handleOpenModal}
          />
        </div>

        <Dialog
          title="By clicking this button you will reset the Fortnox API connection, do you want to continue?"
          actions={[
            <FlatButton
              label="Cancel"
              secondary
              onClick={this.handleCloseModal}
            />,
            <FlatButton
              label="I'm sure"
              secondary
              onClick={this.fortnoxInitFn.bind(this)}
            />,
          ]}
          modal={false}
          open={this.state.fortnoxModal}
          onRequestClose={this.handleCloseModal}
          autoScrollBodyContent={true}
         />
    </div>
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fortnoxInit: bindActionCreators(fortnoxInit, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    fortnoxUrl: state.vendorReducer.fortnoxUrl
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Fortnox);
