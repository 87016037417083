//root JS file
import React from 'react';
//bring in browserHistory instead of hashHistory to clean URLs
import { render } from 'react-dom';
import { browserHistory, Router } from 'react-router';
import { Provider } from 'react-redux';

import createStore from './stores';
import createRoutes from './config/routes';

const store = createStore();

render(
  <Provider store={store}>
    <Router children={createRoutes(store)} history={browserHistory} />
  </Provider>,
  document.getElementById('root')
);
