import {
  GET_COURSE_RESELLER_CUT,
  GET_ALL_CUTS_OF_USER,
  GET_ALL_CUTS_FROM_COURSE,
  DELETE_COURSE_RESELLER_CUT,
  GET_COURSE_RESELLER_CUT_REQUEST,
  SET_COURSE_RESELLER_CUTS_REQUEST,
  GET_ALL_CUTS_OF_USER_REQUEST,
  DELETE_COURSE_RESELLER_CUT_REQUEST
} from '../constants/CourseBudgetActionTypes';


const initialState = {
  loading: true,
  listedCuts: []
};

export default function testUsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COURSE_RESELLER_CUT:
      return {
        loading: false,
        listedCuts: [action.res]
      };
    case GET_ALL_CUTS_OF_USER:
    case GET_ALL_CUTS_FROM_COURSE:
      return {
        loading: false,
        listedCuts: action.res
      };
    case GET_COURSE_RESELLER_CUT_REQUEST:
    case SET_COURSE_RESELLER_CUTS_REQUEST:
    case GET_ALL_CUTS_OF_USER_REQUEST:
    case DELETE_COURSE_RESELLER_CUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COURSE_RESELLER_CUT:
      console.log('DELETE', action)
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
