import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { generateInvitationsList } from '../../actions/Accounting';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  RaisedButton,
  Snackbar,
  TextField,
  Toggle,
  SelectField,
  MenuItem,
} from 'material-ui';

const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    marginBottom: 24,
    marginTop: 32,
    width: 200
  },
  status: {
    marginBottom: 32
  },
  flex1: { flex: 1 },
  flex: {
    display: 'flex',
  }
};

const statusItems = [
  { value: 0, payload: 'any', text: 'Any' },
  { value: 1, payload: 'new', text: 'New' },
  { value: 2, payload: 'accepted', text: 'Accepted' }
];

class InvitationsListReport extends Component {
  static propTypes = {
    generateInvitationsList: PropTypes.func,
  };

  state = {
    message: null,
    open: false,
    value: 'a',
    error: 'This field is mandatory',
    submit: true,
    idState: '',
    costState: '',
    percentageState: '',
    uniqueUsers: false,
    fromCircles: false,
    ucpData: false,
    uniqueUsersPermissions: false,
    status: 0,
    paymentStatus: 0,
    hidePurchases: false,
    paymentFormat: 0,
    emailsSelected: [],
  };

  clearMessage = () => {
    this.setState({ message: null, open: false });
  };

  setUniqueUsers = () => {
    this.setState({ uniqueUsers: !this.state.uniqueUsers });
  };

  setFromCircles = () => {
    this.setState({ fromCircles: !this.state.fromCircles });
  };

  setUcpData = () => {
    this.setState({ ucpData: !this.state.ucpData });
  };

  setStatus = (_, status) => {
    this.setState({ status });
  };

  handleInvitationsList = () => {
    const ownerId = Number(this.refs.ownerId.getValue()) || undefined;
    const startDate = String(this.refs.startDateInvitation.getValue()) || undefined;
    const endDate = String(this.refs.endDateInvitation.getValue()) || undefined;
    const uniqueUsers = this.state.uniqueUsers;
    const fromCircles = this.state.fromCircles;
    const addUcpData = this.state.ucpData;
    const statusItem = statusItems.find(
      status => status.value === this.state.status
    );
    const status = statusItem && statusItem.payload;

    const { generateInvitationsList } = this.props;
    generateInvitationsList({
      ownerId,
      startDate,
      endDate,
      uniqueUsers,
      fromCircles,
      addUcpData,
      status
    }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'Data sent to your email'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  };

  render() {
    const { message, open } = this.state;


    return (
      <div>
        <h2 style={styles.headline}>Invitations list</h2>
        <p>Below you can get a list of invitations</p>

        <TextField
          hintText="Owner's user ID"
          floatingLabelText="Owner's user ID"
          ref='ownerId'
          type='number'
        />
        <br />
        <TextField
          floatingLabelText='Start date'
          ref='startDateInvitation'
          type='date'
        />
        <br />
        <TextField
          floatingLabelText='End date'
          ref='endDateInvitation'
          type='date'
        />
        <br />
        <Toggle
          style={styles.toggle}
          toggled={this.state.uniqueUsers}
          onToggle={this.setUniqueUsers}
          id='0'
          ref='uniqueUsers'
          label='Only unique users'
        />
        <Toggle
          style={styles.toggle}
          toggled={this.state.fromCircles}
          onToggle={this.setFromCircles}
          id='1'
          ref='fromCircles'
          label='Include invtations created by admins of the owned circles'
        />
        <Toggle
          label='Enhance with UCPs'
          toggled={this.state.ucpData}
          onToggle={this.setUcpData}
          id='2'
          ref='addUcpData'
          style={styles.toggle}
        />
        <SelectField
          style={styles.status}
          floatingLabelStyle={{
            transform:
              'perspective(1px) scale(0.75) translate3d(2px, -28px, 0)'
          }}
          floatingLabelText='Status'
          onChange={this.setStatus}
          value={this.state.status}>
          {statusItems.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              primaryText={item.text}
            />
          ))}
        </SelectField>
        <br />
        <RaisedButton
          label='Submit'
          onClick={this.handleInvitationsList}>
          <Snackbar
            message={message || ''}
            open={!!open}
            autoHideDuration={3000}
            onRequestClose={this.clearMessage}
          />
        </RaisedButton>
      </div>

    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    generateInvitationsList: bindActionCreators(generateInvitationsList, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationsListReport);
