import SteelCompass from '../services/SteelCompass';
import { GET_PAYMENTS } from '../constants/PaymentsActionTypes';

//this function brings all payments and specific filters can be applied
export function getPayments(
  filters = [],
  startDate,
  endDate,
  pageStart = 0,
  pageLength = 15
) {
  return {
    type: GET_PAYMENTS,
    pageStart,
    promise: SteelCompass.request(
      'mr.wolf-payment',
      'findAll',
      { filters, startDate, endDate },
      pageStart,
      100
    )
  };
}
