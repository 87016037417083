import SteelCompass from '../services/SteelCompass';
import { GET_USERS, POPULATE_USERS } from '../constants/UsersActionTypes';

//get users function with specific filters
export function getUsers(filters = [], pageStart = 0, pageLength = 15) {
  return {
    type: GET_USERS,
    pageStart,
    promise: SteelCompass.request(
      'mr.wolf-user',
      'findAll',
      { filters },
      pageStart,
      pageLength
    )
  };
}

export function populateUsers(token, pageStart = 0, pageLength = 15) {
  return {
    type: POPULATE_USERS,
    token,
    pageStart,
    promise: SteelCompass.request(
      'mr.wolf-user',
      'suggest',
      { token },
      pageStart,
      pageLength
    )
  };
}
