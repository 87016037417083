

export const SET_COURSE_RESELLER_CUTS = 'SET_COURSE_RESELLER_CUTS';
export const SET_COURSE_RESELLER_CUTS_REQUEST = 'SET_COURSE_RESELLER_CUTS_REQUEST';

export const GET_COURSE_RESELLER_CUT = 'GET_COURSE_RESELLER_CUT';
export const GET_COURSE_RESELLER_CUT_REQUEST = 'GET_COURSE_RESELLER_CUT_REQUEST';

export const GET_ALL_CUTS_OF_USER = 'GET_ALL_CUTS_OF_USER';
export const GET_ALL_CUTS_OF_USER_REQUEST = 'GET_ALL_CUTS_OF_USER_REQUEST';

export const GET_ALL_CUTS_FROM_COURSE = 'GET_ALL_CUTS_FROM_COURSE';
export const GET_ALL_CUTS_FROM_COURSE_REQUEST = 'GET_ALL_CUTS_FROM_COURSE_REQUEST';

export const DELETE_COURSE_RESELLER_CUT = 'DELETE_COURSE_RESELLER_CUT';
export const DELETE_COURSE_RESELLER_CUT_REQUEST = 'DELETE_COURSE_RESELLER_CUT_REQUEST';
