import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, TextField } from 'material-ui';

const styles = {
  block: {
    maxWidth: 1024,
    margin: '16px auto',
    padding: 8
  }
};

class FilterInvitations extends Component {
  static propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = {
    searchText: null
  };

  filter() {
    const { searchText } = this.state;
    const { onChange } = this.props;
    onChange && onChange(searchText);
  }

  tryFilter() {
    if (this._timer) {
      clearTimeout(this._timer);
    }

    this._timer = setTimeout(() => {
      this.filter();
      this._timer = null;
    }, 500);
  }

  changeSearchText = event => {
    const { router } = this.context;
    const { value } = event.target;
    this.setState({ searchText: value }, () => {
      this.tryFilter();
    });
    router.push('/invitations');
  };

  render() {
    const { searchText } = this.state;

    return (
      <Paper style={styles.block}>
        <TextField
          value={searchText || ''}
          onChange={this.changeSearchText}
          fullWidth
          floatingLabelText={'Search invitation by email'}
        />
      </Paper>
    );
  }
}

export default FilterInvitations;
