import React from 'react';
import { Route, IndexRoute } from 'react-router';

import Main from '../components/Main';
import UserPage from '../containers/UserAuth/UserPage';
import AllCourses from '../containers/Courses/AllCourses';
import CourseDetails from '../containers/Courses/CourseDetails';
import Users from '../containers/Users/Users';
import UserManagement from '../containers/UserManagement/UserManagement';
import CreateUser from '../containers/Users/CreateUser';
import CreateBatchUser from '../containers/Users/CreateBatchUser';
import UserDetails from '../containers/Users/UserDetails';
import Receipts from '../containers/Accounting/Receipts';
import StatsAndCoefficients from '../containers/Accounting/StatsAndCoefficients';
import Invitations from '../containers/Invitations/Invitations';
import InvitationDetails from '../containers/Invitations/InvitationDetails';
import Payments from '../containers/Payments/Payments';
import PaymentDetails from '../containers/Payments/PaymentDetails';
import Fortnox from '../containers/Fortnox/Fortnox';
import FortnoxFinalize from '../containers/Fortnox/FortnoxFinalize';

export default function createRoutes(store) {
  const requireLogin = (nextState, replaceState, callback) => {
    const state = store.getState();
    if (!state.user || !state.user.id) {
      replaceState({
        pathname: '/',
        state: { nextPathname: nextState.location.pathname }
      });
    }
    callback();
  };

  return (
    <Route path="/" component={Main}>
      <IndexRoute component={UserPage} />

      <Route path="/fortnox" component={Fortnox} onEnter={requireLogin} />
      <Route path="/fortnox/finalize" component={FortnoxFinalize} onEnter={requireLogin} />

      <Route path="/users" component={Users} onEnter={requireLogin}>
        <Route path="/users/new" component={CreateUser} />
        <Route path="/users/Batch" component={CreateBatchUser} />
        <Route path="/users/:userId" component={UserDetails} />
      </Route>

      <Route path="/business-invites" component={UserManagement} onEnter={requireLogin} />

      <Route path="/allcourses" component={AllCourses} onEnter={requireLogin}>
        <Route path="/allcourses/:courseId" component={CourseDetails} />
      </Route>

      <Route path="/">
        <Route path="/receipts" component={Receipts} onEnter={requireLogin} />
        <Route
          path="/statsandcoefficients"
          component={StatsAndCoefficients}
          onEnter={requireLogin}
        />
      </Route>

      <Route path="/invitations" component={Invitations} onEnter={requireLogin}>
        <Route
          path="/invitations/:invitationId"
          component={InvitationDetails}
        />
      </Route>

      <Route path="/payments" component={Payments} onEnter={requireLogin}>
        <Route path="/payments/:paymentId" component={PaymentDetails} />
      </Route>
    </Route>
  );
}
