//here is the list of all the courses from coursio users
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { List, ListItem, Paper, Checkbox } from 'material-ui';
import ActionVisibilityOff from 'material-ui/svg-icons/action/visibility-off';

const styles = {
  list: {
    margin: 8
  },
  listEmpty: {
    margin: 8,
    padding: 8
  },
  checkbox: {
    maxWidth: 30,
    float: 'left',
    marginTop: '20px',
    left: '10px'
  }
};

class AllCoursesList extends Component {
  static propTypes = {
    courses: PropTypes.object,
    onChooseCourse: PropTypes.func,
    onCheckCourses: PropTypes.func,
    params: PropTypes.object
  };

  state = {
    coursesChosen: {}
  };

  getCourse = course => {
    const { onChooseCourse } = this.props;
    if (onChooseCourse) {
      onChooseCourse(course);
    }
  };

  checkCourses = (course, checked) => {
    const { onCheckCourses } = this.props;
    const { coursesChosen } = this.state;

    if (checked) {
      if (Object.keys(this.state.coursesChosen).length === 0) {
        // map is empty, so just add item
        coursesChosen[course.id] = course;
      } else {
        if (course.id in coursesChosen) {
          // user id exists so remove item
          delete coursesChosen[course.id];
        } else {
          //add user id, it does not exist, so just add item
          coursesChosen[course.id] = course;
        }
      }
    }

    onCheckCourses && onCheckCourses(coursesChosen);
  };

  render() {
    const { courses } = this.props;

    if (courses.loading) {
      return <p>Search Courses</p>;
    }

    let finCourses;
    if (window.location.pathname !== '/allcourses') {
      finCourses = <div />;
    } else {
      finCourses = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(courses).map(ID => {
              const course = courses[ID];

              let check = (
                <div>
                  <Checkbox
                    ref="check"
                    onCheck={this.checkCourses.bind(this, course)}
                    checked={course.id in this.state.coursesChosen}
                    style={styles.checkbox}
                  />
                </div>
              );

              if (course.deleted === true || course.deletedBy === false) {
                return (
                  <div key={course.id}>
                    {check}
                    <ListItem
                      style={{ marginLeft: '40px' }}
                      rightIcon={<ActionVisibilityOff />}
                      onClick={this.getCourse.bind(this, course)}
                      primaryText={course.name}
                      secondaryText={`${course.id} ${!!course.creator ? '- owner:' : ''} ${!!course.creator ? course.creator.name : ''}  ${!!course.creator ? '(' + course.creator.id + ')' : ''}`}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={course.id}>
                    {check}
                    <ListItem
                      style={{ marginLeft: '40px' }}
                      onClick={this.getCourse.bind(this, course)}
                      primaryText={course.name}
                      secondaryText={`${course.id} ${!!course.creator ? '- owner:' : ''} ${!!course.creator ? course.creator.name : ''}  ${!!course.creator ? '(' + course.creator.id + ')' : ''}`}
                    />
                  </div>
                );
              }
            })}
          </List>
        </Paper>
      );
    }

    return (
      <div>
        {Object.keys(courses).length !== 0 ? (
          finCourses
        ) : (
          <Paper style={styles.listEmpty}>No results found</Paper>
        )}
      </div>
    );
  }
}

export default AllCoursesList;
