//user logout component
import PropTypes from 'prop-types';

import React from 'react';
import { RaisedButton } from 'material-ui';

class Logout extends React.Component {
  static propTypes = {
    onLogout: PropTypes.func
  };

  handleSubmit = event => {
    const { onLogout } = this.props;
    event.preventDefault();
    if (onLogout) {
      onLogout();
    }
  };

  render() {
    return (
      <div>
        <RaisedButton label="Logout" onClick={this.handleSubmit} />
      </div>
    );
  }
}

export default Logout;
