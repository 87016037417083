import SteelCompass from '../services/SteelCompass';
import { GET_CS_SALES, GET_CS_INVITES } from '../constants/KpiActionTypes';

// Get top sales per store, for last 7 days
export function getCSSales() {
  return {
    type: GET_CS_SALES,
    promise: SteelCompass.request('mr.wolf-kpi', 'csSales', {})
  };
}

// Get top invitations per store, for last 7 days
export function getCSInvites() {
  return {
    type: GET_CS_INVITES,
    promise: SteelCompass.request('mr.wolf-kpi', 'csInvites', {})
  };
}
