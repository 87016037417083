import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { salesCoefficients } from '../../actions/Accounting';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  RaisedButton,
  Snackbar,
  TextField,
  List,
  ListItem,
} from 'material-ui';

const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    marginBottom: 24,
    marginTop: 32,
    width: 200
  },
  status: {
    marginBottom: 32
  },
  flex1: { flex: 1 },
  flex: {
    display: 'flex',
  }
};

class SalesCoefficients extends Component {
  static propTypes = {
    salesCoefficients: PropTypes.func,
  };

  state = {
    message: null,
    open: false,
    value: 'a',
    error: 'This field is mandatory',
    submit: true,
    idState: '',
    costState: '',
    percentageState: '',
    uniqueUsers: false,
    fromCircles: false,
    ucpData: false,
    uniqueUsersPermissions: false,
    status: 0,
    paymentStatus: 0,
    hidePurchases: false,
    paymentFormat: 0,
    emailsSelected: [],
  };

  clearMessage = () => {
    this.setState({ message: null, open: false });
  };

  handleChange = value => {
    this.setState({
      value: value
    });
  };

  checkId = event => {
    event.preventDefault();
    const id = this.refs.id.getValue();

    if (id === null || id === '') {
      this.setState({ error: 'This field is mandatory', submit: true });
    } else {
      this.setState({ error: '', submit: false });
    }
  };

  handleSalesCoefficients = event => {
    event.preventDefault();

    const { salesCoefficients } = this.props;
    const userId = parseInt(this.refs.id.getValue());
    const invitationCost = parseInt(this.refs.cost.getValue());
    const salesPercentage = parseFloat(this.refs.percentage.getValue());

    salesCoefficients({ userId, invitationCost, salesPercentage }).then(res => {
      if (!res.error) {
        this.setState({
          idState: userId,
          costState: res.data.invitationCost,
          percentageState: res.data.salesPercentage,
          open: true,
          message:
            invitationCost !== res.data.invitationCost &&
              salesPercentage !== res.data.salesPercentage
              ? 'Data for user ' + userId
              : 'Data has been changed for user ' + userId
        });
      } else {
        this.setState({ open: true, message: 'Error: ' + res.data });
      }
    });
  };

  render() {
    const { message, open, error, submit } = this.state;

    return (
      <div style={styles.flex1}>
        <div>
          <h2 style={styles.headline}>Sales Coefficients</h2>
          <p>Below you can get or set customer sales coefficients</p>

          <TextField
            hintText='Customer user ID'
            floatingLabelText='User ID'
            errorText={error}
            onChange={this.checkId}
            ref='id'
            type='number'
          />
          <br />
          <TextField
            hintText='Cost charged by Couzio'
            floatingLabelText='Invitation Cost'
            ref='cost'
            type='number'
          />
          <br />
          <TextField
            hintText='Percentage charged by Couzio'
            floatingLabelText='Sales percentage'
            ref='percentage'
            type='number'
          />
          <br />
          <RaisedButton
            label='Submit'
            onClick={this.handleSalesCoefficients}
            disabled={submit}>
            <Snackbar
              message={message || ''}
              open={!!open}
              autoHideDuration={3000}
              onRequestClose={this.clearMessage}
            />
          </RaisedButton>
          <br />
        </div>
        <List>
          <ListItem
            disabled
            primaryText={
              this.state.idState === ''
                ? ''
                : 'User Id: ' + this.state.idState
            }
          />
          {/* <Divider /> */}
          <ListItem
            disabled
            primaryText={
              this.state.costState === ''
                ? ''
                : 'Invitation cost charged by Coursio: ' +
                this.state.costState
            }
          />
          <ListItem
            disabled
            primaryText={
              this.state.percentageState === ''
                ? ''
                : 'Sales percentage charged by Coursio: ' +
                this.state.percentageState
            }
          />
        </List>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    salesCoefficients: bindActionCreators(salesCoefficients, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesCoefficients);
