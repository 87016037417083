//This is the reducer for bringing all coursio payments
import {
  GET_PAYMENTS,
  GET_PAYMENTS_REQUEST
} from '../constants/PaymentsActionTypes';

const initialState = {
  loading: true,
  payments: {}
};

const mapPaymentsToId = (prevState, nextState) => {
  return nextState.reduce((previousResult, current) => {
    previousResult[current.id] = {
      ...prevState[current.id],
      ...current
    };
    return previousResult;
  }, {});
};

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENTS:
      let payments;
      if (action.pageStart === 0) {
        payments = mapPaymentsToId({}, action.res);
      } else {
        payments = mapPaymentsToId(state.payments, action.res);
      }
      return {
        loading: false,
        payments
      };
    case GET_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
