//This is the container for bringing up the list of all users' courses and a search filtering
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CircularProgress, IconButton, Paper } from 'material-ui';
import { showBulkOptions, hideBulkOptions } from '../../actions/Ui';
import { fetchCourses } from '../../actions/Courses';
import AllCoursesList from '../../components/AllCoursesList';
import FilterCourses from '../../components/FilterCourses';
import Header from '../Bar/Header';
import ActionHelp from 'material-ui/svg-icons/action/help';
import BulkOptions from '../../components/BulkOptions';

const styles = {
  loader: {
    position: 'fixed',
    bottom: 16,
    right: 16
  },
  paper: {
    width: '260px',
    margin: 5,
    padding: 5,
    display: 'inline-block'
  },
  list: {
    margin: 8,
    padding: 8
  }
};

class AllCourses extends Component {
  static propTypes = {
    children: PropTypes.node,
    courses: PropTypes.object,
    fetchCourses: PropTypes.func,
    onPageScroll: PropTypes.func,
    showBulkOptions: PropTypes.func,
    hideBulkOptions: PropTypes.func,
    bulkOptions: PropTypes.bool,
    coursesPerPage: PropTypes.number,
    loading: PropTypes.bool,
    route: PropTypes.object,
    path: PropTypes.object,
    params: PropTypes.object
  };

  state = {
    pageStart: 0,
    pageEnd: 30,
    filters: [],
    chosenCourses: {},
    prevCourses: {}
  };

  static defaultProps = {
    coursesPerPage: 30
  };

  static contextTypes = {
    router: PropTypes.object
  };

  onChooseCourse = course => {
    const { router } = this.context;

    router.push(`/allcourses/${course.id}`);
  };

  onCheckCourses = elements => {
    const { showBulkOptions, hideBulkOptions } = this.props;

    this.setState({ chosenCourses: elements }, () => {
      (Object.keys(elements).length > 0 &&
        window.location.pathname === '/allcourses' &&
        showBulkOptions()) ||
        hideBulkOptions();
    });
  };

  componentDidMount() {
    const { fetchCourses, coursesPerPage, hideBulkOptions } = this.props;
    const { pageStart, filters } = this.state;

    this._node = document.body;
    fetchCourses(filters, pageStart, coursesPerPage);

    hideBulkOptions();
    window.addEventListener('scroll', this.onPageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll);
  }

  onPageScroll = () => {
    const userScrollHeight = window.innerHeight + window.scrollY;
    const windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight) {
      this.loadNext();
    }
  };

  loadNext() {
    const { fetchCourses, coursesPerPage, courses } = this.props;
    const { filters, pageStart, pageEnd, prevCourses } = this.state;

    this.setState(
      {
        pageStart: 0,
        pageEnd: pageEnd + coursesPerPage,
        prevCourses: courses,
      },
      () => {
        if(Object.keys(prevCourses).length !== Object.keys(courses).length)
        fetchCourses(filters, pageStart, this.state.pageEnd);
      }
    );
  }

  filter() {
    const { fetchCourses, coursesPerPage } = this.props;
    const { filters } = this.state;

    fetchCourses(filters, 0, coursesPerPage);
  }

  //if there is text on the search input,then apply the filters else set the filters empty
  handleFilter = searchText => {
    if (searchText !== null) {
      let searchMode = Number(searchText) ? 'id' : 'name';
      this.setState(
        {
          filters: [{ mode: searchMode, value: searchText }],
          pageEnd: this.props.coursesPerPage
        },
        () => {
          this.filter();
        }
      );
    } else {
      this.setState(
        {
          filters: []
        },
        () => {
          this.filter();
        }
      );
    }
  };

  render() {
    const { chosenCourses, pageEnd } = this.state;
    const { courses, loading, children, params, bulkOptions } = this.props;

    let bulk;
    bulk = (
      <div>
        <Paper style={styles.list}>
          Total Items Chosen: {Object.keys(chosenCourses).length || 0}
          <BulkOptions chosenItems={chosenCourses} />
        </Paper>
      </div>
    );

    return (
      <div>
        <Header
          title="Courses Page"
          iconElementRight={
            <IconButton tooltip="Info" onClick={this.handleTouchTap}>
              <ActionHelp />
            </IconButton>
          }
        />

        {Object.keys(courses).length <= pageEnd
          ? loading && (
              <div style={styles.loader}>
                <CircularProgress />
              </div>
            )
          : null}

        <div style={{ display: 'flex', alignItems: 'stretch', marginTop: 60 }}>
          <div style={{ flex: 1 }}>
            <FilterCourses onChange={this.handleFilter} />
            {bulkOptions === true}{' '}
            {Object.keys(chosenCourses).length !== 0 ? bulk : <div> </div>}
            <AllCoursesList
              onChooseCourse={this.onChooseCourse}
              courses={courses}
              params={params}
              onCheckCourses={this.onCheckCourses}
            />
          </div>

          {children && <Paper style={{ flex: 1, margin: 8 }}>{children}</Paper>}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchCourses: bindActionCreators(fetchCourses, dispatch),
    showBulkOptions: bindActionCreators(showBulkOptions, dispatch),
    hideBulkOptions: bindActionCreators(hideBulkOptions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    courses: state.courses.courses,
    loading: state.courses.loading,
    bulkOptions: state.ui.bulkOptions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCourses);
