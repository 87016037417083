import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { finalize, getInviteList, sendInvite, createUser, deleteInviteItem } from '../../actions/UserManagement';
import {
  TextField,
  RaisedButton,
  SelectField,
  MenuItem,
  Dialog
} from 'material-ui';
import Header from '../Bar/Header';

const BUSINESS_INVITE_STATUS = {
  UNKNOWN: 0,
  SENT_OUT: 1,
  ACCEPTED: 2,
  USER_CREATED: 4,
  FINALIZED: 8,
  TERMS_VIEWED: 16
};

const styles = {
  inviteContainer: {
    display: 'flex',
    margin: 15,
    padding: 20
  },
  inviteForm: {
    display: 'flex',
    flexDirection: 'row',
  },
  sendBtn: {
    marginLeft: 50,
    display: 'flex',
    alignItems: 'center',
  },
  invitationContainer: {
    padding: 30,
  },
  invitationCard: {
    display: 'flex',
    border: '1px solid #00bcd4',
    borderRadius: 5,
    padding: 15,
    marginTop: 20,
    minHeight: 120,
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 600,
    width: '100%'
  },
  statusContainer: {
    width: '40%',
    div: {
      marginBottom: 20
    }
  },
  deleteBtn: {
    backgroundColor: 'red',
    color: '#fff',
    padding: '0 20'
  },
  inviteLeft: {
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  inviteRight: {
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
  }
};

class UserManagement extends Component {
  static propTypes = {
    sendInvite: PropTypes.func,
    getInviteList: PropTypes.func,
    finalize: PropTypes.func,
    createUser: PropTypes.func,
  };

  static defaultProps = {
    invitationsPerPage: 5
  };

  constructor(props) {
    super(props);
    this.passRef = React.createRef();
    this.state = {
      selectValue: 'en_US',
      openErr: false,
      openPass: false,
      deleteInviteOpen: false,
      deleteInviteItemId: null,
      message: '',
      pass: '',
      inviteItemCode: null,
      name: '',
      email: '',
      pageStart: 0,
      pageEnd: 0,
      periodOfMonth: 24,
      webShopFee: 20,
      nonInternetFee: 25,
      prevInviteList: [],
    };
  }

  componentDidMount() {
    const { getInviteList } = this.props;
    getInviteList();

    window.addEventListener('scroll', this.onPageScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll);
  };

  onPageScroll = () => {
    const userScrollHeight = window.innerHeight + window.scrollY;
    const windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight) {
      this.loadNext();
    }
  };

  loadNext() {
    const { getInviteList, invitationsPerPage, inviteList } = this.props;
    const { pageStart, pageEnd, prevInviteList } = this.state;

    this.setState(
      {
        pageStart: 0,
        pageEnd: pageEnd + invitationsPerPage,
        prevInviteList: inviteList
      },
      () => {
        if(prevInviteList.length !== inviteList.length)
        getInviteList(pageStart, this.state.pageEnd);
      }
    );
  }

  handleInvite = () => {
    const { sendInvite, getInviteList } = this.props;
    const { selectValue, name, email, periodOfMonth, webShopFee, nonInternetFee } = this.state;

    sendInvite({
      name, email, locale: selectValue,
      months: Number(periodOfMonth), fee_pc: Number(webShopFee),
      fee_inv: Number(nonInternetFee),
    }).then(res => {
      if (res.error) {
        this.setState({openErr: true, message: res.data })
      }
    }).then(() => {
      this.setState({ name: '', email: '' });
      getInviteList();
    });
  };

  finalizeAcc = (id) => {
    const { finalize, getInviteList } = this.props;

    finalize({id});
    getInviteList();
  };

  openPassDialog = (el) => {
    this.setState({ openPass: true, inviteItemCode: el });
  }

  createUserAccount = () => {
    const { createUser, getInviteList } = this.props;

    if (this.state.pass.length < 8) {
      alert('Password should be 8 characters at least!');
      return;
    }

    createUser({code: this.state.inviteItemCode, password: this.state.pass})
      .then(() => {
        this.setState({openPass: false})
        getInviteList();
      })
  };

  handleSelectChange = (e, i, value) => this.setState({selectValue: value});

  handleDialogErrClose = () => {
    this.setState({ openErr: false })
  };

  handleDialogPassClose = () => {
    this.setState({ openPass: false })
  };

  handleDialogDeleteClose = () => {
    this.setState({ deleteInviteOpen: false })
  };

  handleDialogDeleteItemOpen = (el) => {
    this.setState({ deleteInviteOpen: true, deleteInviteItemId: el })
  }

  deleteInvite = async () => {
    const { deleteInviteItem, getInviteList } = this.props;
    const { deleteInviteItemId } = this.state;

    await deleteInviteItem({ id: deleteInviteItemId });
    await getInviteList();

    this.setState({deleteInviteOpen: false})
  }

  buttonStatus = (el) => {
    if (el.status & BUSINESS_INVITE_STATUS.FINALIZED) {
      return (
        <div style={styles.statusContainer}>
          <p>Business account was created with success.</p>
          <div>Status: Finalised</div>
        </div>
      );
    }
    if (el.status & BUSINESS_INVITE_STATUS.USER_CREATED) {
      return (
        <div style={styles.statusContainer}>
          <p>User has accept terms and conditions and account was created. Please click button bellow to finalize business invite.</p>
          <RaisedButton onClick={() =>this.finalizeAcc(el.id)}>Finalize</RaisedButton>
        </div>
      );
    }
    if (el.status & BUSINESS_INVITE_STATUS.ACCEPTED) {
      return (
        <div style={styles.statusContainer}>
          <p>User has already accept terms and conditions. Please click button bellow to create user.</p>
          <RaisedButton onClick={() => this.openPassDialog(el.code)}>Create user</RaisedButton>
        </div>
      );
    }
    if (el.status & BUSINESS_INVITE_STATUS.TERMS_VIEWED) {
      return (
        <div style={styles.statusContainer}>
          <p>User has viewed terms and conditions.</p>
          <div>Status: Terms viewed</div>
        </div>
      )
    }
    if (el.status & BUSINESS_INVITE_STATUS.SENT_OUT) {
      return (
        <div style={styles.statusContainer}>
          <p>The link for accepting terms and conditions has been sent out to user email.</p>
          <div>Status: Sent out</div>
          <RaisedButton buttonStyle={styles.deleteBtn} onClick={() => this.handleDialogDeleteItemOpen(el.id)}>
            Delete invite
          </RaisedButton>
        </div>
      );
    }

    return <div>Unknown</div>
  }

  render() {
    const { inviteList } = this.props;

    return (
      <div>
        <Header title="Business Invites" />

        <div style={styles.inviteContainer}>
          <div style={styles.inviteForm}>
            <div style={styles.inviteLeft}>
              <TextField
                ref="name"
                type="text"
                hintText="Name"
                floatingLabelText="Name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <TextField
                ref="email"
                type="email"
                hintText="Email"
                floatingLabelText="Email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <SelectField
                type="text" hintText="Locale"
                floatingLabelText="locale"
                value={this.state.selectValue}
                onChange={this.handleSelectChange}
              >
                <MenuItem value={'en_US'} primaryText="English" />
                <MenuItem value={'sv_SE'} primaryText="Swedish" />
              </SelectField>
            </div>
            <div style={styles.inviteRight}>
              <TextField
                ref="periodOfMonth"
                hintText="Period of month"
                floatingLabelText="Period of month"
                value={this.state.periodOfMonth}
                onChange={(e) => this.setState({ periodOfMonth: e.target.value })}
              />
              <TextField
                ref="webShopFee"
                hintText="fee from webshop sales (%)"
                floatingLabelText="fee from webshop sales (%)"
                value={this.state.webShopFee}
                onChange={(e) => this.setState({ webShopFee: e.target.value })}
              />
              <TextField
                ref="nonInternetFee"
                hintText="Invitation fee not through webshop"
                floatingLabelText="Invitation fee not through webshop"
                value={this.state.nonInternetFee}
                onChange={(e) => this.setState({ nonInternetFee: e.target.value })}
              />
            </div>
          </div>
          <div style={styles.sendBtn}>
            <RaisedButton onClick={this.handleInvite}>Send</RaisedButton>
          </div>
        </div>

        <div style={styles.invitationContainer}>
          Invitation List
          {
            inviteList && inviteList.map(el => {
              return(
                <div key={el.id} style={styles.invitationCard}>
                  <div>
                    <p>{el.name}</p>
                    <p>{el.email}</p>
                    <p>{el.created}</p>
                  </div>
                  {this.buttonStatus(el)}
                </div>
              )
            })
          }
        </div>

        <Dialog
          title="Error message"
          open={this.state.openErr}
          onRequestClose={this.handleDialogErrClose}
        >
          {this.state.message}
        </Dialog>

        <Dialog
          title="Please enter password to create user"
          open={this.state.openPass}
          onRequestClose={this.handleDialogPassClose}
        >
          <TextField
            inputRef={this.passRef}
            onChange={(e) => this.setState({ pass: e.target.value })}
            autoFocus
            margin="dense"
            id="pass"
            label="Password"
            type="password"
            fullWidth
          />
          <RaisedButton onClick={this.createUserAccount} color="primary" className="set-up-btn">
            Save
          </RaisedButton>
        </Dialog>

        <Dialog
          title="Are you sure you want to delete business invite?"
          open={this.state.deleteInviteOpen}
          onRequestClose={this.handleDialogDeleteClose}
        >
          <RaisedButton onClick={this.deleteInvite} color="primary" className="set-up-btn">
            Yes
          </RaisedButton>
          <RaisedButton onClick={this.handleDialogDeleteClose} color="primary" className="set-up-btn">
            No
          </RaisedButton>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    sendInvite: bindActionCreators(sendInvite, dispatch),
    getInviteList: bindActionCreators(getInviteList, dispatch),
    finalize: bindActionCreators(finalize, dispatch),
    createUser: bindActionCreators(createUser, dispatch),
    deleteInviteItem: bindActionCreators(deleteInviteItem, dispatch),
});

const mapStateToProps = (state) => ({
    hashedId: state.userManagement.hashedId,
    inviteList: state.userManagement.inviteList
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
