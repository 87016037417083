import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  generateInvitationsList,
  generatePaymentsList,
  generateActiveClientsList,
  generatePermissionsList,
} from '../../actions/Accounting';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  RaisedButton,
  Snackbar,
  TextField,
  SelectField,
  MenuItem,
} from 'material-ui';


const styles = {
  stats: {
    margin: 8,
    padding: 18
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  toggle: {
    marginBottom: 24,
    marginTop: 32,
    width: 200
  },
  status: {
    marginBottom: 32
  },
  flex1: { flex: 1 },
  flex: {
    display: 'flex',
  }
};

const paymentStatusItems = [
  { value: 0, payload: 'any', text: 'Any' },
  { value: 1, payload: 'unpaid', text: 'Unpaid' },
  { value: 2, payload: 'paid', text: 'Paid' },
  { value: 3, payload: 'refunded', text: 'Refunded' }
];

const paymentFormatItems = [
  { value: 0, payload: 'google', text: 'Google' },
  { value: 1, payload: 'excel', text: 'Excel' },
  { value: 2, payload: 'accountant1', text: 'Accountant 1' },
];

class PaymentsList extends Component {
  static propTypes = {
    generateInvitationsList: PropTypes.func,
    generatePaymentsList: PropTypes.func,
    generatePermissionsList: PropTypes.func,
    generateActiveClientsList: PropTypes.func,
  };

  state = {
    message: null,
    open: false,
    value: 'a',
    error: 'This field is mandatory',
    submit: true,
    idState: '',
    costState: '',
    percentageState: '',
    uniqueUsers: false,
    fromCircles: false,
    ucpData: false,
    uniqueUsersPermissions: false,
    status: 0,
    paymentStatus: 0,
    hidePurchases: false,
    paymentFormat: 0,
    emailsSelected: [],
  };

  clearMessage = () => {
    this.setState({ message: null, open: false });
  };

  setUniqueUsers = () => {
    this.setState({ uniqueUsers: !this.state.uniqueUsers });
  };

  setPaymentStatus = (_, paymentStatus) => {
    this.setState({ paymentStatus });
  };

  setPaymentFormat = (_, paymentFormat) => {
    this.setState({ paymentFormat })
  };

  handlePaymentsList = () => {
    const storeId = String(this.refs.storeId.getValue()) || undefined;
    const startDate = String(this.refs.startDate.getValue()) || undefined;
    const endDate = String(this.refs.endDate.getValue()) || undefined;
    const statusItem = paymentStatusItems.find(
      status => status.value === this.state.paymentStatus
    );
    const paymentStatus = statusItem && statusItem.payload;
    const formatItem = paymentFormatItems.find(
      format => format.value === this.state.paymentFormat
    );
    const paymentFormat = formatItem && formatItem.payload

    const { generatePaymentsList } = this.props;
    generatePaymentsList({
      storeId,
      startDate,
      endDate,
      status: paymentStatus,
      format: paymentFormat
    }).then(res => {
      if (!res.error) {
        this.setState({
          open: true,
          message: 'Data sent to your email'
        });
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  };

  render() {
    const { message, open } = this.state;

    return (
      <div>
        <h2 style={styles.headline}>Payments list</h2>
        <p>Below you can get a list of payments</p>

        <TextField
          hintText="Store's ID"
          floatingLabelText="Store's ID"
          ref='storeId'
          type='text'
        />
        <br />
        <TextField
          floatingLabelText='Start date'
          ref='startDate'
          type='date'
        />
        <br />
        <TextField
          floatingLabelText='End date'
          ref='endDate'
          type='date'
        />
        <br />
        <SelectField
          style={styles.status}
          floatingLabelStyle={{
            transform:
              'perspective(1px) scale(0.75) translate3d(2px, -28px, 0)'
          }}
          floatingLabelText='Status'
          onChange={this.setPaymentStatus}
          value={this.state.paymentStatus}>
          {paymentStatusItems.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              primaryText={item.text}
            />
          ))}
        </SelectField>
        <br />
        <SelectField
          style={styles.status}
          floatingLabelStyle={{
            transform:
              'perspective(1px) scale(0.75) translate3d(2px, -28px, 0)'
          }}
          floatingLabelText="Format"
          onChange={this.setPaymentFormat}
          value={this.state.paymentFormat}
        >
          {paymentFormatItems.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              primaryText={item.text}
            />
          ))}
        </SelectField>
        <br />
        <RaisedButton label='Submit' onClick={this.handlePaymentsList}>
          <Snackbar
            message={message || ''}
            open={!!open}
            autoHideDuration={3000}
            onRequestClose={this.clearMessage}
          />
        </RaisedButton>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    generateInvitationsList: bindActionCreators(generateInvitationsList, dispatch),
    generatePaymentsList: bindActionCreators(generatePaymentsList, dispatch),
    generateActiveClientsList: bindActionCreators(generateActiveClientsList, dispatch),
    generatePermissionsList: bindActionCreators(generatePermissionsList, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsList);
