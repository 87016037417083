import Config from '../Config';
import SuperAgent from 'superagent';
// import AuthActions from 'actions';
//import { history } from 'helpers';
//import AuthActions from '../actions/Auth';

const HTTP_UNAUTHORIZED = 401;
//const HTTP_OK = 200;

class SteelCompass {
  constructor() {
    this.token = localStorage && localStorage.getItem('token');
  }

  setAuthToken(token) {
    this.token = token;
    localStorage.setItem('token', token);
  }

  getUrl() {
    const testMode =
      localStorage.getItem('userOptions') == null
        ? false
        : JSON.parse(localStorage.getItem('userOptions')).testMode == null
          ? false
          : JSON.parse(localStorage.getItem('userOptions')).testMode;
    const localMode = localStorage.getItem('localMode') || false;
    const stage = localMode ? 'local' : testMode ? 'test' : 'production';
    const url = Config.steelCompassRoot[stage];
    return url;
  }

  request(object, method, requestData, pageStart = 0, pageLength = 15) {
    const url = this.getUrl();
    const data = requestData || {};
    return new Promise((resolve, reject) => {
      SuperAgent.post(url + object)
        .set('Accept', 'application/json')
        .set('Content-Type', 'text/plain')
        .set('Token', this.token)
        .send(JSON.stringify({ method, data, pageStart, pageLength }))
        .end((err, response) => {
          if (err && err.status) {
            if (response.status === HTTP_UNAUTHORIZED) {
              //  history.pushState(null, '/logout');
            }
            reject((response.body && response.body.errMsg) || 'Ohh noes!');
          } else {
            try {
              if (!response.body) {
                response.body = SuperAgent.parse['application/json'](
                  response.text
                );
              }
            } catch (e) {
              reject('Ohh snap!');
            }
            if (
              response &&
              response.body.isError &&
              response.status === HTTP_UNAUTHORIZED
            ) {
              //  history.pushState(null, '/logout');
              reject(response.body.errMsg);
            } else if (response && response.body.isError) {
              reject(response.body.errMsg);
            } else if (!response) {
              reject('Ohh snap!');
            } else {
              resolve(response.body.data || {});
            }
          }
        });
    });
  }

  /*savePageModule(id, params) {
    this.call('page-module', 'update', { id, params }, response => {

    });
  }

  deletePageModule(id, callback) {
    this.call('page-module', 'delete', { id }, response => {
      if (callback) {
        callback();
      }
    });
  }*/

  /*upload(files, options) {
    let request,
      method = options.encoded && 'encoded' || 'plain';
    options = options || {};

    request = SuperAgent.post(Config.steelCompassRoot + 'upload/' + method)
      // .set('Content-type', 'multipart/form-data')
      .set('Token', this.token);


    let fd = new FormData();
    for (let i = 0, file;file = files[i];i++) {
      fd.append('file[]', file, file.name);
    }
    request.send(fd)
      .on('progress', (event) => {
        if (options.progress) {
          options.progress(event);
        }
      })
      .end((err, response) => {
        if (err) {
          options.error(response.body);
        } else if (options.success) {
          options.success(response.body);
        }
      });
  }*/

  login(identity, credential) {
    return new Promise((resolve, reject) => {
      this.request('auth', 'login', { identity, credential }).then(
        data => {
          if (data) {
            resolve(data);
          } else {
            reject('Unknown error');
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  loginCdk(key) {
    return new Promise((resolve, reject) => {
      this.request('auth', 'loginCdk', { hash: key }).then(
        data => {
          if (data) {
            resolve(data);
          } else {
            reject('Unknown error');
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  logout() {
    let token = localStorage && localStorage.getItem('token');
    localStorage.removeItem(token);
    return this.request('auth', 'logout');
  }
}

let sc = new SteelCompass();

window.SteelCompass = sc;

export default sc;
