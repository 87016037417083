import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List, ListItem, Divider, Avatar } from 'material-ui';

class BulkOptions extends Component {
  static propTypes = {
    children: PropTypes.node,
    chosenItems: PropTypes.object
  };

  render() {
    const { chosenItems } = this.props;
    let content = null;
    return (
      <div>
        <List>
          {Object.keys(chosenItems).map(id => {
            const item = chosenItems[id];

            let itemEmail = '',
              itemName = '',
              itemAvatar = null;

            if (item.email !== undefined) {
              itemEmail = ', Email: ' + item.email;
            }

            if (item.name !== undefined) {
              itemName = ', Name: ' + item.name;
            }

            if (item.avatarUrl !== undefined) {
              itemAvatar = item.avatarUrl;
            }

            if (window.location.pathname === '/allcourses') {
              content = (
                <div>Bulk options for courses list to be added here</div>
              );
            } else if (window.location.pathname === '/users') {
              content = <div>Bulk options for users list to be added here</div>;
            } else if (window.location.pathname === '/invitations') {
              content = (
                <div>Bulk options for invitations list to be added here</div>
              );
            } else {
              content = (
                <div>Bulk options for payments list to be added here</div>
              );
            }

            return (
              <div key={id}>
                <ListItem
                  primaryText={'Id: ' + item.id + itemEmail + itemName}
                  leftAvatar={
                    itemAvatar !== null ? <Avatar src={itemAvatar} /> : null
                  }
                  disabled
                />
                <Divider />
              </div>
            );
          })}
        </List>

        {content}
      </div>
    );
  }
}

export default BulkOptions;
