import {
  OPEN_DRAWER,
  CLOSE_DRAWER,
  TEST_MODE,
  LIVE_MODE,
  SHOW_BULK_OPTIONS,
  HIDE_BULK_OPTIONS
} from '../constants/UiActionTypes';

export function openDrawer() {
  return {
    type: OPEN_DRAWER
  };
}

export function closeDrawer() {
  return {
    type: CLOSE_DRAWER
  };
}

export function testMode() {
  return {
    type: TEST_MODE
  };
}

export function liveMode() {
  return {
    type: LIVE_MODE
  };
}

export function showBulkOptions() {
  return {
    type: SHOW_BULK_OPTIONS
  };
}

export function hideBulkOptions() {
  return {
    type: HIDE_BULK_OPTIONS
  };
}
