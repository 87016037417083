import { combineReducers } from 'redux';
import user from './userReducer';
import users from './usersReducer';
import kpi from './kpiReducer';
import currUser from './currUserReducer';
import courses from './allCoursesReducer';
import currCourse from './currCourseReducer';
import invitations from './invitationsReducer';
import currInvitation from './currInvitationReducer';
import payments from './paymentsReducer';
import currPayment from './currPaymentReducer';
import ui from './uiReducer';
import accounting from './accountingReducer';
import userManagement from './userManagementReducer';
import testUsers from './testUsersReducer';
import courseBudget from './courseBudgetReducer';
import vendorReducer from "./vendorReducer";

export default combineReducers({
  user,
  users,
  kpi,
  currUser,
  courses,
  currCourse,
  invitations,
  currInvitation,
  payments,
  currPayment,
  ui,
  accounting,
  userManagement,
  testUsers,
  courseBudget,
  vendorReducer
});
