//here is the list of the courses related to the current user
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import React, { Component } from 'react';
import { List, ListItem, Paper } from 'material-ui';
import Dialog from 'material-ui/Dialog';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import FlatButton from 'material-ui/FlatButton';
import ActionVisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import EditIcon from 'material-ui/svg-icons/image/edit';

const styles = {
  list: {
    margin: 0
  }
};

class AllCoursesList extends Component {
  static propTypes = {
    courses: PropTypes.object,
    onChooseCourse: PropTypes.func,
    params: PropTypes.object
  };

  state = {
    open: false
  };

  handleChange = (e, value) => {
    this.props.updatePermission({ id: this.props.userPermissions[0].id, mode: value });
  }

  handleOpen = courseId => {
    this.props.listPermission({ userId: this.props.userId, courseId });
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getCourse = course => {
    const { onChooseCourse } = this.props;
    onChooseCourse && onChooseCourse(course);
  };

  render() {
    const { courses, params } = this.props;

    const actions = [
      <FlatButton label="Close" primary onClick={this.handleClose} />
    ];

    if (courses.loading) {
      return <p>Search Courses</p>;
    }

    let finCourses;
    if (window.location.pathname !== `/users/${params.userId}`) {
      finCourses = <div> </div>;
    } else {
      finCourses = (
        <Paper style={styles.list}>
          <List>
            {Object.keys(courses).map(id => {
              const course = courses[id];

              if (course.deleted === true || course.deletedBy === false) {
                return (
                  <ListItem
                    rightIcon={<ActionVisibilityOff />}
                    key={id}
                    onClick={this.getCourse.bind(this, course)}
                    primaryText={course.name}
                    secondaryText={id}
                  />
                );
              } else {
                return (
                  <ListItem
                    rightIconButton={
                      <IconButton
                        touch
                        onClick={this.handleOpen.bind(this, course.id)}
                        tooltip="Change user course permission"
                        tooltipPosition="bottom-left"
                      >
                        <EditIcon />
                      </IconButton>
                    }
                    onClick={this.getCourse.bind(this, course)}
                    key={id}
                    primaryText={course.name}
                    secondaryText={id}
                  />
                );
              }
            })}
          </List>
          <Dialog
            title="Update user course permission"
            actions={actions}
            modal={false}
            open={this.state.open}
            onRequestClose={this.handleClose}
          >
            <RadioButtonGroup
              onChange={this.handleChange}
              name="shipSpeed"
              valueSelected={
                this.props.userPermissions.length &&
                this.props.userPermissions[0].access
              }
            >
              <RadioButton value="builder" label="Builder" />
              <RadioButton value="reader" label="Reader" />
            </RadioButtonGroup>
          </Dialog>
        </Paper>
      );
    }

    return <div>{finCourses}</div>;
  }
}

export default AllCoursesList;
