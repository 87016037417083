import SteelCompass from '../services/SteelCompass';
import {
  SET_COURSE_RESELLER_CUTS,
  GET_COURSE_RESELLER_CUT,
  GET_ALL_CUTS_OF_USER,
  GET_ALL_CUTS_FROM_COURSE,
  DELETE_COURSE_RESELLER_CUT,
} from '../constants/CourseBudgetActionTypes';

//set reseller cuts over courses
export function setResellerCut({ courseId, userId, cutValue, cutType, notes, cutName }) {
  return {
    type: SET_COURSE_RESELLER_CUTS,
    promise: SteelCompass.request('mr.wolf-course-budget', 'setCut', {
      userId,
      id: courseId,
      cut: cutValue,
      cutType,
      notes,
      name: cutName,
    })
  };
}
//get reseller cut for a course 
export function getCutOfUserForCourse({ courseId, userId }) {
  return {
    type: GET_COURSE_RESELLER_CUT,
    promise: SteelCompass.request('mr.wolf-course-budget', 'getCut', {
      userId,
      id: courseId,
    })
  };
}
//get reseller cuts of all theirs courses
export function getAllCutsOfUser({ userId }) {
  return {
    type: GET_ALL_CUTS_OF_USER,
    promise: SteelCompass.request('mr.wolf-course-budget', 'findCutsByUserId', {
      userId,
    })
  };
}
//get reseller cuts of all theirs courses
export function getAllCutsForCourse({ courseId }) {
  return {
    type: GET_ALL_CUTS_FROM_COURSE,
    promise: SteelCompass.request('mr.wolf-course-budget', 'findCutsByCourseId', {
      id: courseId,
    })
  };
}
//delete cut
export function deleteCut({ courseId, userId, cutId }) {
  return {
    type: DELETE_COURSE_RESELLER_CUT,
    promise: SteelCompass.request('mr.wolf-course-budget', 'deleteCut', {
      userId,
      id: courseId,
      cutId
    })
  };
}
