import {
  FIND_PAYMENT_BY_ID,
  FIND_PAYMENT_BY_ID_REQUEST,
  REFUND_PAYMENT,
  REFUND_PAYMENT_REQUEST
} from '../constants/CurrPaymentActionTypes';

const initialState = {
  loading: true,
  currPayment: {}
};

export default function currPaymentReducer(state = initialState, action) {
  let currPayment;
  switch (action.type) {
    case FIND_PAYMENT_BY_ID:
      currPayment = action.res;
      return {
        loading: false,
        currPayment
      };
    case FIND_PAYMENT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REFUND_PAYMENT:
      currPayment = action.res;
      return {
        loading: false,
        currPayment
      };
    case REFUND_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
