import SteelCompass from '../services/SteelCompass';
import { GET_INVITATIONS } from '../constants/InvitationsActionTypes';

//this function brings all invitations and specific filters can be applied
export function getInvitations(filters = [], pageStart = 0, pageLength = 15) {
  return {
    type: GET_INVITATIONS,
    pageStart,
    promise: SteelCompass.request(
      'mr.wolf-invitation',
      'findAll',
      { filters },
      pageStart,
      pageLength
    )
  };
}
