//Here is where the authentication of the user takes place
import PropTypes from 'prop-types';

import React from 'react';
import Login from '../../components/Login';
import Logout from '../../components/Logout';
import CreateUserForm from '../../components/CreateUserForm';
import { login, logout } from '../../actions/Auth';
import { createDemoAccount } from '../../actions/User';
import { getCSSales, getCSInvites } from '../../actions/Kpi';
import {
  Avatar,
  Paper,
  RaisedButton,
  Dialog,
  FlatButton,
  Snackbar,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui';
import SteelCompass from '../../services/SteelCompass';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../Bar/Header';

const styles = {
  root: {
    margin: 8
  },
  login: {
    padding: 8,
    width: 320,
    margin: 'auto'
  },
  logout: {
    margin: 8
  },
  avatar: {
    position: 'relative',
    top: 20,
    margin: 4
  },
  kpiTableHeader: {
    fontSize: 24,
    fontWeight: 400
  },
  kpiTablesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 50
  },
  kpiTableContainer: {
    flexGrow: 1,
    width: 500,
    marginLeft: 50,
    marginRight: 50
  }
};

class UserPage extends React.Component {
  static propTypes = {
    login: PropTypes.func,
    logout: PropTypes.func,
    user: PropTypes.object,
    ui: PropTypes.object,
    kpi: PropTypes.object,
    currUser: PropTypes.object,
    children: PropTypes.node,
    createDemoAccount: PropTypes.func,
    generateCSStats: PropTypes.func,
    generateInvitationsList: PropTypes.func,
    generatePaymentsList: PropTypes.func,
    generatePermissionsList: PropTypes.func,
    getCSSales: PropTypes.func,
    getCSInvites: PropTypes.func,
    username: PropTypes.string,
    password: PropTypes.string,
    courseIds: PropTypes.array
  };

  state = {
    addUser: false,
    message: null,
    open: false
  };

  componentDidMount() {
    const { getCSSales, getCSInvites } = this.props;
    getCSSales();
    getCSInvites();
  }

  handleOpenAddUser = () => {
    this.setState({ addUser: true });
  };

  handleCloseAddUser = () => {
    this.setState({ addUser: false });
  };

  login = (email, password) => {
    const { login } = this.props;

    login(email, password).then(res => {
      if (res.error) {
        SteelCompass.setAuthToken(null);
      } else {
        const { data } = res;
        SteelCompass.setAuthToken(data.sessionId);
      }
    });
  };

  logout = () => {
    const { logout } = this.props;

    logout().then(() => {
      SteelCompass.setAuthToken(null);
    });
  };

  createDemoAccount = (username, password, courseIds) => {
    const { createDemoAccount } = this.props;

    createDemoAccount(username, password, courseIds).then(res => {
      if (
        !res.error &&
        username != null &&
        (password != null) & (courseIds != null)
      ) {
        this.setState({
          open: true,
          message: 'Demo Account Created Successfully'
        });
        setTimeout(() => this.setState({ addUser: false }), 3000);
      } else {
        this.setState({
          open: true,
          message: 'Error: ' + res.data
        });
      }
    });
  };

  clearMessage = () => {
    this.setState({ message: null, open: false });
  };

  getTopSalesTable({ data }) {
    const rows = data.map(({ storeName, salesCount }, index) => {
      return (
        <TableRow key={index}>
          <TableRowColumn>{storeName}</TableRowColumn>
          <TableRowColumn>{salesCount}</TableRowColumn>
        </TableRow>
      );
    });

    return (
      <Table selectable={false}>
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn>Store</TableHeaderColumn>
            <TableHeaderColumn>Sales</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>{rows}</TableBody>
      </Table>
    );
  }

  getTopInvitesTable({ data }) {
    const rows = data.map(
      ({ username, invitesIssued, invitesAccepted }, index) => {
        return (
          <TableRow key={index}>
            <TableRowColumn>{username}</TableRowColumn>
            <TableRowColumn>{invitesIssued}</TableRowColumn>
            <TableRowColumn>{invitesAccepted}</TableRowColumn>
          </TableRow>
        );
      }
    );

    return (
      <Table selectable={false}>
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn>Store</TableHeaderColumn>
            <TableHeaderColumn>Invited</TableHeaderColumn>
            <TableHeaderColumn>Accepted</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>{rows}</TableBody>
      </Table>
    );
  }

  render() {
    const { user, ui, kpi: { topSales, topInvites } } = this.props;
    const { message, open } = this.state;

    let greeting;
    const nowDate = new Date();
    const nowHour = nowDate.getHours();

    if (nowHour < 12) {
      greeting = 'Good Morning';
    } else if (nowHour < 17) {
      greeting = 'Good Afternoon';
    } else {
      greeting = 'Good Evening';
    }

    const actions = [
      <FlatButton
        label="Cancel"
        secondary
        onClick={this.handleCloseAddUser}
      />
    ];

    const topSalesTable = this.getTopSalesTable({ data: topSales });
    const topInvitesTable = this.getTopInvitesTable({ data: topInvites });

    let login;
    if (user.id) {
      login = (
        <div style={styles.logout}>
          <Header title="Home sweet home" />

          <h3>
            <Avatar src={user.avatarUrl} style={styles.avatar} />
            {'  '}
            {greeting} {user.displayName || user.id}!
          </h3>
          <hr />
          <p>
            Hello!! Check the menu on the left of the page for more options.
          </p>
          {!ui.testMode ? (
            <p>
              You are working on LiveMode -- any changes affect the live
              database{' '}
            </p>
          ) : (
            <p>
              {' '}
              You are working on TestMode -- any changes affect the testing
              database
            </p>
          )}

          <div>
            <div style={{ marginBottom: 10 }}>
              <RaisedButton
                secondary
                label="Create Demo Account"
                onClick={this.handleOpenAddUser}
              />
            </div>

            <Dialog
              title="Create Demo Account"
              actions={actions}
              modal={false}
              open={this.state.addUser}
              onRequestClose={this.handleCloseAddUser}
              autoScrollBodyContent={true}
            >
              <CreateUserForm onCreateDemoAccount={this.createDemoAccount} />
              <Snackbar
                message={message || ''}
                open={!!open}
                autoHideDuration={3000}
                onRequestClose={this.clearMessage}
              />
            </Dialog>
          </div>

          <Logout onLogout={this.logout} />

          <div style={styles.kpiTablesContainer}>
            <div style={styles.kpiTableContainer}>
              <h2 style={styles.kpiTableHeader}>Top sales (last 7 days)</h2>
              {topSalesTable}
            </div>
            <div style={styles.kpiTableContainer}>
              <h2 style={styles.kpiTableHeader}>
                Top invitations (last 7 days)
              </h2>
              {topInvitesTable}
            </div>
          </div>
        </div>
      );
    } else {
      login = (
        <Paper style={styles.login}>
          <Header title="Home sweet home" />
          <Login onLogin={this.login} />
        </Paper>
      );
    }

    return <div style={styles.root}>{login}</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    login: bindActionCreators(login, dispatch),
    logout: bindActionCreators(logout, dispatch),
    createDemoAccount: bindActionCreators(createDemoAccount, dispatch),
    getCSSales: bindActionCreators(getCSSales, dispatch),
    getCSInvites: bindActionCreators(getCSInvites, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    currUser: state.currUser,
    kpi: state.kpi,
    ui: state.ui
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
