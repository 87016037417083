import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findInvitationById } from '../../actions/Invitation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  IconButton,
  IconMenu,
  List,
  ListItem,
  Divider
} from 'material-ui';
import NavigationCancel from 'material-ui/svg-icons/navigation/cancel';
import ActionLabel from 'material-ui/svg-icons/action/label';
import ActionLabelOutline from 'material-ui/svg-icons/action/label-outline';
import ActionAccountCircle from 'material-ui/svg-icons/action/account-circle';
import ActionSchedule from 'material-ui/svg-icons/action/schedule';
import CommunicationEmail from 'material-ui/svg-icons/communication/email';

const styles = {
  smallIcon: {
    width: 23,
    height: 23
  },
  small: {
    width: 10,
    height: 10,
    padding: 16
  }
};

class InvitationsDetails extends Component {
  static propTypes = {
    children: PropTypes.node,
    params: PropTypes.object,
    findInvitationById: PropTypes.func,
    currInvitation: PropTypes.object
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    const { params, findInvitationById } = this.props;

    findInvitationById(params.invitationId);
  }

  goBack = () => {
    const { router } = this.context;
    router.push('/invitations');
  };

  render() {
    const { params, currInvitation } = this.props;
    let courseInvitationName = '';
    let courseInvitationId = '';
    let courseInvitationSlug = '';

    if (typeof currInvitation.course != 'undefined') {
      courseInvitationName = currInvitation.course.name;
      courseInvitationId = currInvitation.course.id;
      courseInvitationSlug = currInvitation.course.slug;
    }

    let invitationItem;

    invitationItem = (
      <div>
        <Toolbar>
          <ToolbarGroup key={0}>
            <ToolbarTitle text={'Invitation id: ' + params.invitationId} />
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <IconMenu
              iconButtonElement={
                <IconButton
                  style={styles.small}
                  iconStyle={styles.smallIcon}
                  tooltip="Close"
                  tooltipPosition="top-right"
                  onClick={this.goBack}
                >
                  <NavigationCancel />
                </IconButton>
              }
            />
          </ToolbarGroup>
        </Toolbar>

        <List>
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Id"
            secondaryText={currInvitation.id}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<CommunicationEmail />}
            primaryText="Email"
            secondaryText={currInvitation.email}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionSchedule />}
            primaryText="Expiration"
            secondaryText={currInvitation.expires}
          />
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabel />}
            primaryText="Course Name"
            secondaryText={courseInvitationName}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionAccountCircle />}
            primaryText="Course Id"
            secondaryText={courseInvitationId}
          />
          <Divider inset={true} />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabelOutline />}
            primaryText="Course Slug"
            secondaryText={courseInvitationSlug}
          />
          <Divider />
          <ListItem
            disabled={true}
            leftIcon={<ActionLabel />}
            primaryText="Transport"
            secondaryText={currInvitation.transport}
          />
        </List>
      </div>
    );

    return <div>{invitationItem}</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    findInvitationById: bindActionCreators(findInvitationById, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    currInvitation: state.currInvitation.currInvitation
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(InvitationsDetails);
